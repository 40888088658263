import React, { useState } from 'react';
import Banner from '../../Components/banner';
import '../../assets/css/home.css';
import HomeBanner from './HomeBanner';
import OurBrand from './OurBrand';
import ShopByCategory from './ShopByCategory';
import OurCollection from './OurCollection';
import BestSeller from './BestSeller';
import VideoBanner from './VideoBanner';
import GskDifference from './GskDifference';
import NewsLetter from './NewsLetter';

const Home = () => {
    return (
        <>
            {/* <Banner /> */}
            <HomeBanner />
            <OurBrand/>
            <ShopByCategory />
            <OurCollection />
            <BestSeller />
            <VideoBanner/>
            <GskDifference/>
          
        </>
    )
}
export default Home;
