import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/home.css";
import RingSeller from "../../assets/images/Home/earrings_seller.png";
import { BsHeart } from "react-icons/bs";
import { GrPrevious, GrNext } from "react-icons/gr";
import axios from "axios";
import { base_url, postHeader } from "../../Helpers/request";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Left from "../../assets/images/Home/new-home/left-arrow.png";
import Right from "../../assets/images/Home/new-home/right-arrow.png";

const BestSeller = () => {
  const history= useHistory();
  useEffect(() => {
    getBestSeller();
  }, []);

  const [data, setData] = useState([]);

  const getBestSeller = async () => {
    const options = {
      method: "GET",
      headers: postHeader,
    };

    await axios.get(base_url + "/order/bestseller", options).then((res) => {
      if (res && res?.status === 200) {
        setData(res?.data?.data);
      }
    });
  };

  const [hoverpb, setHoverpb] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    nextArrow: <img src={Right} className="bs_color"/>,
    prevArrow: <img src={Left} className="bs_color" />,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          
          slidesToScroll: 1,

        },
      },
    ],
  };

  return (
    <>
      <section className="gsk_best_seller_section">
        <Container className="gs_custome_fluid_container" fluid>
          <Row>
            <Col>
              <div className="gsK_home_title d_font">
                <h2>Best Sellers </h2>
                {/* <p> Sub Content </p> */}
              </div>
            </Col>
          </Row>

          <Slider {...settings} className="gs_best_seller_sliderMain">
            {data.length > 0 &&
              data.map((e, i) => (

               
                <div
                  className="gsk_product_box_view" key={i}
                >
                  <div
                    className="gsk_product_heart_btn">
                    <div className="flex">
                    { e.product_type===0 &&   <Button className="gsk_customize"> Customize </Button>
                    }
                    
                      <Button className="gsk_heart">
                        <BsHeart />
                      </Button>
                    </div>
                  </div>

                  <div 
                    className="gsk_product_box_view_img"
                  >
                    <Link to={`productdetail/${e?.product_slug}/${e?.product_sku}/${e?.stone_data && e?.stone_data.join('_')}`}>
                       <div className="hm_best_seller"></div>
                      <img className="img-fluid" src={e?.img_url} alt="" /> 
                    </Link>
                  </div>

                  <div
                    className="gsk_product_box_view_text d_font"
                  >
              <Link to={`productdetail/${e?.product_slug}/${e?.product_sku}/${e?.stone_data && e?.stone_data.join('_')}`}>
                      <h3>{e?.product_name}</h3>
                    </Link>
                    <p>{e?.currency}</p>
                    <Link  to={`productdetail/${e?.product_slug}/${e?.product_sku}/${e?.stone_data && e?.stone_data.join('_')}`}
                     className="gsk_product_addToBag_btn btn btn-primary">
                    Add to bag
                    </Link>
                  </div>
                </div>
              ))}
          </Slider>

       
        </Container>
      </section>
    </>
  );
};
export default BestSeller;
