import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import "../../assets/css/custome.css";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
    <div id="gs_scroll_top">
        <Button className="gs_scroll_top" style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}>
        <ArrowUpwardIcon />
        </Button>
    </div>
);
}

export default ScrollButton;
