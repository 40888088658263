import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { postData } from '../../Helpers/request';

const SignupTo = () => {

  const [dynamicMargin, setdynamicMargin] = useState(0);
  useEffect(() => {
    var leftMargin = document.getElementsByClassName('gs_customer_container');
    setdynamicMargin(leftMargin[0].offsetLeft);
  }, [dynamicMargin])

  const [email, setEmail] = useState("");

  const handlesubscribe = (e) => {
    // kleviyoNewsletter()
    e.preventDefault();
    postData("/user/newsLetter", { email })
      .then((res) => {
        if (res.status == 1) {
          setEmail("");
          if (window?.klaviyo) {
            window.klaviyo.push(['identify', {
              'email': email
            }])
          };
          toast.success(res.message, { autoClose: 3000 });
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const kleviyoNewsletter = async () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
    
  //   const raw = JSON.stringify({
  //     "profiles": [
  //       {
  //         "email": "test3333223@gmail.com"
  //       }
  //     ]
  //   });
    
  //   const requestOptions = {
  //     method: "POST", 
  //   };
  //   fetch("https://a.klaviyo.com/api/v2/list/LFGY3u/members?api_key=pk_d2b86ef8eac106d0eef8416ca42a23b209&emails=123@gmail.com", requestOptions)
  //   // fetch("https://a.klaviyo.com/api/v2/list/LFGY3u/members?api_key=pk_d2b86ef8eac106d0eef8416ca42a23b209", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.error(error));

  // }


  return (
    <div className='bgNewsletter'>
      <Row>
        <Col lg={6} md={6} className='d-flex align-items-center'>
          <div className='wrapperNewstetter py-4' style={{ marginLeft: `${dynamicMargin + 15}px` }}>
            <Row>
              <Col lg={8}>
                <div className="d_font">
                  <h2 className='fw600'>Sign up to be a
                    Gem Stone King insider </h2>
                  <p className='f_roboto'> Become a preferred subscriber and get a special offer on your purchase. </p></div>

                <div className="gs_newsletter_input_section">

                  <Form className="gs_news_form my-3" onSubmit={handlesubscribe}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Email Address..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        type="email"
                      />
                      <Button
                        type="submit"
                        disabled={!email.length}
                        id="button-addon2"
                      >
                        Subscribe
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
                <p className='f_roboto'>
                  By signing up you confirm that you have read the Privacy Policy and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.
                </p>
              </Col>
            </Row>


          </div>

        </Col>

        <Col lg={6} md={6}>
          <img src={require('../../assets/images/newsletter_img.jpg').default} alt="" className='w-100' />
        </Col>
      </Row>
    </div>
  )
}

export default SignupTo
