import React, { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import about_line from "../../assets/images/icon/line_black.png";
import jvc_images from "../../assets/images//Home/new-home/gsk_certificate1.png";
import jvc_images2 from "../../assets/images/Home/new-home/gsk_certificate.png";
import { Helmet } from "react-helmet";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Skeleton } from "@material-ui/lab";

const OurStory = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Helmet>
        <title>Our Story | Gem Stone King ™</title>
        <meta
          name="description"
          content='The story of Gem Stone King is the story that proves the saying that “the more things change, the more they remain the same". Today, Gem Stone King is a leading online jewelry company with over 3,000,000 Internet sales. While Internet technology is relatively new, the family that owns Gem Stone King has been in the dia'
        />
        <meta property="og:site_name" content="Gem Stone King ™" />
        <meta
          property="og:url"
          content="http://gsk.tasksource.in:8080/our-story"
        />
        <meta property="og:title" content="Our Story" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content='The story of Gem Stone King is the story that proves the saying that “the more things change, the more they remain the same". Today, Gem Stone King is a leading online jewelry company with over 3,000,000 Internet sales. While Internet technology is relatively new, the family that owns Gem Stone King has been in the dia'
        />
      </Helmet>
      <div className="gs_static_pages_content">
        <Container className="gs_customer_container">
         
          <Row className="gs_main_sec_static">
            <Col lg={6}>
              <div className="gs_static_section1">
                <h2>Our Story</h2>
                <span>
                  The story of Gem Stone King is the story that proves the
                  saying that “the more things change, the more they remain the
                  same".
                </span>
                <span>
                  Today, Gem Stone King is a leading online jewelry company with
                  over 3,000,000 Internet sales. While Internet technology is
                  relatively new, the family that owns Gem Stone King has been
                  in the diamond business for over 100 years.
                </span>
                <span>
                  Our president, Nir Hollander is a fourth generation diamond
                  merchant. Nir’s great grandfather, Elyahu Holzer, started the
                  business in 1903 in Antwerp, Belgium.
                </span>
                <span>
                  From these humble beginnings the family grew the diamond
                  business with locations in New York, Belgium, Israel, and
                  Thailand. Nir 's father, Charlie Hollander, moved to Israel in
                  1966 from where he exported Diamonds all over the world
                  selling to jewelry stores. Fifteen years ago he started the
                  Charles Hollander Collection producing prestigious objects
                  made out of gold and diamonds.
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="gs_static_section1">
                {isMobileOnly ? (
                  <Skeleton
                    style={{
                      display: loading ? "none" : "block",
                      margin: "0 auto",
                    }}
                    variant="rect"
                    animation="wave"
                    width={300}
                    height={150}
                  />
                ) : isTablet ? (
                  <Skeleton
                    style={{
                      display: loading ? "none" : "block",
                      margin: "0 auto",
                    }}
                    variant="rect"
                    animation="wave"
                    width={450}
                    height={430}
                  />
                ) : (
                  <Skeleton
                    style={{ display: loading ? "none" : "block" }}
                    variant="rect"
                    animation="wave"
                    width={700}
                    height={450}
                  />
                )}
                <iframe
                  src="https://player.vimeo.com/video/280808357?title=0&amp;byline=0&amp;portrait=0"
                  style={{ display: loading ? "block" : "none" }}
                  onLoad={() => setLoading(true)}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                  width="100%"
                  height="450px"
                  frameBorder="0"
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="gs_main_sec_static mt-xl-5 mt-lg-5">
            <Col lg={6} className="gs_order-2  mb-md-5">
              <div className="gs_static_section1 ">
                {isMobileOnly ? (
                  <Skeleton
                    style={{
                      display: loading ? "none" : "block",
                      margin: "0 auto",
                    }}
                    variant="rect"
                    animation="wave"
                    width={300}
                    height={150}
                  />
                ) : isTablet ? (
                  <Skeleton
                    style={{
                      display: loading ? "none" : "block",
                      margin: "0 auto",
                    }}
                    variant="rect"
                    animation="wave"
                    width={700}
                    height={450}
                  />
                ) : (
                  <Skeleton
                    style={{ display: loading ? "none" : "block" }}
                    variant="rect"
                    animation="wave"
                    width={700}
                    height={450}
                  />
                )}

                <div className="videoContainer"> 
                <iframe className="responsiveIframe" src="https://www.youtube-nocookie.com/embed/1t2ihYhux5o?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            
              </div>
            </Col>
            <Col lg={6} className="mt-xl-0 mt-lg-0 mt-4 mt-md-5">
              <div className="gs_static_section1 ">
                <div className="gs_static_section1">
                  <h2>The Internet changed all that</h2>
                  <span>
                    The amazing power of the Internet made it possible for us to
                    offer our fine merchandise directly to consumers. And
                    because we buy gems direct from the source, we are able to
                    offer low prices to match our high quality. We began selling
                    our jewelry on E-bay where we earned a customer satisfaction
                    rating of 99.5% over more than 186,000 transactions. Today
                    we are expanding our services on our own website.
                  </span>
                  <span>
                    We are proud to offer our clientele a wide range of products
                    including: beautiful polished diamonds, colored diamonds in
                    a range of exciting colors (pink, rainbow-red, orange,
                    yellow, blue and green), loose gemstones and fine designer
                    jewelry. Our quality is unsurpassed and all of our
                    collections cater to the latest fashion trends.
                  </span>
                  <span>
                    We have rings, earrings, bracelets and pendants with
                    diamonds and semi-precious stones. Everything we sell is of
                    the highest quality and every piece is brand new and maily
                    manufactured in the United States.
                  </span>
                  <span>
                    Our customers benefit because we are diamond wholesalers who
                    can sell to them directly. Because we control the entire
                    process from source to final product we control the costs
                    and are able to maintain the highest quality and to pass
                    savings to our customers.
                  </span>
                  <span>
                    With our old world values and traditions and our mastery of
                    the most advanced Internet technologies, we truly offer our
                    customers the best of both worlds.
                  </span>
                  <span>
                    To all of you, from all of us at Gem Stone King - Thank you
                    and Happy Shopping!
                  </span>
                  <p>
                    Nir Hollander <br /> President
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="gs_main_sec_static pb-5">
            <Col lg={6}>
              <div className="gsk_certificate">
                <Image src={jvc_images} alt=""></Image>
              </div>
            </Col>

            <Col lg={6}>
              <div className="gsk_certificate">
                <Image src={jvc_images2} alt=""></Image>
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurStory;
