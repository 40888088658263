import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Collection2 from "../../assets/images/Home/collection2.png";
import "../../assets/css/Shoping-bag.css";
import { MenuItem, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { BsHeart } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ShopingBag = () => {
  return (
    <>
      <div className="breadCrumb cart_breadcrumb">
        <Container className="gs_customer_container_fluid" fluid>
          <nav aria-label="breadcrumb" className="breadCrumbWrapper">
            <ol className="breadcrumb bg-transparent">
              <li className="breadcrumb-item">
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                Shopping Bag / Payment
              </li>
            </ol>
          </nav>
          <div className="gs_product_top_heading">
            <h1>Shopping Bag</h1>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad rerum
              officiis nesciunt molestiae inventore perferendis
            </p>
          </div>
        </Container>
      </div>

      <section className="gsk_shoping_bag_section">
        <Container className="gs_customer_container">
          <Row className="">
            <Col lg={5} className="gs_shoping_cart_order_2">
              <div className="gs_shopingBag_shipingMethod">
                <p className="gs_montserrat"> Shipping Method </p>
              </div>

              <div className="gs_shiping_option">
                <ul>
                  <li>
                    <div className="gs_shiping_radio">
                      <Form.Check
                        inline
                        label="free expedited shipping (2-3 days)"
                        name="group1"
                        type="radio"
                        id="s1"
                      />
                    </div>

                    <div className="gs_shiping_text">
                      <span> FREE </span>
                    </div>
                  </li>

                  <li>
                    <div className="gs_shiping_radio">
                      <Form.Check
                        inline
                        label="nextDay air (1 day)"
                        name="group1"
                        type="radio"
                        id="s2"
                      />
                    </div>

                    <div className="gs_shiping_text">
                      <span> $37.00 </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="gs_shiping_form">
                <Row>
                  <Col>
                    <p className="gs_montserrat" style={{ fontWeight: "600" }}>
                      Shipping Address
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Form className="w-100">
                    <Col sm={12}>
                      <TextField
                        id="First-Name"
                        label="First Name*"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-3"
                      />
                    </Col>

                    <Col sm={12}>
                      <TextField
                        id="Last-Name"
                        label="Last Name*"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-3"
                      />
                    </Col>

                    <Col sm={12}>
                      <TextField
                        id="email"
                        label="Email Address*"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-3"
                      />
                    </Col>

                    <Col sm={12}>
                      <TextField
                        id="email"
                        type="number"
                        label="Phone*"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-4"
                      />
                    </Col>

                    <Row className="m-0">
                      <Col sm={12}>
                        <TextField
                          id="country"
                          select
                          label="Country"
                          defaultValue=""
                          variant="standard"
                          className="w-100 mb-3"
                        >
                          <MenuItem>0</MenuItem>
                          <MenuItem>1</MenuItem>
                          <MenuItem>2</MenuItem>
                        </TextField>
                      </Col>
                    </Row>

                    <Col sm={12}>
                      <TextField
                        id="standard-select-currency"
                        label="Address"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-3"
                      ></TextField>
                    </Col>

                    <Row className="m-0">
                      <Col sm={4}>
                        <TextField
                          id="city"
                          type="text"
                          label="City"
                          defaultValue=""
                          variant="standard"
                          className="w-100 mb-4"
                        />
                      </Col>

                      <Col sm={4}>
                        <TextField
                          id="state"
                          select
                          label="State"
                          defaultValue=""
                          variant="standard"
                          className="w-100 mb-3"
                        >
                          <MenuItem>0</MenuItem>
                          <MenuItem>1</MenuItem>
                          <MenuItem>2</MenuItem>
                        </TextField>
                      </Col>

                      <Col sm={4}>
                        <TextField
                          id="standard-select-currency"
                          label="Zip code"
                          defaultValue=""
                          variant="standard"
                          className="w-100 mb-3"
                        ></TextField>
                      </Col>
                    </Row>

                    <Row className="m-0 mt-4">
                      <Col>
                        <p
                          className="gs_montserrat"
                          style={{ fontWeight: "600" }}
                        >
                          Billing Address
                        </p>

                        <div className="gs_shiping_radio">
                          <Form.Check
                            inline
                            label="Same as Shipping Address"
                            name="group1"
                            type="checkbox"
                            id="Shipping-Address"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="m-0 mt-4">
                      <Col>
                        <p
                          className="gs_montserrat"
                          style={{ fontWeight: "600" }}
                        >
                          Gift Option
                        </p>

                        <div className="gs_shiping_radio">
                          <Form.Check
                            inline
                            label="Same as Shipping Address"
                            name="group1"
                            type="checkbox"
                            id="Shipping-Address"
                          />
                        </div>

                        <Form.Group
                          className="mt-2 mb-4 gs_shiping_textarea"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control as="textarea" rows={3} />
                          <Form.Text id="passwordHelpBlock" muted>
                            150 Characters Remaining
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Row>
              </div>
              <div className="gs_shiping_bag_btn">
                <Button> CONTINUE TO PAYMENT </Button>
              </div>
            </Col>
            <Col lg={7}>
              {/* product item main div  */}
              <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box">
                <Row>
                  <Col sm={12} md={4} lg={6} xl={3}>
                    <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={8}>
                    <h2 className="gs_wish_title gs_shooping_bag">
                      Sterling Silver Women Solitaire Ring
                    </h2>

                    <h2 className="gs_wish_title gs_shooping_bag_price">
                      $49.99
                    </h2>
                    <div className="gs_cart_quantitys gs_shoping_bag">
                      <span className="mr-3 gs_q_size">Quantity </span>
                      <InputGroup className="mt-2 input-group">
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <RemoveIcon />
                        </Button>
                        <input
                          className="gs_shoping_cart_count_inp"
                          type="text"
                          value="1"
                          disabled
                          name="name"
                        />
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>

                    <Row className="w-100 m-auto">
                      {/* {val.product[0].engraving?.filter(val => val.value?.length > 0).length ? */}
                      <Col xs={12} sm={12} className="p-0">
                        <span className=" mt-2 gs_eng_txt">
                          <BsHeart className="mr-3 f-20" />
                          <AiOutlineDelete className="" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="gs_shoping_bag_cart_accordion">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Customizations
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="gs_shoping_bag_cart_customiz">
                          <ul>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone Selection:
                              </span>
                              <span> 1</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 1:
                              </span>
                              <span> Emerald</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 2:
                              </span>
                              <span> Citrine</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving Options:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Fonts:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 1:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 2:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 3:
                              </span>
                              <span> test</span>
                            </li>
                          </ul>
                          <p> Edit </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              
              {/* product item main div  */}
              <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box">
                <Row>
                  <Col sm={12} md={4} lg={6} xl={3}>
                    <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={8}>
                    <h2 className="gs_wish_title gs_shooping_bag">
                      Sterling Silver Women Solitaire Ring
                    </h2>

                    <h2 className="gs_wish_title gs_shooping_bag_price">
                      $49.99
                    </h2>
                    <div className="gs_cart_quantitys gs_shoping_bag">
                      <span className="mr-3 gs_q_size">Quantity </span>
                      <InputGroup className="mt-2 input-group">
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <RemoveIcon />
                        </Button>
                        <input
                          className="gs_shoping_cart_count_inp"
                          type="text"
                          value="1"
                          disabled
                          name="name"
                        />
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>

                    <Row className="w-100 m-auto">
                      {/* {val.product[0].engraving?.filter(val => val.value?.length > 0).length ? */}
                      <Col xs={12} sm={12} className="p-0">
                        <span className=" mt-2 gs_eng_txt">
                          <BsHeart className="mr-3 f-20" />
                          <AiOutlineDelete className="" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="gs_shoping_bag_cart_accordion">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Customizations
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="gs_shoping_bag_cart_customiz">
                          <ul>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone Selection:
                              </span>
                              <span> 1</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 1:
                              </span>
                              <span> Emerald</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 2:
                              </span>
                              <span> Citrine</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving Options:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Fonts:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 1:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 2:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 3:
                              </span>
                              <span> test</span>
                            </li>
                          </ul>
                          <p> Edit </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>

              {/* subtotal  */}
              <div className="gs_shoping_bag_cart_subTotal">
                <ul>
                  <li>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto"> Subtotal </h5>
                      <h5> $252.56 </h5>
                    </div>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto"> Expedited Shipping </h5>
                      <h5 style={{ textTransform: "uppercase" }}> free </h5>
                    </div>
                  </li>

                  <li>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto">
                        Grand Total Excluding Tax <br />
                        <p className="gs_roboto"> Grand Total Excluding Tax </p>
                      </h5>

                      <h5> $259.99 </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ShopingBag;
