import React, { useEffect, useState } from 'react';
import { Container, Row, InputGroup, Col, Image, Modal } from 'react-bootstrap';
import { Button, IconButton, InputLabel, MenuItem, Select, FormControl, CircularProgress, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import '../../assets/css/wishlist.css'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import payment_icon from "../../assets/images/footer/payment_icon.svg";
import { Link, NavLink, useHistory } from 'react-router-dom';
import { getData, postData, deleteData, putAuthData, putData, isLogin } from '../../Helpers/request';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { toast } from 'react-toastify';
import emptyCart from "../../assets/images/icon/mycart.svg";
import { Helmet } from 'react-helmet';
import { Skeleton } from '@material-ui/lab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import gs_ringsicon from '../../assets/images/Home/rings.jpg';
import gs_pendanticon from '../../assets/images/Home/Pendant.jpg';
import gs_earringicon from '../../assets/images/Home/Earrings.jpg';
import gs_braceletsicon from '../../assets/images/Home/Bracelet.jpg';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 110,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const MiniCart = (props) => {
    const selector= useSelector(state=> state);
    const history = useHistory();
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const [coupon, setCoupon] = React.useState('');
    const [cartid, setCartid] = React.useState('');
    const [curr_icon, setCurr_icon] = React.useState('');
    const [curr_ring_size, setCurr_ring_size] = React.useState([]);
    const [productsdata, setProductsdata] = React.useState([]);
    const [mycart, setMycart] = React.useState(localStorage.getItem("cartlength"));
    const [quantities, setQuantities] = React.useState([]);
    const [ivalue, setIvalue] = React.useState(null);
    const [total_cart_price, setTotal_cart_price] = React.useState(null);
    const [discount, setDiscount] = React.useState(0);
    const [amount, setAmount] = React.useState(null);
    const [couponcode, setCouponcode] = React.useState(null);
    const [checkoutdisabled, setCheckoutdisabled] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [noRingsize, setNoRingsize] = useState(false);
    const [overnightshipping, setOvernightshipping] = useState(false);

    const handleChange = (e, i) => {
        setCurr_ring_size(curr_ring_size.map((x, index) => {
            if (i == index) {
                postsize(i, e.target.value);
                return x = e.target.value;
            }
            return x;
        }))
    }


    const incNum = (i) => {
        // quantities[i] = Number(quantities[i]) + 1;
        setIvalue(i);
        setQuantities(quantities.map((x, index) => {
            if (i === index) {
                // postquantity(i, x + 1);
                return x + 1;
            }
            return x;
        }))
    };
    const decNum = (i) => {
        setIvalue(i);
        if (quantities[i] > 1) {
            // quantities[i] = Number(quantities[i]) - 1;
            setQuantities(quantities.map((x, index) => {
                if (i === index) {
                    // postquantity(i, x - 1);
                    return x - 1;
                }
                return x;
            }))
        } else {
            setQuantities(quantities.map((x, index) => {
                if (i === index)
                    return x = 1;
                return x;
            }))
        }
    };
    const postquantity = (i) => {
        setCount(0);
        var quantity = 0;
        quantities.map((x, index) => {
            if (i === index) {
                quantity = x;
            }
        })
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            product_id: productsdata?.cartlist[i]?.product[0]?.id,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            stone_data: productsdata?.cartlist[i]?.product[0]?.stone_data?.length ? Object.values(productsdata?.cartlist[i]?.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "",
            ring_size: curr_ring_size[i],
            quantity: quantity,
            engraving: productsdata?.cartlist[i]?.engraving,
            font_style: productsdata?.cartlist[i]?.font_style,
            jewelry_box: productsdata?.cartlist[i]?.jewelry_box,
        }
        postData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const Overnight = (check) => {
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            is_overnight_shipping: check
        }
        postData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    setOvernightshipping(check)
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const postsize = (i, x) => {
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            product_id: productsdata.cartlist[i].product[0].id,
            cart_id: productsdata.cartlist[i].id,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            stone_data: productsdata?.cartlist[i]?.product[0]?.stone_data?.length ? Object.values(productsdata?.cartlist[i]?.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "",
            ring_size: x,
            quantity: quantities[i],
            engraving: productsdata?.cartlist[i]?.engraving,
            font_style: productsdata?.cartlist[i]?.font_style,
            jewelry_box: productsdata?.cartlist[i]?.jewelry_box,
        }
        putData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getcoupon = () => {
        setCouponcode("");
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/coupon?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}&coupon_code=${coupon ? coupon : sessionStorage.getItem("coupon")}`)
            .then((response) => {
                if (response.status == 1) {
                    setTotal_cart_price(response.data.final_amount)
                    setDiscount(response.data.discount_amount)
                    setAmount(response.data.total_amount)
                    setCouponcode(response.data.coupon_code)
                    sessionStorage.setItem("coupon", response.data.coupon_code);
                    setCoupon("")
                    setMessage("")
                    // toast.success(response.message, { autoClose: 3000 });
                } else if (response.status == 0) {
                    // toast.error(response.message, { autoClose: 3000 });
                    setMessage(response.message)
                    sessionStorage.removeItem("coupon")
                    setCoupon(null);
                    setDiscount(0)
                    setAmount(null)
                    setTotal_cart_price(null)
                    setCouponcode(null)
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const Deleteitem = (id) => {
        deleteData(`/order/cartItems?cart_id=${id}`)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                    if(history.location.pathname=="/checkout"){
                        handleClose()
                        history.push("/cart");
                       
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getcartdata = () => {
        setCheckoutdisabled(false)
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/cartItems?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then(res => {
                if (res.status == 1) {
                    setQuantities([])
                    //setCurr_ring_size([])
                
                    setProductsdata(res.data)
                    setTotal_cart_price(res.data.total_cart_price)
                    setAmount(res.data.cart_price)
                    setOvernightshipping(res.data.overnight_shipping_flag)
                    localStorage.setItem("cartlength", res.data.cart_item_count)
                    setMycart(res.data.cart_item_count);
                    setCurr_icon(res.data.cartlist[0]?.product[0]?.currency_icon)
                    res.data.cartlist?.map((val, index) => {
                        //quantities.push(val.quantity)
                        setQuantities(quantities => [...quantities, val.quantity])
                        setCurr_ring_size(curr_ring_size => [...curr_ring_size, val.ring_size])
                        if (val.product[0].ring_size?.filter(val => val.value?.length > 0).length) {
                            if (val.ring_size == 0) {
                                setCheckoutdisabled(true)
                            }
                        }
                        // if (val.product[0].engraving?.filter(val => val.value?.length > 0).length) {
                        //     if (val.engraving == 0) {
                        //         setCheckoutdisabled(false)
                        //     }
                        // }
                    })
                    if (sessionStorage.getItem("coupon")?.length) {
                        getcoupon();
                    }

                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const deletecoupon = () => {
        sessionStorage.removeItem("coupon")
        setCoupon(null);
        setDiscount(0)
        setAmount(null)
        setTotal_cart_price(null)
        setCouponcode(null)
        getcartdata();
    }
    useEffect(() => {
        getcartdata();
    
      
    }, [])
    const redirect = (slug, sku, stone_data) => {
        history.push('/productdetail/' + slug + "/" + sku + (stone_data.length ? "/" + Object.values(stone_data)?.toString()?.replaceAll(",", "_") : ""));
    }
    const checkoutclick = () => {
        if (checkoutdisabled) {
            setNoRingsize(true)
            // setTimeout(() => { setNoRingsize(false) }, 2000);
        } else {
            history.push("/checkout")
            window.scrollTo(0, 0)
        }
    }
    const [remove, setRemove] = useState(false);
    const handleClose = () => setRemove(false);
    const handleShow = () => setRemove(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [count, setCount] = useState(0);
    const imageLoaded = () => {
        setLoading(true);
    }
    return (
        <>
            <Helmet>
                <title>Cart | Gem Stone King ™</title>
                <meta name="description" content="Cart" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/cart" />
                <meta property="og:title" content="Cart" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Cart" />
            </Helmet>
           
            <div className="gs_wishlist_content miniCartWrapper">
                <Container className="gs_customer_container">
                    <Row className="m-0 w-100">
                        <div className="gs_wish_heading1">
                            <h3>Shopping Bag <span>{mycart == 1 ? `(${mycart})` : mycart > 1 ? `(${mycart})` : ""}</span></h3>
                            {
                                !isLogin &&  <>
                                <p className='mb-1 f_roboto f400'>
                                For express checkout. Sign In Now.
                            </p>
                                <p className='text-left f_roboto'>
                                <Link className='colorBlack' to='/sign-in' onClick={()=>props?.callback(false)}>Sign In > </Link>
                            </p>
                                </>    
                            }
                        
                          
                        </div>
                    </Row>
                    {mycart > 0 ?
                        <Row className="pb-3">
                            <Col sm={12} md={12} lg={8} xl={12} className="">
                                <Row className="m-0 w-100">
                                    <Modal
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered className="gs_appointment_modal gs_cart" show={remove} onHide={() => setRemove(false)}>
                                        <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="gs_appointment_input gs_noradius">
                                                <DeleteOutlineIcon className="gs_remove_cart" />
                                                <Modal.Title>Are you sure you want to remove this item ?</Modal.Title>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" className="gs_fill_button" onClick={() => { Deleteitem(); handleClose() }}>
                                                Yes
                                            </Button>
                                            <Button variant="primary" className="gs_outline_button" onClick={() => setRemove(false)}>
                                                No
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                   
                                    {productsdata?.cartlist?.map((val, index) =>
                                        <Col sm={12} md={12} lg={12} xl={12} className="mb-3 p-0">
                                            <div className="gs_my_wishlist gs_my_cart">
                                    

                                                <Row>
                                                    <Col sm={12} md={3} lg={3} xl={3}>
                                                        {isMobileOnly ?
                                                            <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={87} height={87} /> : isTablet ? <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={87} height={87} /> : <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={220} height={220} />
                                                        }
                                                        <div className='gsk_product_box_view_img pointer'>
                                                        <Image className='img-fluid' style={{ display: loading ? "block" : "none" }} onLoad={() => imageLoaded(val.product[0]?.img_url)} src={val.product[0]?.img_url}></Image>
                                                        </div>
                                                    
                                                    </Col>
                                                    <Col sm={12} md={9} lg={9} xl={9}>
                                                        <h2 className="gs_wish_title mb-0" onClick={() => redirect(val.product[0]?.product_slug, val.product[0]?.Product_sku, val.product[0]?.stone_data)}>{val.product[0]?.product_name} </h2>
                                                        {/* <p className="gs_sku">{val.product[0]?.Product_sku}</p> */}
                                                        <h2 className="gs_wish_title">{val.product[0]?.currency_icon}{val.product[0]?.product_price}{val.jewelry_box ? ` + ${val.product[0]?.currency_icon}${val.jewelry_box_price} (Customize Gift Box)  ` : ""} </h2>
                                                        <div className="gs_cart_quantitys align-items-center">
                                                            <span className="mr-3 fs12">Quantity </span>
                                                            <div className="mb-3 mt-2 d-flex">
                                                                <Button variant="outline-secondary" className="gs_increment_btn" id="button-addon1" onMouseLeave={(e) => count > 0 ? postquantity(ivalue) : ""} onClick={() => { setCount(count + 1); decNum(index) }}>
                                                                    <RemoveIcon />                                                                 </Button>
                                                                <input type="text" className='' value={quantities[index]} disabled name="name" />
                                                                <Button variant="outline-secondary" className="gs_increment_btn" id="button-addon1" onMouseLeave={(e) => count > 0 ? postquantity(ivalue) : ""} onClick={() => { setCount(count + 1); incNum(index) }}>
                                                                    <AddIcon />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <Row className='align-items-center'>
                                                            <Col lg={8}>
                                                            {val.product[0]?.ring_size?.filter(val => val.value?.length > 0).length ?
                                                            <Row className="gsk_cart_ringsize mt-2">
                                                               
                                                                <div className={noRingsize && (curr_ring_size[index] == 0) ? "gs_appointment_input gs_noradius ml-3 mt-3 d-block gs_details_ring_size animate__animated animate__shakeX gs_sel_sizerequired" : "gs_appointment_input gs_noradius d-block gs_details_ring_size "}>
                                                                    <FormControl variant="outlined" className={classes.formControl} style={{ margin: "0px !important" }}>
                                                                        <InputLabel id="demo-simple-select-outlined-label">Ring Size</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-outlined-label"
                                                                            id="demo-simple-select-outlined"
                                                                            label="Ring Size"
                                                                            value={String(curr_ring_size[index])}
                                                                            //value={curr_ring_size[index]}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                            size="small"
                                                                        >
                                                                            {val.product[0]?.ring_size.map(item =>
                                                                                <MenuItem value={item.value} >{item.value}</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </Row> : ""}
                                                            </Col>
                                                            <Col lg={4}>
                                                            <div onClick={() => { setCartid(val.id); Deleteitem(val.id)}}  className="fs12 d-flex justify-content-end">
                                                              {/* <DeleteIcon /> */}
                                                            Remove
                                                             </div>
</Col>
                                                        </Row>
                                                    

                                                       
                                                 
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}

                                    <div className="gs_cart_row d-flex justify-content-between w-100 pt-3 mt-0">
                                    <div className='text-left'>
                                    <p className="gs_summary_label text-uppercase f_roboto text-left fs12 colorBlack"> Sub Total </p>
                                            <p className="gs_summary_label f_roboto text-left fs11"> Excluding Shipping and Taxes </p>
                                    </div>
                                      
                                            <p className='f_roboto'>{curr_icon}{total_cart_price}</p>
                                        </div>

                                        <div className='miniButton w-100'>
                                            {/* <Link to='/checkout' className='d-block btnBlue w-100 mb-2 fs13' onClick={()=>props?.callback(false)}>
                                            Checkout
                                            </Link> */}
                                            <Link to='/cart' className='d-block btnGrey fs13 w-100' onClick={()=>props?.callback(false)}>
                                            View Shopping Bag
                                            </Link>
                                        </div>
                                </Row>
                            </Col>
                   
                        </Row> : <Row className='w-100 d-block m-auto'><NavLink to="/"> <Image className="gs_empty_cart" src={emptyCart} alt="cart empty image"></Image> </NavLink>
                            <Divider />
                            <ul className="gs_no_prod_item mt-5">
                                <li onClick={() => history.push('/category/shop-rings/category=ring')}>
                                    <img className="gs_noprod_img" src={gs_ringsicon} />
                                    Shop Rings
                                </li>
                                <li onClick={() => history.push('/category/shop-pendants/category=pendant')}>
                                    <img className="gs_noprod_img" src={gs_pendanticon} />
                                    Shop Pendants</li>
                                <li onClick={() => history.push('/category/shop-earrings/category=earrings')}>
                                    <img className="gs_noprod_img" src={gs_earringicon} />
                                    Shop Earrings</li>
                                <li onClick={() => history.push('/category/shop-bracelets/category=bracelet')}>
                                    <img className="gs_noprod_img" src={gs_braceletsicon} />
                                    Shop Bracelets</li>
                            </ul>
                        </Row>
                    }
                </Container>
            </div>

        </>
    )
}

export default MiniCart
