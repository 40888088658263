import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../../assets/css/invoice.css";
import logoImage from '../../assets/images/logo.svg';
import { Button, Divider } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PrintIcon from '@material-ui/icons/Print';
import { getData } from '../../Helpers/request';
import moment from 'moment';

const Invoice = (props) => { 
    const [orderdetail,setOrderdetail] = useState([])
    useEffect(() => {
        document.getElementById("navbar").style.display = "none";
        document.getElementById("footer").style.display = "none";
        document.getElementById("gs_scroll_top").style.display = "none";
        getinvoice();
    }, [])
    const getinvoice = () =>{
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : props.match.params.uid;
        getData(`/order/orderdetail?user_id=${user_id}&order_id=${props.match.params.order_id}`)
        .then(res =>{
            if(res.status == 1){
                setOrderdetail(res.data[0])
            }
        })
    }
    return (
        <>
            <Helmet>
                <title>Invoice | Gem Stone King ™</title>
                <meta name="description" content="Invoice" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/invoice" />
                <meta property="og:title" content="Invoice" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Invoice" />
            </Helmet>
            <div className="gs_myaccount">
                <section className="theme-invoice-1 section-b-space">
                    <Container className="gs_customer_container">
                        <Row className="m-auto w-100 justify-content-center">
                            <Col xl={8}>
                                <div className="invoice-wrapper">
                                    <div className="invoice-header">
                                        <div className="upper-icon">
                                            <Image
                                                src="http://themes.pixelstrap.com/multikart/assets/images/invoice/invoice.svg"
                                                alt="Invoice"
                                            />
                                        </div>
                                        <div className="row header-content">
                                            <div className="col-sm-6">
                                                <Image
                                                    src={logoImage}
                                                    alt="Gemstone King"
                                                    style={{ width: "250px" }}
                                                />
                                                <div className="mt-md-4 mt-3">
                                                    <h4 className="mb-0">support@gemstoneking.com</h4>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 text-sm-end mt-md-0 mt-4">
                                                <h2>invoice</h2>
                                                <div className="mt-md-4 mt-3">
                                                    <h4 className="mb-2">555 Eighth Avenue,</h4>
                                                    <h4 className="mb-0"> New York, NY 10018</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-bottom">
                                            <ul>
                                                <li>
                                                    <strong>issue date :</strong>
                                                    <h4>{moment(new Date(orderdetail.created_at)).format("YYYY-MM-DD")}</h4>
                                                </li>
                                                <li>
                                                    <strong>invoice no :</strong>
                                                    <h4>{orderdetail.order_no}</h4>
                                                </li>
                                                <li>
                                                    <strong>email :</strong>
                                                    <h4> {orderdetail.email}</h4>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="invoice-body table-responsive-md">
                                        <table className="table table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>#</strong></th>
                                                    <th scope="col"><strong>Image</strong></th>
                                                    <th scope="col"><strong>Name</strong></th>
                                                    <th className="trest" scope="col"><strong>Gift Box</strong></th>
                                                    <th scope="col"><strong>Price</strong></th>
                                                    <th scope="col"><strong>Quantity</strong></th>
                                                    <th scope="col"><strong>Total</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderdetail.orderitem?.map((val,index) => 
                                                <tr>
                                                    <th scope="row">{index+1}</th>
                                                    <td><Image src={val.image_url} width="50px" height="50px"></Image></td>
                                                    <td>{val.name}</td>
                                                    <td>{orderdetail.currency_icon}{val.jewelry_box_price}</td>
                                                    <td>{orderdetail.currency_icon}{val.product_price}</td>
                                                    <td>{val.quantity}</td>
                                                    <td>{orderdetail.currency_icon}{val.price}</td>
                                                </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td />
                                                    <td className="font-bold text-dark" colSpan={4}>
                                                        <strong>TOTAL</strong>
                                                    </td>
                                                    <td className="font-bold text-theme"> {orderdetail.currency_icon}{orderdetail.sub_total}</td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td className="font-bold text-dark" colSpan={4}>
                                                        <strong>DISCOUNT</strong>
                                                    </td>
                                                    <td className="font-bold text-theme">{orderdetail.currency_icon}{orderdetail.discount_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td className="font-bold text-dark text-uppercase" colSpan={4}>
                                                        <strong>Tax Amount</strong>
                                                    </td>
                                                    <td className="font-bold text-theme">{orderdetail.currency_icon}{orderdetail.tax_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td className="font-bold text-dark" colSpan={4}>
                                                        <strong>GRAND TOTAL</strong>
                                                    </td>
                                                    <td className="font-bold text-theme"> {orderdetail.currency_icon}{orderdetail.usd_total_amount}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <Row className="gs_address_details">
                                        <Col sm={6}>
                                            <div className="gs_add_box" style={{boxShadow: "none"}}>
                                                <address>
                                                    <p className="mb-3"><strong>Billing Address </strong></p>
                                                    <Divider />
                                                    <p className="mt-3"><strong>Name </strong> </p>
                                                    <p><span>{orderdetail.billing_address?.first_name} {orderdetail.billing_address?.last_name}</span></p>
                                                    <p><strong>Address </strong> </p>
                                                    <p><span>{orderdetail.billing_address?.address_line_1}, {orderdetail.billing_address?.city}, {orderdetail.billing_address?.state},  {orderdetail.billing_address?.country}, {orderdetail.billing_address?.zip_code}</span></p>
                                                    <p><strong>Mobile Number </strong></p>
                                                    <p><span>{orderdetail.billing_address?.contact_no}</span></p>
                                                    <Divider />
                                                    <Button className="gs_address_btn">{orderdetail.billing_address?.address_for == "H" ? "Home" : "Office"}</Button>
                                                </address>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="gs_add_box" style={{boxShadow: "none" }}>
                                                <address>
                                                    <p className="mb-3"><strong>Shipping Address </strong></p>
                                                    <Divider />
                                                    <p className="mt-3"><strong>Name </strong> </p>
                                                    <p><span>{orderdetail.shipping_address?.first_name} {orderdetail.shipping_address?.last_name}</span></p>
                                                    <p><strong>Address </strong> </p>
                                                    <p><span>{orderdetail.shipping_address?.address_line_1}, {orderdetail.shipping_address?.city}, {orderdetail.shipping_address?.state},  {orderdetail.shipping_address?.country}, {orderdetail.shipping_address?.zip_code}</span></p>
                                                    <p><strong>Mobile Number </strong></p>
                                                    <p><span>{orderdetail.shipping_address?.contact_no}</span></p>
                                                    <Divider />
                                                    <Button className="gs_address_btn">{orderdetail.shipping_address?.address_for == "H" ? "Home" : "Office"}</Button>
                                                </address>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="invoice-footer">
                                        <Col sm={6}>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="buttons">
                                                <Button className="gs_print" onClick={() => window.print()}> <PrintIcon /> Print</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}
export default Invoice;
