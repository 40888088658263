import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/home.css";
import Collection1 from "../../assets/images/Home/collection1.png";
import Collection2 from "../../assets/images/Home/collection2.png";

const OurCollection = () => {
  return (
    <>
      <section className="gsk_our_collection_section">
        <Container className="gs_custome_fluid_container" fluid>
          <Row className="align-items-center">
            <Col lg={6} xs={12} className="pr-0">
              <div className="gsk_our_collection_img">
                <img className="w-100" src={Collection1} />
              </div>
            </Col>
            <Col lg={6} xs={12} className="p-0">
              <div className="gsk_our_brand_text_content gsk_our_collection_text">
                <h2 className="d_font"> Our Exclusive Collection </h2>
                <p>
                  Gem Stone King has a wide range of fine jewelry pieces from
                  Pendants, Engagement Rings, Earrings and Bracelets all are
                  available in a vast variety of Stones, Stone Colors, Precious
                  Metals of 10K, 14K, 18K Gold and 925 Sterling Silver.
                </p>
                <p>Gem Stone King is the Leading Online Jewelry Retailer</p>
                <Button className="gsk_our_brand_btn"> <Link to="/category/shop-pendants/category=pendant" > Discover More </Link> </Button>
              </div>
            </Col>

            
            <Col lg={6} xs={12} className="p-0 collection_order_2">
              <div className="gsk_our_brand_text_content gsk_our_collection_text">
                <h2 className="d_font">Personalize Your Jewelry </h2>
                <p className="gs-w-short_text">
                All of our pieces go through intense and rigorous quality control process in our home office in New York to ensure that our 
jewelry is up to standard and built to last.
                </p>
              
                <Button className="gsk_our_brand_btn"> <Link to="productlist/shop-pendants/style/personalized/category=pendant/style=" > Discover More </Link> </Button>
              </div>
            </Col>
            <Col lg={6} xs={12} className="p-0 mt-5 mt-xl-0 mt-lg-0 mt-md-0">
              <div className="gsk_our_collection_img">
                <img className="w-100" src={Collection2} />
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </>
  );
};
export default OurCollection;
