import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Truck from "../../assets/images/Home/new-home/truck.png";
import Source from "../../assets/images/Home/new-home/source.png";
import Design from "../../assets/images/Home/new-home/design.png";
import Warranty from "../../assets/images/Home/new-home/warranty.png";
import Help from "../../assets/images/Home/new-home/help.png";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

let data = [
  {
    img: Truck,
    title: "FREE 2-Day Shipping",
    link: "#",
  },
  {
    img: Source,
    title: "We Are The Source",
    link: "#",
  },
  {
    img: Design,
    title: "Our Own Unique Designs",
    link: "#",
  },
  {
    img: Warranty,
    title: "Lifetime Warranty",
    link: "#",
  },
  {
    img: Help,
    title: "World Class Customer Service Mon-Fri 9am-5pm Eastern",
    link: "#",
  },
];

const GskDifference = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="14.526" height="22.507" viewBox="0 0 14.526 22.507">
    <path id="Icon_awesome-angle-right" data-name="Icon awesome-angle-right" d="M15.771,19.2,6.209,28.758a1.681,1.681,0,0,1-2.384,0L2.236,27.169a1.681,1.681,0,0,1,0-2.384l6.778-6.778L2.236,11.229a1.681,1.681,0,0,1,0-2.384l1.582-1.6a1.681,1.681,0,0,1,2.384,0L15.764,16.8a1.683,1.683,0,0,1,.007,2.391Z" transform="translate(-1.74 -6.746)" fill="#fff"/>
  </svg>
  ,
    prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="14.533" height="22.507" viewBox="0 0 14.533 22.507">
    <path id="Icon_awesome-angle-left" data-name="Icon awesome-angle-left" d="M2.229,16.8l9.563-9.562a1.681,1.681,0,0,1,2.384,0l1.589,1.589a1.681,1.681,0,0,1,0,2.384L8.993,18l6.778,6.778a1.681,1.681,0,0,1,0,2.384l-1.589,1.6a1.681,1.681,0,0,1-2.384,0L2.236,19.2A1.683,1.683,0,0,1,2.229,16.8Z" transform="translate(-1.734 -6.746)" fill="#fff"/>
  </svg>
  
  };

  return (
    <>
      <section className="gsk_difference_section">
        <Container className="gs_customer_container c-90">
          <Row className="align-items-center mxMinus15">
            <Col lg={12} xs={12}>
              <div className="gsk_difference_bg">
                <div className="gsk_difference_title">
                  <h2 className="d_font"> Gem Stone King Difference </h2>
                </div>

                {isMobile ? (
                  <Slider {...settings}>
                  {data.map((e, i) => (
                      <div key={i} className="gsk_difference_box slider_carousel">
                        <div className="d-flex justify-content-center">
                        <img src={e.img} alt="truck" />
                        </div>
                        <p> {e.title} </p>
                        <Link to={e.link}> Learn More </Link>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="gsk_difference_content_main">
                    {data.map((e, i) => (
                      <div key={i} className="gsk_difference_box">
                        <img src={e.img} alt="truck" />
                        <p> {e.title} </p>
                        <Link to={e.link}> Learn More </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default GskDifference;
