import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import bannerimg from "../../assets/images/Home/banner_d.jpg";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const HomeBanner = () => {
  const history = useHistory();
  const[widthSize,setwidthSize]=useState(window.screen.width);
  const resizeScreen=()=>{
    setwidthSize(window.screen.width);
  }
  useEffect(()=>{
    resizeScreen()
    window.addEventListener("resize",resizeScreen)
    return ()=>{
      window.addEventListener("resize",resizeScreen)
    }
  },[])


  const bannerdata = localStorage.getItem("bannerdata")
    ? JSON.parse(localStorage.getItem("bannerdata"))
    : [];

  return (
    <>
      <div
        className="gs_hero_slider position-relative"
        //style={{ backgroundImage: `url(${bannerimg})` }}
      >
      {
        bannerimg ?  
      <Link className="w-100" to="/category/best-sellers/category=bestseller">
          
        <img src={widthSize > 768 ? require("../../assets/images/Home/mother_day.jpg").default : require("../../assets/images/Home/mother_day_mob.jpg").default} className='img-fluid w-100' alt="" /></Link>  :
      <Skeleton
                                          style={{ display: "block" }}
                                          variant="rect"
                                          animation="wave"
                                          width='100%'
                                          height={791}/>
      }
      
{/*      
      <div className="captionSl">
      <Container className="gs_customer_container c-90">
          <Row>
            <Col>
              <div className={"gs_hero_img text-md-center"}>
                <p className="text-white">Save up to <span className="gs_lora"> 30% </span> OFF</p>
                
                <h1 className="colorRed fontCursive fs55">Celebrate Your Love</h1>
                <div className="text-uppercase colorRed fw500 fs20">
                -Shop Gifts-
                </div>

                <Button
                  onClick={() => {
                    sessionStorage.removeItem("list_filter");
                    history.push("/category/rings/category=ring");
                  }}
                  variant="outline-dark"
                >
                  Shop Gifts
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
   
      </div>
    </>
  );
};
export default HomeBanner;
