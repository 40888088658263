import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Tabs,
  Tab,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  Table,
  Divider,
  Button,
  Radio,
  RadioGroup,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  CircularProgress, Select, FormControl, MenuItem, InputLabel
} from "@material-ui/core";

import { Container, Row, Col, Image, InputGroup } from "react-bootstrap";
import "../../assets/css/myaccount.css";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import {
  deleteData,
  getAuthData,
  getData,
  postAuthData,
  postData,
  putAuthData,
} from "../../Helpers/request";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import moment from "moment";
import Logout from "../../assets/images/Home/new-home/logout.png";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { BsHeart } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import usStates from "./statesUs.json"
import { counter } from "@fortawesome/fontawesome-svg-core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f5f8fe",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(sNo, order_id, price, date, status) {
  return { sNo, order_id, price, date, status };
}

const rows = [
  createData(1, 908452, 59.99, "20 Sep 2021", "Active"),
  createData(2, 908453, 59.99, "20 Sep 2021", "Active"),
];

const MyAccount = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const [addressshow, setAddressShow] = useState(false);
  const [confirmpassword, setConfirmpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [currpassword, setCurrpassword] = useState("");
  const [accountdetails, setAccountDetails] = useState([]);
  const [addressdetails, setAddressdetails] = useState([]);
  const [add_fn, setAdd_fn] = useState("");
  const [add_ln, setAdd_ln] = useState("");
  const [add_for, setAdd_for] = useState("");
  const [add_forO, setAdd_forO] = useState(false);
  const [add_forH, setAdd_forH] = useState(false);
  const [add_line1, setAdd_line1] = useState("");
  const [add_line2, setAdd_line2] = useState("");
  const [add_landmark, setAdd_landmark] = useState("");
  const [add_city, setAdd_city] = useState("");


  const [add_Zc, setAdd_Zc] = useState("");
  const [add_shipping, setAdd_shipping] = useState(false);
  const [add_billing, setAdd_billing] = useState(false);
  const [add_country, setAdd_country] = useState('United States');
  const [add_contact, setAdd_contact] = useState(null);
  const [addressUpdate, setAddressUpdate] = useState(false);
  const [editValue, setEditValue] = useState([]);
  const [add_state, setAdd_state] = useState("");
  const [stateAbbr, setstateAbbr] = useState("");
  const [acc_gender, setAcc_gender] = useState("");
  const [acc_firstname, setAcc_firstname] = useState("");
  const [acc_lastname, setAcc_lastname] = useState("");
  const [acc_email, setAcc_email] = useState("");
  const [acc_ringsize, setAcc_ringsize] = useState("");
  const [acc_mobile, setAcc_mobile] = useState("");
  const [acc_DOB, setAcc_DOB] = useState("");
  const [acc_isSubscribe, setAcc_isSubscribe] = useState(null);
  const [orderlist, setOrderlist] = useState([]);

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  let history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange3 = (event) => {
    setAdd_shipping(event.target.checked);
  };
  const handleChange4 = (event) => {
    setAdd_billing(event.target.checked);
  };
  const handleChange5 = (event) => {
    if (event.target.name == "home") {
      setAdd_for("H");
      setAdd_forH(true);
      setAdd_forO(false);
    } else if (event.target.name == "office") {
      setAdd_for("O");
      setAdd_forO(true);
      setAdd_forH(false);
    }
  };
  const handlelogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("wishlistlenth");
    localStorage.removeItem("cartlength");
    localStorage.removeItem("session_id");
    history.push("/");
    window.location.reload(true);
  };
  const resetadd = () => {
    setAdd_fn("");
    setAdd_ln("");
    setAdd_for("");
    setAdd_forH(false);
    setAdd_forO(false);
    setAdd_line1("");
    setAdd_line2("");
    setAdd_landmark("");
    setAdd_city("");
    setAdd_Zc("");
    setAdd_contact(null);
    setAdd_country('United States');
    setAdd_state("");
    setAdd_shipping(false);
    setAdd_billing(false);
  };
  const addAddress = (e) => {
    e.preventDefault();
    if (add_forH || add_forO) {
      const data = {
        user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
        first_name: add_fn,
        last_name: add_ln,
        address_for: add_for,
        address_line_1: add_line1,
        address_line_2: add_line2,
        landmark: add_landmark,
        city: add_city,
        state: stateAbbr,
        contact_no: add_contact,
        country: 'US',
        zip_code: add_Zc,
        shipping: add_shipping ? 1 : 0,
        billing: add_billing ? 1 : 0,
      };
      postAuthData(`/user/userAddress`, data)
        .then((res) => {
          if (res.status == 1) {
            resetadd();
            setAddressShow(false);
            setAddressdetails(res.data[0]);
            userAddress();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please select Home or Office", { autoClose: 3000 });
    }
  };
  const deleteAddress = (id) => {
    var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
      ? JSON.parse(localStorage.getItem("user"))?.user_id
      : "";
    deleteData(`/user/userAddress?address_id=${id}&user_id=${user_id}`)
      .then((response) => {
        if (response.status == 1) {
          userAddress();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changePass = () => {
    if (newpassword == confirmpassword) {
      let data = {
        username: acc_email,
        password: currpassword,
        new_password: newpassword,
      };
      postAuthData(`/user/changePassword`, data)
        .then((res) => {
          if (res.status == 1) {
            setAccountDetails(res.data[0]);
            setCurrpassword("");
            setConfirmpassword("");
            setNewpassword("");
            toast.success(res.message, { autoClose: 3000 });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("New password & confirm password do not match!", {
        autoClose: 3000,
      });
    }
  };
  const userAddress = () => {
    let id = JSON.parse(localStorage.getItem("user"))?.user_id;
    getData(`/user/userAddress?user_id=${id}`)
      .then((res) => {
        if (res.status == 1) {
          setAddressdetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const userinfo = () => {
    let id = JSON.parse(localStorage.getItem("user"))?.user_id;
    getData(`/user/userInfo?user_id=${id}`)
      .then((res) => {
        if (res.status == 1) {
          setAccountDetails(res.data);
          setAcc_gender(res.data?.gender);
          setAcc_firstname(res.data?.first_name);
          setAcc_lastname(res.data?.last_name);
          setAcc_email(res.data?.email);
          setAcc_mobile(res.data?.mobile_number);
          setAcc_ringsize(res.data?.my_ring_size);
          setAcc_DOB(res.data?.date_of_birth);
          setAcc_isSubscribe(res.data?.is_subscribe);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    userinfo();
    userAddress();
    getorder();
  }, []);
  const handleHiddenChange = (event) => {
    setAcc_isSubscribe(event.target.checked);
  };
  const editAddress = (val) => {
    setAddressUpdate(!addressUpdate);
    setEditValue(val);
    setstateAbbr(val.state)
    setAdd_shipping(val.shipping);
    setAdd_billing(val.billing);
    if (val?.address_for == "H") {
      setAdd_for("H");
      setAdd_forH(true);
      setAdd_forO(false);
    } else if (val?.address_for == "O") {
      setAdd_for("O");
      setAdd_forO(true);
      setAdd_forH(false);
    }
  };
  const updateEditAddress = (e) => {
    e.preventDefault();
    const data = {
      id: editValue.id,
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      first_name: add_fn?.length ? add_fn : editValue.first_name,
      last_name: add_ln?.length ? add_ln : editValue.last_name,
      address_for:
        add_for == editValue.address_for ? editValue.address_for : add_for,
      address_line_1: add_line1?.length ? add_line1 : editValue.address_line_1,
      address_line_2: add_line2?.length ? add_line2 : editValue.address_line_2,
      landmark: add_landmark?.length ? add_landmark : editValue.landmark,
      city: add_city?.length ? add_city : editValue.city,
      state: add_state?.length ? stateAbbr : editValue.state,
      contact_no: add_contact?.length ? add_contact : editValue.contact_no,
      // country: add_country?.length ? add_country : editValue.country,
      country: "US",
      zip_code: add_Zc?.length ? add_Zc : editValue.zip_code,
      shipping:
        add_shipping == editValue.shipping
          ? editValue.shipping
          : add_shipping
            ? 1
            : 0,
      billing:
        add_billing == editValue.billing
          ? editValue.billing
          : add_billing
            ? 1
            : 0,
    };
    putAuthData(`/user/userAddress`, data)
      .then((res) => {
        if (res.status == 1) {
          toast.success(res.message, { autoClose: 3000 });
          setAddressUpdate(!addressUpdate);
          resetadd();
          userAddress();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateAccount = (e) => {
    e.preventDefault();
    const data = {
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      first_name: acc_firstname,
      last_name: acc_lastname,
      email: acc_email,
      my_ring_size: acc_ringsize,
      mobile_number: acc_mobile,
      gender: acc_gender,
      date_of_birth: acc_DOB,
      is_subscribe: acc_isSubscribe,
    };
    postData(`/user/userInfo`, data)
      .then((res) => {
        if (res.status == 1) {
          var existing = JSON.parse(localStorage.getItem("user"));
          existing.first_name = res.data.first_name;
          existing.last_name = res.data.last_name;
          localStorage.setItem("user", JSON.stringify(existing));
          toast.success(res.message, { autoClose: 3000 });
          userinfo();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getorder = () => {
    var user_id = JSON.parse(localStorage.getItem("user"))?.user_id;
    getAuthData(`/order/orderlist?user_id=${user_id}`).then((res) => {
      if (res.status == 1) {
        setOrderlist(res.data);
      }
    });
  };

  const resetData = () => {
    setConfirmpassword('');
    setNewpassword('');
    setCurrpassword('');
  }
  const [buttonLoading, setButtonLoading] = useState(false);

  const stateHandlingForAbbr=(stateAddr,state)=>{
    setAdd_state(state)
    setstateAbbr(stateAddr)
  }
  return (
    <>
      <Helmet>
        <title>My Account | Gem Stone King ™</title>
        <meta name="description" content="My Account" />
        <meta property="og:site_name" content="Gem Stone King ™" />
        <meta
          property="og:url"
          content="http://gsk.tasksource.in:8080/myaccount"
        />
        <meta property="og:title" content="My Account" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="My Account" />
      </Helmet>
    
      <ToastContainer limit={1} />
      <div className="gs_myaccount">
        <Container className="gs_customer_container">
          <Row className="m-0 w-100 d-block d-lg-none">
            <div className="gs_wish_heading">
              <h1>My Account</h1>
            </div>
          </Row>
          <div className="gs_myaccount_mobile d-block d-lg-none">
            <div className={classes.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    <DashboardIcon />
                    Dashboard
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="gs_myaccount-content">
                      <h5>Dashboard</h5>
                      <p>
                        Hello 
                        <strong className="ml-1">
                        {accountdetails?.first_name
                          +" "+accountdetails?.last_name}
                        </strong>
                        , welcome to your account
                      </p>
                      <p className="mb-0">
                        From your account dashboard.you can easily check &amp;
                        view your recent orders, manage your shipping and
                        billing addresses and edit your password and account
                        details.
                      </p>
                    </div>
                    <div className="gs_myaccount_input">
                      <form>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              type="text"
                              value={acc_firstname}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_firstname(e.target.value)}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              type="text"
                              variant="outlined"
                              value={acc_lastname}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_lastname(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              type="Email"
                              variant="outlined"
                              value={acc_email}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_email(e.target.value)}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              variant="outlined"
                              value={acc_mobile}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_mobile(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Ring Size"
                              type="number"
                              InputProps={{ inputProps: { min: 3, max: 9 } }}
                              variant="outlined"
                              value={acc_ringsize}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_ringsize(e.target.value)}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="date"
                              label="Date of Birth"
                              type="date"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={acc_DOB}
                              onChange={(e) => setAcc_DOB(e.target.value)}
                              inputProps={{
                                max: date,
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <RadioGroup
                              onChange={(e) => setAcc_gender(e.target.value)}
                              row
                              aria-label="position"
                              name="position"
                              value={acc_gender}
                            >
                              <FormControlLabel
                                value="M"
                                control={<Radio color="primary" />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="F"
                                control={<Radio color="primary" />}
                                label="Female"
                              />
                            </RadioGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={acc_isSubscribe}
                                  onChange={handleHiddenChange}
                                  color="primary"
                                />
                              }
                              label="Subscribe Newsletter"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              onClick={updateAccount}
                              className="gs_fill_button m-auto d-block"
                              disabled={buttonLoading}
                            >
                              Update
                              {buttonLoading ? (
                                <CircularProgress className="gs_loader" />
                              ) : (
                                ""
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <AccountCircleIcon /> Personal Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="gs_myaccount-content d-none">
                      <h5 className="m-0">Account Details</h5>
                    </div>
                    <div className="gs_myaccount_input d-none">
                      <form>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              type="text"
                              variant="outlined"
                              value={acc_firstname}
                              disabled
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              type="text"
                              variant="outlined"
                              value={acc_lastname}
                              disabled
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              type="Email"
                              variant="outlined"
                              value={acc_email}
                              disabled
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                    <div className="gs_myaccount-content d-none">
                      <h5 className="m-0">Password Change</h5>
                    </div>
                    <div className="gs_myaccount_input d-none">
                      <form>
                        <Row>
                          <Col xs={12}>
                            <TextField
                              id="outlined-basic"
                              label="Current Password"
                              type="password"
                              variant="outlined"
                              value={currpassword}
                              onChange={(e) => setCurrpassword(e.target.value)}
                            />
                          </Col>
                          <Col xs={12}>
                            <TextField
                              id="outlined-basic"
                              label="New Password"
                              type="password"
                              variant="outlined"
                              value={newpassword}
                              onChange={(e) => setNewpassword(e.target.value)}
                            />
                          </Col>
                          <Col xs={12}>
                            <TextField
                              id="outlined-basic"
                              label="Confirm New Password"
                              type="Epassword"
                              variant="outlined"
                              value={confirmpassword}
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              onClick={changePass}
                              className="gs_fill_button m-auto d-block"
                              disabled={buttonLoading}
                            >
                              Save Changes
                              {buttonLoading ? (
                                <CircularProgress className="gs_loader" />
                              ) : (
                                ""
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>


                    <div className="gs_myaccount-content">
                      <h5 className="m-0">Personal Details</h5>
                    </div>

                    <Row>
                      <Col lg={6} md={5}>
                        <div className="gs_myaccount_input">
                          <form>
                            <Row>
                              <Col xs={12} sm={12}>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> First Name</p>
                                  <h6> {acc_firstname} </h6>
                                </div>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> Last Name</p>
                                  <h6> {acc_lastname} </h6>
                                </div>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> Email </p>
                                  <h6> {acc_email}</h6>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                      <Col lg={6} md={7}>
                        <div className="gs_myaccount-content mt-5">
                          <h5 className="m-0" style={{ border: "none" }}>
                            Password Change
                          </h5>
                        </div>
                        <div className="gs_myaccount_input pt-3">
                          <form>
                            <Row>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Current Password"
                                  type="password"
                                  variant="standard"
                                  value={currpassword}
                                  onChange={(e) =>
                                    setCurrpassword(e.target.value)
                                  }
                                />
                              </Col>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="New Password"
                                  type="password"
                                  variant="standard"
                                  value={newpassword}
                                  onChange={(e) =>
                                    setNewpassword(e.target.value)
                                  }
                                />
                              </Col>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Confirm New Password"
                                  type="password"
                                  variant="standard"
                                  value={confirmpassword}
                                  onChange={(e) =>
                                    setConfirmpassword(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="gs_myaccount_personal_details_btn">
                                  <Button
                                    onClick={changePass}
                                    className="gs_fill_button gs_fill_button_new m-auto"
                                    disabled={buttonLoading}
                                  >
                                    Save
                                    {buttonLoading ? (
                                      <CircularProgress className="gs_loader" />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                  <Button className="gs_fill_button gs_border_button_new m-auto " onClick={() => resetData()}>
                                    Cancel
                                    {buttonLoading ? (
                                      <CircularProgress className="gs_loader" />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <ShoppingCartIcon /> Orders
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>




                    <div className="gs_myaccount-content">
                      <h5>Orders</h5>
                    </div>
                    <div className="gs_order_item_table">
                      {/* <TableContainer component={Paper}>
                                                <Table className={classes.table} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>No.</StyledTableCell>
                                                            <StyledTableCell>Order No</StyledTableCell>
                                                            <StyledTableCell>Price</StyledTableCell>
                                                            <StyledTableCell>Date</StyledTableCell>
                                                            <StyledTableCell>Order Status</StyledTableCell>
                                                            <StyledTableCell>Shipping Status</StyledTableCell>
                                                            <StyledTableCell>Action</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {orderlist.map((row, index) => (
                                                            <StyledTableRow >
                                                                <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                                                                <StyledTableCell>{row.order_no}</StyledTableCell>
                                                                <StyledTableCell>${row.total_amount}</StyledTableCell>
                                                                <StyledTableCell>{moment(new Date(row.created_at)).format("YYYY-MM-DD")}</StyledTableCell>
                                                                <StyledTableCell>{row.order_status}</StyledTableCell>
                                                                <StyledTableCell>{row.shipping_status}</StyledTableCell>
                                                                <StyledTableCell><Button className="gs_fill_button" onClick={() => window.open(`/invoice/${JSON.parse(localStorage.getItem("user"))?.user_id}/${row.id}`, "_blank")}><VisibilityIcon /></Button> </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer> */}

                      <Accordion className="gs_order_accordion_main">
                        <AccordionSummary
                          className="gs_order_accordion_summry"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography >
                            <div className="gs_order_title_text">
                              <ul>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order:
                                  </span>
                                  <span> 1</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order date:
                                  </span>
                                  <span> 01/05/2023</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Total item(s):
                                  </span>
                                  <span> 2</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Sales tax:
                                  </span>
                                  <span> $15</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order total:
                                  </span>
                                  <span> $279.99</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Tracking:
                                  </span>
                                  <span> 120458754</span>
                                </li>
                              </ul>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row>
                              <Col>
                                <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box gs_orders">
                                  <Row>
                                    <Col sm={12} md={4} lg={6} xl={3}>
                                      <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                                    </Col>
                                    <Col sm={12} md={8} lg={6} xl={8}>
                                      <h2 className="gs_wish_title gs_shooping_bag gs_roboto">
                                        Sterling Silver Women Solitaire Ring
                                      </h2>

                                      <h2 className="gs_wish_title gs_shooping_bag_price gs_roboto">
                                        $49.99
                                      </h2>
                                      <div className="gs_cart_quantitys gs_shoping_bag">
                                        <span className="mr-3 gs_q_size gs_roboto">
                                          Quantity{" "}
                                        </span>
                                        <p className="m-0 gs_roboto"> (1) </p>
                                      </div>



                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                    </div>




                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <LocationOnIcon /> Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="gs_myaccount-content">
                      <h5>Billing Address</h5>
                      <Row>
                        {addressdetails?.map((val) => (
                          <Col sm={6}>
                            <div className="gs_add_box">
                              <address>
                                <p>
                                  <strong>Name: </strong>
                                </p>
                                <p>
                                  <span>
                                    {val.first_name} {val.last_name}
                                  </span>
                                </p>
                                <p>
                                  <strong>Address: </strong>
                                </p>
                                <p>
                                  <span>
                                    {val.address_line_1} {val.city}, {val.state}
                                    , {val.zip_code}
                                  </span>
                                </p>
                                <p>
                                  <strong>Mobile Number: </strong>
                                </p>
                                <p>
                                  <span>{val.contact_no}</span>
                                </p>
                                <Divider />
                                <Button className="gs_address_btn">
                                  {val.address_for == "H" ? "Home" : "Office"}
                                </Button>
                                {val.billing == 1 ? (
                                  <Button className="gs_address_btn">
                                    Billing
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {val.shipping == 1 ? (
                                  <Button className="gs_address_btn">
                                    Shipping
                                  </Button>
                                ) : (
                                  ""
                                )}
                                <IconButton
                                  className="myacc_edit"
                                  aria-label="edit"
                                  component="span"
                                >
                                  <EditIcon onClick={() => editAddress(val)} />
                                </IconButton>
                                <IconButton
                                  className="myacc_remove"
                                  aria-label="delete"
                                  component="span"
                                >
                                  <DeleteIcon
                                    onClick={() => deleteAddress(val.id)}
                                  />
                                </IconButton>
                              </address>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      {addressUpdate ? (
                        ""
                      ) : (
                        <Button
                          className="gs_fill_button gs_add_new"
                          onClick={() => setAddressShow(!addressshow)}
                        >
                          {addressshow ? (
                            <>
                              Add New Address <RemoveCircleIcon />
                            </>
                          ) : (
                            <>
                              Add New Address <AddCircleIcon />
                            </>
                          )}
                        </Button>
                      )}
                    </div>
                    {addressshow ? (
                      <div className="gs_myaccount_input">
                        <form>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="First Name"
                                type="text"
                                variant="outlined"
                                value={add_fn}
                                onChange={(e) => setAdd_fn(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                type="text"
                                variant="outlined"
                                value={add_ln}
                                onChange={(e) => setAdd_ln(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                type="number"
                                variant="outlined"
                                value={add_contact}
                                onChange={(e) => setAdd_contact(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Landmark"
                                type="text"
                                variant="outlined"
                                value={add_landmark}
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 1"
                                type="text"
                                variant="outlined"
                                value={add_line1}
                                onChange={(e) => setAdd_line1(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 2"
                                type="text"
                                variant="outlined"
                                value={add_line2}
                                onChange={(e) => setAdd_line2(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>


                              <FormControl variant="outlined" >
                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                <Select
                                  labelId="country-select-outlined-label"
                                  id="country-select-outlined"
                                  label="Country"
                                  value={add_country}
                                  onChange={(e) => setAdd_country(e.target.value)}
                                  size="small"
                                >
                                  <MenuItem value="United States" >United States</MenuItem>
                                </Select>
                              </FormControl>
                            </Col>
                            <Col xs={12} sm={6}>
                              {/* <TextField
                                id="outlined-basic"
                                label="State"
                                type="text"
                                variant="outlined"
                                value={add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}

                              <select style={{ height: "54px" }} className='form-control' id="" value={stateAbbr}
                               onChange={(e) => stateHandlingForAbbr(e.target.value,e.target[e.target.selectedIndex].title)}
                                required>
                                <option value="">Choose State </option>  
                                {
                                  usStates.length > 0 && usStates?.map((e, i) => (
                                    <option title={e?.name} value={e?.abbreviation} key={i}>{e?.name}</option>
                                  ))
                                }
                              </select>
                              {/* <FormControl variant="outlined" >
                                                            <InputLabel id="state-select-outlined-label">State</InputLabel>
                                                            <Select
                                                                labelId="state-select-outlined-label"
                                                                id="state-select-outlined"
                                                                label="State"
                                                                value={add_state}
                                                                onChange={(e) => setAdd_state(e.target.value)}
                                                                size="small"
                                                                required
                                                            >
                                                                {
                                                                    usStates.length > 0 && usStates?.map((e, i) => (
                                                                        <MenuItem value={e?.name} key={i}>{e?.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                type="text"
                                variant="outlined"
                                value={add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Zip"
                                type="number"
                                variant="outlined"
                                value={add_Zc}
                                onChange={(e) => setAdd_Zc(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_shipping}
                                    onChange={handleChange3}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Shipping Address"
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forH}
                                    onChange={handleChange5}
                                    name="home"
                                    color="primary"
                                  />
                                }
                                label="Home"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_billing}
                                    onChange={handleChange4}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Billing Address"
                              />
                            </Col>

                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forO}
                                    onChange={handleChange5}
                                    name="office"
                                    color="primary"
                                  />
                                }
                                label="Office"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                onClick={addAddress}
                                className="gs_fill_button m-auto d-block"
                                disabled={buttonLoading}
                              >
                                Save
                                {buttonLoading ? (
                                  <CircularProgress className="gs_loader" />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                    {addressUpdate ? (
                      <div className="gs_myaccount_input">
                        <form>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="First Name"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.first_name || add_fn}
                                onChange={(e) => setAdd_fn(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.last_name || add_ln}
                                onChange={(e) => setAdd_ln(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                type="number"
                                variant="outlined"
                                defaultValue={
                                  editValue?.contact_no || add_contact
                                }
                                onChange={(e) => setAdd_contact(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Landmark"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.landmark || add_landmark
                                }
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 1"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.address_line_1 || add_line1
                                }
                                onChange={(e) => setAdd_line1(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 2"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.address_line_2 || add_line2
                                }
                                onChange={(e) => setAdd_line2(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControl variant="outlined" >
                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                <Select
                                  labelId="country-select-outlined-label"
                                  id="country-select-outlined"
                                  label="Country"
                                  value={add_country}
                                  onChange={(e) => setAdd_country(e.target.value)}
                                  size="small"
                                >
                                  <MenuItem value="US" >United States</MenuItem>
                                </Select>
                              </FormControl>
                            </Col>
                            <Col xs={12} sm={6}>
                              {/* <TextField
                                id="outlined-basic"
                                label="State"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.state || add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              /> */}

                              <select style={{ height: "54px" }} className='form-control' id="" value={stateAbbr}
                                onChange={(e) => stateHandlingForAbbr(e.target.value,e.target[e.target.selectedIndex].title)}
                                required>
                                <option value="">Choose State</option>
                                {
                                  usStates.length > 0 && usStates?.map((e, i) => (
                                    <option title={e?.name} value={e?.abbreviation} key={i}>{e?.name}</option>
                                  ))
                                }
                              </select>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.city || add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Zip"
                                type="number"
                                variant="outlined"
                                defaultValue={editValue?.zip_code || add_Zc}
                                onChange={(e) => setAdd_Zc(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_shipping}
                                    onChange={handleChange3}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Shipping Address"
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forH}
                                    onChange={handleChange5}
                                    name="home"
                                    color="primary"
                                  />
                                }
                                label="Home"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_billing}
                                    onChange={handleChange4}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Billing Address"
                              />
                            </Col>

                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forO}
                                    onChange={handleChange5}
                                    name="office"
                                    color="primary"
                                  />
                                }
                                label="Office"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                onClick={updateEditAddress}
                                className="gs_fill_button m-auto d-block"
                                disabled={buttonLoading}
                              >
                                Update
                                {buttonLoading ? (
                                  <CircularProgress className="gs_loader" />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <NavLink
                to="/wishlist"
                onClick={() => window.scrollTo(0, 0)}
                className="gs_myacc_wish"
              >
                <Tab label="Wishlist" icon={<FavoriteIcon />} />
              </NavLink>
              <Tab
                onClick={handlelogout}
                className="gs_myacc_logout"
                label="LogOut"
                icon={<ExitToAppIcon />}
              />
            </div>
          </div>
          <div className="gs_myaccount_desktop d-none d-lg-block">
            <div className={classes.root}>
              <Row>
                <Col>
                  <div className="gs_welcome_user">
                    <p className="d_font">
                      Welcome, <span> {user.first_name} </span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4} md={2} lg={2} xl={2} className="gs_acount_mg">
                  <div className="gs_acount_signOut">
                    <span
                      className="gs_roboto"
                      onClick={handlelogout}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={Logout} /> SIGN OUT
                    </span>
                  </div>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                  >
                    <Tab
                      className="gs_side_tabs gs_roboto"
                      label="Dashboard"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="gs_side_tabs gs_roboto"
                      label="Personal Details"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="gs_side_tabs gs_roboto"
                      label="Orders"
                      {...a11yProps(2)}
                    />
                    <Tab
                      className="gs_side_tabs gs_roboto"
                      label="Address"
                      {...a11yProps(3)}
                    />
                    <NavLink
                      to="/wishlist"
                      onClick={() => window.scrollTo(0, 0)}
                      className="gs_myacc_wish gs_roboto"
                    >
                      <Tab
                        className="gs_side_tabs gs_roboto"
                        label="Wishlist"
                        {...a11yProps(4)}
                      />
                    </NavLink>

                    {/* <Tab className="gs_side_tabs" label="Dashboard" icon={<DashboardIcon />} {...a11yProps(0)} />
                                        <Tab className="gs_side_tabs" label="Account Details" icon={<AccountCircleIcon />} {...a11yProps(1)} />
                                        <Tab className="gs_side_tabs" label="Order" icon={<ShoppingCartIcon />} {...a11yProps(2)} />
                                        <Tab className="gs_side_tabs" label="Address" icon={<LocationOnIcon />} {...a11yProps(3)} />
                                        <NavLink to="/wishlist" onClick={() => window.scrollTo(0, 0)} className="gs_myacc_wish">
                                            <Tab className="gs_side_tabs" label="Wishlist" icon={<FavoriteIcon />} {...a11yProps(4)} />
                                        </NavLink>
                                        <Tab onClick={handlelogout} className="gs_side_tabs" label="LogOut" icon={<ExitToAppIcon />} {...a11yProps(5)} />
                                         */}
                  </Tabs>
                </Col>
                <Col sm={10} md={10} lg={10} xl={10}>
                  <TabPanel
                    className="gs_side_tabpanel"
                    value={value}
                    index={0}
                  >
                    <div className="gs_myaccount-content">
                      <h5>Dashboard</h5>
                      <p>
                        Hello
                        <strong className="ml-1">
                          {accountdetails?.first_name
                          +" "+accountdetails?.last_name}
                        </strong>
                        , welcome to your account
                      </p>
                      <p className="mb-0">
                        From your account dashboard.you can easily check &amp;
                        view your recent orders, manage your shipping and
                        billing addresses and edit your password and account
                        details.
                      </p>
                    </div>
                    <div className="gs_myaccount_input">
                      <form onSubmit={updateAccount}>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              type="text"
                              value={acc_firstname}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_firstname(e.target.value)}
                              required
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              type="text"
                              variant="outlined"
                              value={acc_lastname}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_lastname(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              type="Email"
                              variant="outlined"
                              value={acc_email}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_email(e.target.value)}
                              required
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              type="number"
                              variant="outlined"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={acc_mobile}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_mobile(e.target.value)}
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="outlined-basic"
                              label="Ring Size"
                              type="number"
                              InputProps={{ inputProps: { min: 3, max: 9 } }}
                              variant="outlined"
                              value={acc_ringsize}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setAcc_ringsize(e.target.value)}
                              required
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <TextField
                              id="date"
                              label="Date of Birth"
                              type="date"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={acc_DOB}
                              onChange={(e) => setAcc_DOB(e.target.value)}
                              inputProps={{
                                max: date,
                              }}
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <RadioGroup
                              onChange={(e) => setAcc_gender(e.target.value)}
                              row
                              aria-label="position"
                              name="position"
                              value={acc_gender}
                            >
                              <FormControlLabel
                                value="M"
                                control={<Radio color="primary" />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="F"
                                control={<Radio color="primary" />}
                                label="Female"
                              />
                            </RadioGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={acc_isSubscribe}
                                  onChange={handleHiddenChange}
                                  color="primary"
                                />
                              }
                              label="Subscribe Newsletter"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              type="submit"
                              className="gs_fill_button m-auto d-block"
                              disabled={buttonLoading}
                            >
                              Update
                              {buttonLoading ? (
                                <CircularProgress className="gs_loader" />
                              ) : (
                                ""
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </TabPanel>

                  <TabPanel
                    className="gs_side_tabpanel"
                    value={value}
                    index={1}
                  >
                    <div className="gs_myaccount-content">
                      <h5 className="m-0">Personal Details</h5>
                    </div>

                    <Row>
                      <Col lg={6}>
                        <div className="gs_myaccount_input">
                          <form>
                            <Row>
                              <Col xs={12} sm={12}>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> First Name</p>
                                  <h6> {acc_firstname} </h6>
                                </div>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> Last Name</p>
                                  <h6> {acc_lastname} </h6>
                                </div>
                                <div className="gs_account_details_content">
                                  <p className="gs_roboto"> Email </p>
                                  <h6> {acc_email}</h6>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="gs_myaccount-content mt-5">
                          <h5 className="m-0" style={{ border: "none" }}>
                            Password Change
                          </h5>
                        </div>
                        <div className="gs_myaccount_input pt-3">
                          <form>
                            <Row>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Current Password"
                                  type="password"
                                  variant="standard"
                                  value={currpassword}
                                  onChange={(e) =>
                                    setCurrpassword(e.target.value)
                                  }
                                />
                              </Col>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="New Password"
                                  type="password"
                                  variant="standard"
                                  value={newpassword}
                                  onChange={(e) =>
                                    setNewpassword(e.target.value)
                                  }
                                />
                              </Col>
                              <Col xs={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Confirm New Password"
                                  type="password"
                                  variant="standard"
                                  value={confirmpassword}
                                  onChange={(e) =>
                                    setConfirmpassword(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="gs_myaccount_personal_details_btn">
                                  <Button
                                    onClick={changePass}
                                    className="gs_fill_button gs_fill_button_new m-auto"
                                    disabled={buttonLoading}
                                  >
                                    Save
                                    {buttonLoading ? (
                                      <CircularProgress className="gs_loader" />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                  <Button className="gs_fill_button gs_border_button_new m-auto " onClick={() => resetData()}>
                                    Cancel
                                    {buttonLoading ? (
                                      <CircularProgress className="gs_loader" />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel
                    className="gs_side_tabpanel"
                    value={value}
                    index={2}
                  >
                    <div className="gs_myaccount-content">
                      <h5>Orders</h5>
                    </div>
                    <div className="gs_order_item_table">

                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>No.</StyledTableCell>
                              <StyledTableCell>Order No</StyledTableCell>
                              <StyledTableCell>Price</StyledTableCell>
                              <StyledTableCell>Date</StyledTableCell>
                              <StyledTableCell>Order Status</StyledTableCell>
                              <StyledTableCell>Shipping Status</StyledTableCell>
                              <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderlist.map((row, index) => (
                              <StyledTableRow >
                                <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                                <StyledTableCell>{row.order_no}</StyledTableCell>
                                <StyledTableCell>${row.total_amount}</StyledTableCell>
                                <StyledTableCell>{moment(new Date(row.created_at)).format("YYYY-MM-DD")}</StyledTableCell>
                                <StyledTableCell>{row.order_status}</StyledTableCell>
                                <StyledTableCell>{row.shipping_status}</StyledTableCell>
                                <StyledTableCell><Button className="gs_fill_button" onClick={() => window.open(`/invoice/${JSON.parse(localStorage.getItem("user"))?.user_id}/${row.id}`, "_blank")}><VisibilityIcon /></Button> </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Accordion className="gs_order_accordion_main">
                        <AccordionSummary
                          className="gs_order_accordion_summry"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography >
                            <div className="gs_order_title_text">
                              <ul>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order:
                                  </span>
                                  <span> 1</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order date:
                                  </span>
                                  <span> 01/05/2023</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Total item(s):
                                  </span>
                                  <span> 2</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Sales tax:
                                  </span>
                                  <span> $15</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order total:
                                  </span>
                                  <span> $279.99</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Tracking:
                                  </span>
                                  <span> 120458754</span>
                                </li>
                              </ul>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row>
                              <Col>
                                <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box gs_orders">
                                  <Row>
                                    <Col sm={12} md={4} lg={6} xl={3}>
                                      <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                                    </Col>
                                    <Col sm={12} md={8} lg={6} xl={8}>
                                      <h2 className="gs_wish_title gs_shooping_bag gs_roboto">
                                        Sterling Silver Women Solitaire Ring
                                      </h2>

                                      <h2 className="gs_wish_title gs_shooping_bag_price gs_roboto">
                                        $49.99
                                      </h2>
                                      <div className="gs_cart_quantitys gs_shoping_bag">
                                        <span className="mr-3 gs_q_size gs_roboto">
                                          Quantity{" "}
                                        </span>
                                        <p className="m-0 gs_roboto"> (1) </p>
                                      </div>



                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>


                      <Accordion className="gs_order_accordion_main">
                        <AccordionSummary
                          className="gs_order_accordion_summry"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography >
                            <div className="gs_order_title_text">
                              <ul>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order:
                                  </span>
                                  <span> 1</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order date:
                                  </span>
                                  <span> 01/05/2023</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Total item(s):
                                  </span>
                                  <span> 2</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Sales tax:
                                  </span>
                                  <span> $15</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Order total:
                                  </span>
                                  <span> $279.99</span>
                                </li>
                                <li>
                                  <span className="gs_order_t2 gs_roboto">
                                    Tracking:
                                  </span>
                                  <span> 120458754</span>
                                </li>
                              </ul>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row>
                              <Col>
                                <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box gs_orders">
                                  <Row>
                                    <Col sm={12} md={4} lg={6} xl={3}>
                                      <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                                    </Col>
                                    <Col sm={12} md={8} lg={6} xl={8}>
                                      <h2 className="gs_wish_title gs_shooping_bag gs_roboto">
                                        Sterling Silver Women Solitaire Ring
                                      </h2>

                                      <h2 className="gs_wish_title gs_shooping_bag_price gs_roboto">
                                        $49.99
                                      </h2>
                                      <div className="gs_cart_quantitys gs_shoping_bag">
                                        <span className="mr-3 gs_q_size gs_roboto">
                                          Quantity{" "}
                                        </span>
                                        <p className="m-0 gs_roboto"> (1) </p>
                                      </div>



                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  </TabPanel>
                  <TabPanel
                    className="gs_side_tabpanel"
                    value={value}
                    index={3}
                  >
                    <div className="gs_myaccount-content">
                      <h5>Your Addresses</h5>
                      <Row>
                        {addressdetails?.map((val) => (
                          <Col sm={6}>
                            <div className="gs_add_box">
                              <address>
                                <p>
                                  <strong>Name: </strong>
                                </p>
                                <p>
                                  <span>
                                    {val.first_name} {val.last_name}
                                  </span>
                                </p>
                                <p>
                                  <strong>Address: </strong>
                                </p>
                                <p>
                                  <span>
                                    {val.address_line_1} {val.city}, {val.state}
                                    , {val.zip_code}
                                  </span>
                                </p>
                                <p>
                                  <strong>Mobile Number: </strong>
                                </p>
                                <p>
                                  <span>{val.contact_no}</span>
                                </p>
                                <Divider />
                                <Button className="gs_address_btn">
                                  {val.address_for == "H" ? "Home" : "Office"}
                                </Button>
                                {val.billing == 1 ? (
                                  <Button className="gs_address_btn">
                                    Default Billing
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {val.shipping == 1 ? (
                                  <Button className="gs_address_btn">
                                    Default Shipping
                                  </Button>
                                ) : (
                                  ""
                                )}
                                <IconButton
                                  className="myacc_edit"
                                  aria-label="edit"
                                  component="span"
                                >
                                  <EditIcon onClick={() => editAddress(val)} />
                                </IconButton>
                                <IconButton
                                  className="myacc_remove"
                                  aria-label="delete"
                                  component="span"
                                >
                                  <DeleteIcon
                                    onClick={() => deleteAddress(val.id)}
                                  />
                                </IconButton>
                              </address>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      {addressUpdate ? (
                        ""
                      ) : (
                        <Button
                          className="gs_fill_button gs_add_new"
                          onClick={() => setAddressShow(!addressshow)}
                        >
                          {addressshow ? (
                            <>
                              Add New Address <RemoveCircleIcon />
                            </>
                          ) : (
                            <>
                              Add New Address <AddCircleIcon />
                            </>
                          )}
                        </Button>
                      )}
                    </div>
                    {addressshow ? (
                      <div className="gs_myaccount_input">
                        <form onSubmit={addAddress}>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="First Name"
                                type="text"
                                variant="outlined"
                                value={add_fn}
                                onChange={(e) => setAdd_fn(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                type="text"
                                variant="outlined"
                                value={add_ln}
                                onChange={(e) => setAdd_ln(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                type="number"
                                variant="outlined"
                                value={add_contact}
                                onChange={(e) => setAdd_contact(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Landmark (Optional)"
                                type="text"
                                variant="outlined"
                                value={add_landmark}
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 1"
                                type="text"
                                variant="outlined"
                                value={add_line1}
                                onChange={(e) => setAdd_line1(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 2 (Optional)"
                                type="text"
                                variant="outlined"
                                value={add_line2}
                                onChange={(e) => setAdd_line2(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControl variant="outlined" >
                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                <Select
                                  labelId="country-select-outlined-label"
                                  id="country-select-outlined"
                                  label="Country"
                                  value={add_country}
                                  onChange={(e) => setAdd_country(e.target.value)}
                                  size="small"
                                >
                                  <MenuItem value="US" >United States</MenuItem>
                                </Select>
                              </FormControl>
                            </Col>
                            <Col xs={12} sm={6}>
                              {/* <TextField
                                id="outlined-basic"
                                label="State"
                                type="text"
                                variant="outlined"
                                value={add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              /> */}
                              <select style={{ height: "54px" }} className='form-control' id="" value={stateAbbr}
                               onChange={(e) => stateHandlingForAbbr(e.target.value,e.target[e.target.selectedIndex].title)}
                                required>
                                <option value="">Choose State</option>
                                {
                                  usStates.length > 0 && usStates?.map((e, i) => (
                                    <option title={e?.name} value={e?.abbreviation} key={i}>{e?.name}</option>
                                  ))
                                }
                              </select>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                type="text"
                                variant="outlined"
                                value={add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Zip"
                                type="number"
                                variant="outlined"
                                value={add_Zc}
                                onChange={(e) => setAdd_Zc(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_shipping}
                                    onChange={handleChange3}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Shipping Address"
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forH}
                                    onChange={handleChange5}
                                    name="home"
                                    color="primary"
                                  />
                                }
                                label="Home"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_billing}
                                    onChange={handleChange4}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Billing Address"
                              />
                            </Col>

                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forO}
                                    onChange={handleChange5}
                                    name="office"
                                    color="primary"
                                  />
                                }
                                label="Office"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                type="submit"
                                className="gs_fill_button m-auto d-block"
                                disabled={buttonLoading}
                              >
                                Save
                                {buttonLoading ? (
                                  <CircularProgress className="gs_loader" />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                    {addressUpdate ? (
                      <div className="gs_myaccount_input">
                        <form onSubmit={updateEditAddress}>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="First Name"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.first_name || add_fn}
                                onChange={(e) => setAdd_fn(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.last_name || add_ln}
                                onChange={(e) => setAdd_ln(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                type="number"
                                variant="outlined"
                                defaultValue={
                                  editValue?.contact_no || add_contact
                                }
                                onChange={(e) => setAdd_contact(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Landmark (Optional)"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.landmark || add_landmark
                                }
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 1"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.address_line_1 || add_line1
                                }
                                onChange={(e) => setAdd_line1(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Address Line 2 (Optional)"
                                type="text"
                                variant="outlined"
                                defaultValue={
                                  editValue?.address_line_2 || add_line2
                                }
                                onChange={(e) => setAdd_line2(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Country"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.country || add_country}
                                onChange={(e) => setAdd_country(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                disabled
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                            
                              <select style={{ height: "54px" }} className='form-control' id=""  value={stateAbbr}
                              onChange={(e) => stateHandlingForAbbr(e.target.value,e.target[e.target.selectedIndex].title)}
                                required>
                                <option value="">Choose State</option>
                                {
                                  usStates.length > 0 && usStates?.map((e, i) => (
                                    <option title={e?.name} value={e?.abbreviation} key={i} >{e?.name}</option>
                                  ))
                                }
                              </select>
                              {/* <TextField
                                id="outlined-basic"
                                label="State"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.state || add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              /> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                type="text"
                                variant="outlined"
                                defaultValue={editValue?.city || add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <TextField
                                id="outlined-basic"
                                label="Zip"
                                type="number"
                                variant="outlined"
                                defaultValue={editValue?.zip_code || add_Zc}
                                onChange={(e) => setAdd_Zc(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_shipping}
                                    onChange={handleChange3}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Shipping Address"
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forH}
                                    onChange={handleChange5}
                                    name="home"
                                    color="primary"
                                  />
                                }
                                label="Home"
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_billing}
                                    onChange={handleChange4}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label="Default Billing Address"
                              />
                            </Col>

                            <Col xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={add_forO}
                                    onChange={handleChange5}
                                    name="office"
                                    color="primary"
                                  />
                                }
                                label="Office"
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                type="submit"
                                className="gs_fill_button m-auto d-block"
                                disabled={buttonLoading}
                              >
                                Update
                                {buttonLoading ? (
                                  <CircularProgress className="gs_loader" />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MyAccount;
