import { createSlice } from "@reduxjs/toolkit";


const OnlyRedux= createSlice({
    name: 'OnlyRedux',
    initialState: {
        mystate: false,
    },
    reducers:{
        RemoveOnLoad(state,action){
            state.mystate = action.payload
        },
     
     
    }
})


export default OnlyRedux.reducer;
export const {RemoveOnLoad}=OnlyRedux.actions;