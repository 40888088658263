import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { postData } from '../Helpers/request';
import { Button, Modal } from 'react-bootstrap';
import { useScreenshot, createFileName } from "use-react-screenshot";
import { ConsoleView, isMobileOnly, isTablet } from 'react-device-detect';
import html2canvas from 'html2canvas';

var line = new window.fabric.Path('M 65 0 Q 50, 50, 100, 0', { fill: '', stroke: 'black', objectCaching: false }) ;  
line.path[0][1] =  50;
line.path[0][2] = 50;

line.path[1][1] = 100;
line.path[1][2] = 100;

line.path[1][3] = 150;
line.path[1][4] = 50;
line.selectable = false;
var text = null;
function Engraving({ image, handleClose, engtext, textstyle, handlesavebtn, eng_data }) {
  const [img64, setImg64] = useState("");
  useEffect(() => {
    if (image.includes("data:image")) {
      setImg64(image);
    } else {
      getbase64();
    }
  }, [img64])
  const getbase64 = () => {
    const data = {
      image: image
    }

    postData(`/api/imageconvert`, data)
      .then((response) => {
        if (response.status == 1) {
          setImg64(response.data?.image);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  //Engraving -------------------------------------------------------------------------------------------------------------------------------
  const ref = createRef(null);
  const [screenshot, setScreenshot] = useState(null);
  var canvas = null;
  useEffect(() => {

    // canvas = new window.fabric.Canvas('c', {
    //   backgroundImage: 'https://dl2vs6wk2ewna.cloudfront.net/scrap/overnight/50040-E/50040-E.jpg',
    // });
    canvas = new window.fabric.Canvas('c');
    window.fabric.Object.prototype.objectCaching = false;

    drawQuadratic();
    canvas.on({
      'object:selected': onObjectSelected,
      'object:moving': onObjectMoving,
      'selection:cleared': onSelectionCleared
    });
  }, [engtext, img64]);

  async function drawQuadratic() {

    var path = line;
    text = new window.fabric.Text(engtext, { fontSize: 20, path: path, top: path.top, left: path.left, textAlign: "center", fontFamily: textstyle == 'montserrat' ? 'Montserrat' : 'Kaushan Script' });
    canvas.add(text);


    var p1 = makeCurvePoint(line.path[1][1], line.path[1][2], null, line, null);
    p1.name = "p1";
    canvas.add(p1);

    var p0 = makeCurveCircle(line.path[0][1], line.path[0][2], line, p1, null);
    p0.name = "p0";
    canvas.add(p0);

    var p2 = makeCurveCircle(line.path[1][3], line.path[1][4], null, p1, line);
    p2.name = "p2";
    canvas.add(p2);

  }

  function makeCurveCircle(left, top, line1, line2, line3) {
    var c = new window.fabric.Circle({
      left: left,
      top: top,
      strokeWidth: 3,
      radius: 7,
      fill: '#fff',
      stroke: '#666',
      originX: 'center',
      originY: 'center',
    });

    c.hasBorders = c.hasControls = false;

    c.line1 = line1;
    c.line2 = line2;
    c.line3 = line3;

    return c;
  }

  function makeCurvePoint(left, top, line1, line2, line3) {
    var c = new window.fabric.Circle({
      left: left,
      top: top,
      strokeWidth: 4,
      radius: 7,
      fill: '#fff',
      stroke: '#666',
      originX: 'center',
      originY: 'center',
    });

    c.hasBorders = c.hasControls = false;

    c.line1 = line1;
    c.line2 = line2;
    c.line3 = line3;

    return c;
  }

  function onObjectSelected(e) {
    var activeObject = e.target;

    if (activeObject.name == "p0" || activeObject.name == "p2") {
      activeObject.line2.animate('opacity', '1', {
        duration: 200,
        onChange: canvas.renderAll.bind(canvas),
      });
      activeObject.line2.selectable = true;
    }
  }

  function onSelectionCleared(e) {
    var activeObject = e.target;
    if (activeObject?.name == "p0" || activeObject?.name == "p2") {
      activeObject.line2.animate('opacity', '0', {
        duration: 200,
        onChange: canvas.renderAll.bind(canvas),
      });
      activeObject.line2.selectable = false;
    }
    else if (activeObject?.name == "p1") {
      activeObject.animate('opacity', '0', {
        duration: 200,
        onChange: canvas.renderAll.bind(canvas),
      });
      activeObject.selectable = false;
    }
  }

  async function onObjectMoving(e) {

    if (e.target.name == "p0" || e.target.name == "p2") {
      var p = e.target;

      if (p.line1) {
        p.line1.path[0][1] = p.left;
        p.line1.path[0][2] = p.top;
      }
      else if (p.line3) {
        p.line3.path[1][3] = p.left;
        p.line3.path[1][4] = p.top;
      }
    }
    else if (e.target.name == "p1") {
      var p = e.target;

      if (p.line2) {
        p.line2.path[1][1] = p.left;
        p.line2.path[1][2] = p.top;
      }
    }
    else if (e.target.name == "p0" || e.target.name == "p2") {
      var p = e.target;

      p.line1 && p.line1.set({ 'x2': p.left, 'y2': p.top });
      p.line2 && p.line2.set({ 'x1': p.left, 'y1': p.top });
      p.line3 && p.line3.set({ 'x1': p.left, 'y1': p.top });
      p.line4 && p.line4.set({ 'x1': p.left, 'y1': p.top });
    }
    var path = line;
    var pathInfo = window.fabric.util.getPathSegmentsInfo(path.path);
    path.segmentsInfo = pathInfo;
  }
  const handlesave = async () => {
    line.stroke = "";
    await canvas.renderAll();
    const ss = await html2canvas(document.getElementById('eng_img_main'))
    setScreenshot(ss.toDataURL());
    line = new window.fabric.Path('M 65 0 Q 50, 50, 100, 0', { fill: '', stroke: 'black', objectCaching: false });  }
  useEffect(() => {

    line.path[0][1] =  50;
    line.path[0][2] = 50;
    
    line.path[1][1] = 100;
    line.path[1][2] = 100;
    
    line.path[1][3] = 150;
    line.path[1][4] = 50;
    line.selectable = false;

    if (screenshot != null) {
      const data = {
        id: eng_data.length + 1,
        img: screenshot,
        engraving: engtext,
        fontstyle: textstyle
      }
      handlesavebtn(data);
      handleClose();
    }
  }, [screenshot])
  //Engraving--------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <Modal show={true} size="lg" centered className='eng_modal_main'>
        <Modal.Header ><h3> Add Engraving</h3>
          <button type="button" onClick={() => handleClose()} className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body>
          <div className='eng_border_d'>
            <div id="eng_img_main" className='eng_img_main' ref={ref} style={{ backgroundImage: `url(${img64})` }}>


              {/* <canvas id="c" className='iphone350' width="310px" height="310px"></canvas> */}
              {window.innerWidth < 351 ?
                <canvas id="c" width="285px" height="285px"></canvas> :
                window.innerWidth < 361 ?
                  <canvas id="c" width="305px" height="305px"></canvas> :
                  window.innerWidth < 391 ?
                    <canvas id="c" width="360px" height="360px"></canvas> :
                    window.innerWidth < 451 ?
                      <canvas id="c" width="380px" height="380px"></canvas> :
                      isTablet ? <canvas id="c" width="400" height="400" ></canvas> :
                      window.innerWidth < 1441 && window.innerWidth > 1350 ?
                      <canvas id="c" width="500px" height="500px"></canvas> : <canvas id="c" width="600" height="600" ></canvas> 
              }

              {/* {isMobileOnly ? <canvas id="c" width="100%" height="100%" ></canvas> :
                isTablet ? <canvas id="c" width="400" height="400" ></canvas> :
                  <canvas id="c" width="600" height="600" ></canvas>} */}
            </div></div><br />
          <div className='eng_input_btn_main'>
            <Button className='ml-3' onClick={() => handlesave()}>Save Engraving</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default React.memo(Engraving);
