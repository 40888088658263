import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../../assets/css/invoice.css";
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';
import OrderImage from '../../assets/images/icon/sucess.png'

const OrderSuccess = (props) => {

    const [totalAmount,settotalAmount]=useState("");
    useEffect(()=>{
        getTotal();
    },[props.match.params.no])

    const getTotal=()=>{
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
          let value = params.total;
          settotalAmount(value);
    }




    return (
        <>
            <Helmet>
                <title>Order-Success | Gem Stone King ™</title>
                <meta name="description" content="Order Success" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/order-success" />
                <meta property="og:title" content="Order Success" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Order Success" />
            </Helmet>
            <div className="gs_myaccount gs_order_success">
                <Container className="gs_customer_container">
                    <Row className="w-100 m-auto">
                        <Col>
                            <Image src={OrderImage} alt="Order Success"></Image>
                            <h1>THANK YOU</h1>
                            <p className="mt-3 d-inline-block">Payment Is Successfully Processed and Your Order Is On The Way</p>
                            <p>Order No : {props.match.params.no}</p>
                            <p>Transaction Id : {props.match.params.tid}</p>
                             <Button className="gs_fill_button" onClick={() => window.open(`/invoice/${props.match.params.uid}/${props.match.params.id}`,"_blank")}>View Details</Button>
                             <div className='amountSep'>
                                    <p className='mb-2'>
                                    Total Amount:
                                    </p>
                                    <p>
                                    ${totalAmount}
                                    </p>
                             </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default OrderSuccess;
