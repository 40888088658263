import React, { useState } from "react";
import {
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  Col,
  Image,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Fb from "../../assets/images/Home/new-home/fb.png";
import Insta from "../../assets/images/Home/new-home/insta.png";
import Yt from "../../assets/images/Home/new-home/yt.png";
import Pin from "../../assets/images/Home/new-home/pin.png";
import { ToastContainer, toast } from "react-toastify";
import { postData } from "../../Helpers/request";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

const NewsLetter = () => {
  const [email, setEmail] = useState("");

  const handlesubscribe = (e) => {
    e.preventDefault();
    postData("/user/newsLetter", { email })
      .then((res) => {
        if (res.status == 1) {
          setEmail("");
          toast.success(res.message, { autoClose: 3000 });
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="gsk_news-letter_section">
        <Container className="gs_customer_container">
          <Row className="align-items-center">
          {
              !isMobile &&  
            <Col lg={3} className="mr-auto">
             <div className="gsk_news-letter_social_icn">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/gskjewelry/",
                          "_blank"
                        )
                      }
                    >
                      <img src={Fb} />
                    </Link>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/accounts/login/",
                          "_blank"
                        )
                      }
                    >
                      <img src={Insta} />
                    </Link>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          " https://www.youtube.com/@gemstoneking6395",
                          "_blank"
                        )
                      }
                    >
                      <img src={Yt} />
                    </Link>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://www.pinterest.com/gemstoneking/",
                          "_blank"
                        )
                      }
                    >
                      <img src={Pin} />
                    </Link>
                  </li>
                </ul>
              </div>
        
           
            </Col>
          }

            <Col lg={6} className="ml-auto pr-0">
              <div className="gs_newsletter_input_section">
                <p className="gs_newsletter_p">
                  {" "}
                  Get Exclusive Offers and News
                </p>
                <Form className="gs_news_form" onSubmit={handlesubscribe}>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Email Address..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      type="email"
                    />
                    <Button
                      type="submit"
                      disabled={!email.length}
                      id="button-addon2"
                    >
                      Subscribe
                    </Button>
                  </InputGroup>
                </Form>
              </div>
            </Col>
          </Row>
          
        </Container>
        <img src={require('../../assets/images/newsletter_img.jpg').default} alt="" className='w-100' />
      </section>
    </>
  );
};
export default NewsLetter;
