import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import "../../assets/css/blog.css";
import about_line from "../../assets/images/icon/line_black.png";
import { base_url, getData } from '../../Helpers/request';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import "../../assets/css/product_list.css";
import Pagination from "@material-ui/lab/Pagination";

class BlogPage extends Component {

    constructor(props) {
        super(props)
        this.state={
            page: 1,
            blogUrl: `/user/blog?currency=USD&language=EN&limit=16&offset=16}`,
            bloglist :[]
        }
    
    }

    getBlog=()=>{
        getData(this.state.blogUrl)
        .then((res)=>{
            if(res.status == 1){
                this.setState({bloglist: res && res.data});


            }
        })
    }
    componentDidMount(){
    this.getBlog()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.blogUrl !== prevState.blogUrl) {
           this.getBlog()
        }
      }
// prevFunc=()=>{
//     this.setState({ blogUrl: this.state.bloglist?.previous.replace("http://gskapi.demobw.com","")});
//     window.scrollTo(0,0);
// }
// nextFunc=()=>{
//     this.setState({blogUrl: this.state.bloglist?.next.replace("http://gskapi.demobw.com","")});
//     window.scrollTo(0,0);
// }

handlePageChange=(v)=>{
    this.setState({ page: Number(v) });
    var currentPage= `/user/blog?currency=USD&currency=USD&language=EN&language=EN&limit=16&offset=${(Number(v)-1)*16}`;
   this.setState({blogUrl: currentPage });
    window.scrollTo(0, 0);
}

      
    
    render() {

        return (
            <div>
                 <Helmet>
                <title>Blog | Gem Stone King ™</title>
                <meta name="description" content="Blog" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gemstoneking.com/blog" />
                <meta property="og:title" content="Blog" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Blog" />
                </Helmet>
                {/*Blog Details section*/}
                <Container>

                    <Row className="m-0 w-100">
                        <div className="gs_static_heading">
                            <h1>Blog</h1>
                            <Image src={about_line} alt="line"></Image>
                        </div>
                    </Row>
                </Container>
                <section className="section-b-space blog-page ratio2_3">
                    <Container>
                        <Row className="m-0 w-100">
                            <Col>
                            {this.state.bloglist.results?.map(val=>
                                <div className="blog-media row">
                                    <Col lg={6}>
                                        <div className="blog-left">
                                            <NavLink to={'/blogs/news/' + val.slugField}>
                                                <Image src={base_url + val.image} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="blog-right">
                                            <div>
                                                <h6>{moment(new Date(val.created_at)).format("YYYY-MM-DD")}</h6>
                                                    <h4><Link to={'/blogs/news/' + val.slugField}>{val.title}</Link></h4>
                                                {/* <p>{val.description}</p> */}
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            )}
                            
                        {/* <div className='d-flex justify-content-center mx-auto pagiDesign'>
                        <button className='mr-2' onClick={()=>this.prevFunc()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='mr-2' width="8" height="23.619" viewBox="0 0 13.503 23.619">
  <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z" transform="translate(-11.251 -6.194)"/>
</svg>  Prevoius 

                        </button>
                        <button onClick={()=>this.nextFunc()}>
                        Next
                         <svg xmlns="http://www.w3.org/2000/svg" className='ml-2' width="8" height="23.616" viewBox="0 0 13.503 23.616">
  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
</svg>

                        </button>
                        </div> */}
                            </Col>
                        </Row>

{
    this.state.bloglist.count > 16 &&  <Pagination
                          count={Math.ceil((this.state.bloglist.count/16))}
                          page={this.state.page}
                          onChange={(e)=>this.handlePageChange(e.target.outerText)}
                          className='d-flex justify-content-center mt-5 mb-4 blogPaginate'
                        />
}
                       
                    </Container>
                </section>


            </div>
        )
    }
}

export default BlogPage