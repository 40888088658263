import React, { useLayoutEffect, useState } from "react";
import {
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  Col,
  Image,
  Form,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/footer.css";
import logoImage from "../../assets/images/logo.svg";
import DraftsIcon from "@material-ui/icons/Drafts";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import about_line from "../../assets/images/footer/about_line.png";
import verified_logo from "../../assets/images/footer/verified_logo.png";
import bracelet from "../../assets/images/footer/bracelet.png";
import earrings from "../../assets/images/footer/earrings.png";
import necklace from "../../assets/images/footer/necklace.png";
import rings from "../../assets/images/footer/rings.png";
import payment_icon from "../../assets/images/footer/payment_icon.svg";
import { postData } from "../../Helpers/request";
import fbIcon from "../../assets/images/icon/fb_icon.png";
import instaIcon from "../../assets/images/icon/insta_icon.png";
import twitterIcon from "../../assets/images/icon/twiter_icon.png";
import pinterestIcon from "../../assets/images/icon/pinterest_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewsLetter from "../Landing/NewsLetter";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import Fb from "../../assets/images/Home/new-home/fb.png";
import Insta from "../../assets/images/Home/new-home/insta.png";
import Yt from "../../assets/images/Home/new-home/yt.png";
import Pin from "../../assets/images/Home/new-home/pin.png";
import SignupTo from "../Landing/SignupTo";

const Footer = () => {
  const [email, setEmail] = useState("");
  const footerdata = JSON.parse(localStorage.getItem("footerdetails"));
  const handlesubscribe = (e) => {
    e.preventDefault();
    postData("/user/newsLetter", { email })
      .then((res) => {
        if (res.status == 1) {
          setEmail("");
          toast.success(res.message, { autoClose: 3000 });
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="footer">
      <ToastContainer limit={1} />

      {
        !isMobile && <SignupTo />
      }
      {
        isMobile && 
        <NewsLetter/>
      }
      
     

      {/* Newsletter */}
      {/* <div className="gs_newsletter_section">
                <Container>
                    <h2>sign up for our email list</h2>
                    <div className="gs_newsletter_input_section">
                        <Form className="gs_news_form w-100" onSubmit={handlesubscribe}>
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Email Address..."
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    type="email"
                                />
                                <Button variant="outline-secondary" type="submit" disabled={!email.length} id="button-addon2">
                                    Subscribe
                                </Button>
                            </InputGroup>
                        </Form>
                    </div>
                    <div className="gs_newsletter_content">
                        <p>By signing up you confirm that you have read the Privacy Policy and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p>
                    </div>
                </Container>
            </div> */}
      {/* Newsletter */}
      {/*Ffooter */}
      <div className="gs_footer_section">
       
        {
          !isMobile &&   <Container className="gs_customer_container"> <div className="gs_footer_content">
            <Row className="m-0 w-100">
              <Col sm={6} md={6} lg={3}>
                <div className="gs_col_footer_top">
                  <h2>About Us</h2>
                </div>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/privacy-policy" className="nav-link">
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/terms-of-service" className="nav-link">
                        Terms of Service
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink
                        to="/accessibility-statement"
                        className="nav-link"
                      >
                        Accessibility Statement
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink
                        to="/pages/contact-us"
                        className="nav-link"
                      >
                       Contact us
                      </NavLink>
                    </li>
                  </ul>
                </div>

             <div className="gsk_news-letter_social_icn">
                <ul className="d-flex">
                  <li>
                  <a href="https://www.facebook.com/gskjewelry/" target="_blank" rel="noopener noreferrer">
                    <img src={Fb} />
                    </a>

                    </li>

                    <li>
                    <a href="https://www.instagram.com/gemstonekingjewelry/" target="_blank" rel="noopener noreferrer">
                    <img src={Insta} alt="" />
                    </a>
                    </li>
                
                <li>
                <a href="https://www.youtube.com/@gemstoneking6395/videos" target="_blank" rel="noopener noreferrer">
                <img src={Yt} alt="" />
                    </a>
         
                </li>
                 
                 <li>
                 <a href="https://www.pinterest.com/gemstoneking_nyc/" target="_blank" rel="noopener noreferrer">
                 <img src={Pin} alt="" />
                    </a>
         
                 </li>
                </ul>
              </div>
        
          
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="gs_col_footer_top">
                  <h2 className="gs_here_title">News</h2>
                </div>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/our-story" className="nav-link">
                        Our Story
                      </NavLink>
                    </li>

                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="#" className="nav-link">
                        Giving Back To The Community
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="#" className="nav-link">
                        Gem Stone King Club Newspaper
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/blog" className="nav-link">
                        Blog : A Phenomenal Pianist
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="gs_col_footer_top">
                  <h2 className="gs_here_title"> Customer Service</h2>
                </div>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    {/* <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/contact-us" className="nav-link">
                        Contact US
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/faqs" className="nav-link">
                        FAQ's
                      </NavLink>
                    </li> */}
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/faqs" className="nav-link">
                        FAQ's
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/shipping-returns" className="nav-link">
                        Shipping & Returns
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/refund-policy" className="nav-link">
                        Refund Policy
                      </NavLink>
                    </li>

                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/refund-policy" className="nav-link">
                        Create an Account
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col sm={6} md={6} lg={3}>
                <div className="gs_col_footer_top m-0 text-center">
                  <NavLink
                    to="/"
                    className="nav-link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <Image src={logoImage} alt="Gem Stone King" />
                  </NavLink>
                </div>
                <div className="gs_col_footer">
                  <ul className="gs_footer_contact_details">
                    <li>
                      <NavLink
                        to="#"
                        onClick={(e) => {
                          window.location.href = `tel:${footerdata?.footerinfo[0].mobile_no}`;
                        }}
                        className="nav-link"
                      >
                        {footerdata?.footerinfo[0].mobile_no}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        onClick={(e) => {
                          window.location.href = `mailto:${footerdata?.footerinfo[0].email}`;
                        }}
                        className="nav-link"
                      >
                          
                        {footerdata?.footerinfo[0].email}  
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#" className="nav-link">
                          
                        {footerdata?.footerinfo[0].address1} <br />  
                        {footerdata?.footerinfo[0].address2}  
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          </Container>
        }
        
{
  isMobile &&   <div className="footerAccordion mt-3">
          <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    About 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/privacy-policy" className="nav-link">
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/terms-of-service" className="nav-link">
                        Terms of Service
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink
                        to="/accessibility-statement"
                        className="nav-link"
                      >
                        Accessibility Statement
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink
                        to="/pages/contact-us"
                        className="nav-link"
                      >
                       Contact us
                      </NavLink>
                    </li>
                  </ul>
                </div>
                
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    News
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/our-story" className="nav-link">
                        Our Story
                      </NavLink>
                    </li>

                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="#" className="nav-link">
                        Giving Back To The Community
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="#" className="nav-link">
                        Gem Stone King Club Newspaper
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/blog" className="nav-link">
                        Blog : A Phenomenal Pianist
                      </NavLink>
                    </li>
                  </ul>
                </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Customer Service
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="gs_col_footer">
                  <ul className="gs_footer_about_detail">
                    {/* <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/contact-us" className="nav-link">
                        Contact US
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/faqs" className="nav-link">
                        FAQ's
                      </NavLink>
                    </li> */}
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/faqs" className="nav-link">
                        FAQ's
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/shipping-returns" className="nav-link">
                        Shipping & Returns
                      </NavLink>
                    </li>
                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/refund-policy" className="nav-link">
                        Refund Policy
                      </NavLink>
                    </li>

                    <li onClick={() => window.scrollTo(0, 0)}>
                      <NavLink to="/pages/refund-policy" className="nav-link">
                        Create an Account
                      </NavLink>
                    </li>
                  </ul>
                </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
          </div>
}
        {
          isMobile && 
          <div className="wrapperBottomMob pb-3">

          <div className="gs_col_footer mb-3"><ul className="gs_footer_contact_details"><li><a aria-current="page" className="nav-link active" href="tel:+1 (877) 706-5333">+1 (877) 706-5333</a></li><li><a aria-current="page" className="nav-link active" href="mailto:support@gemstoneking.com">support@gemstoneking.com</a></li><li><a aria-current="page" className="nav-link active" href="/">555 Eighth Avenue <br/>New York, NY 10018</a></li></ul></div>
          <div className="gsk_news-letter_social_icn">
                <ul>
                  <li className="d-flex justify-content-center">
                    <a href="https://www.facebook.com/gskjewelry/" target="_blank" rel="noopener noreferrer">
                    <img src={Fb} />
                    </a>
                    <a href="https://www.instagram.com/gemstonekingjewelry/" target="_blank" rel="noopener noreferrer">
                    <img src={Insta} />
                    </a>
                    <a href="https://www.youtube.com/@gemstoneking6395" target="_blank" rel="noopener noreferrer">
                    <img src={Yt} />
                    </a>
                    <a href="https://www.pinterest.com/gemstoneking/" target="_blank" rel="noopener noreferrer">
                    <img src={Pin} />
                    </a>
                  </li>
                </ul>
              </div>

           
              </div>
      
        }

      
       
      </div>
      {/* footer */}
      {/* footer bottom */}
      <div className="gs_bottom_footer_section">
        <Container className="gs_customer_container">
          <div className="gs_bottom_footer_content">
            <Row className="m-0 w-100">
              <Col sm={12} className="p-0 text-center">
                <p className="f_roboto">
                  Copyright © {new Date().getFullYear()} Gem Stone King <sup>TM</sup>. All Right
                  Reserved. Designed and Developed by: <a target="_blank" href="https://belgiumwebnet.com/">Belgium WebNet</a>
                </p>
              </Col>
              {/* <Col sm={6} className="p-0 pr-5">
                <Image src={payment_icon} alt="Payment"></Image>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>

      {/* footer bottom */}
    </div>
  );
};

export default Footer;
