import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Image, Button, Modal, Form } from 'react-bootstrap';
import '../assets/css/header.css';
import { useHistory, NavLink, Link } from 'react-router-dom';
import { TextField, Menu, MenuItem, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import userImages from "../assets/images/icon/user.png";
import { postData } from '../Helpers/request';
import 'react-toastify/dist/ReactToastify.css';
import { isMobileOnly } from "react-device-detect";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { GoogleLogin } from "react-google-login";
import ReactFacebookLogin from "react-facebook-login";
import FacebookIcon from '@material-ui/icons/Facebook';
import { Facebook } from '@material-ui/icons';
import { gapi } from 'gapi-script';
import { AiOutlineUser } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../store/slices/Global';

const Login = (props) => {
    var selector = useSelector(state => state?.user?.user);
    const dispatch = useDispatch();
    const [reload, setreload] = useState(false);
    useEffect(() => {
    }, [reload])
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("user"));
    const [login, setLogin] = useState(false);
    const [logAction, setlogAction] = useState(false);

    const [afterLogin, setAfterLogin] = useState(sessionStorage.getItem('afterLogin'));
    const [buttonLoading, setButtonLoading] = useState(false);

    /*search code*/
    const [signup, setSignup] = useState(false);
    const [forgotpassword, setForgotpassword] = useState(false);

    //login
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    //forgotpass
    const [f_email, setF_email] = useState('');
    //signup
    const [s_email, setS_email] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [s_password, setS_password] = useState('');
    const [s_confirmpassword, setS_confirmpassword] = useState('');

    const [alert, setAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [terms, setTerms] = useState(false);
    const [news, setNews] = useState(false);
    const handleChange3 = (event) => {
        setTerms(event.target.checked);
    };
    const handleChange4 = (event) => {
        setNews(event.target.checked);
    };
    // useMemo(() => {
    //     if (!logAction) {
    //         setlogAction(true);
    //     }
    // }, []);
    useEffect(() => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                clientId: "283858210868-7ermnoi4h5qnhf387mlis643ag5vo3mn.apps.googleusercontent.com",
                plugin_name: "chat"
            })
        })
        setAfterLogin(sessionStorage.getItem('afterLogin') == 'true' && true);
       
        //setLogin(sessionStorage.getItem('afterLogin')=='true' && true);
     
        if (localStorage.getItem("user") === null) {
            sessionStorage.setItem('afterLogin', false);
        }
        if (selector === null) {
            sessionStorage.setItem('afterLogin', false);

        }
    }, [selector])


    useEffect(()=>{
       
        // if (localStorage.getItem("user") !== null) {
        //   setlogAction(sessionStorage.getItem('afterLogin') == 'true' && true);
        // }
        // console.log("pr12")
    
    },[])
  
    const onResponse = (resp, type) => {
        setButtonLoading(true);
        const session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        const data = {
            first_name: type == 'google' ? resp.profileObj?.givenName : resp?.name?.split(" ")[0],
            last_name: type == 'google' ? resp.profileObj?.familyName : resp?.name?.split(" ")[1],
            social_id: type == 'google' ? resp.profileObj?.googleId : resp?.id,
            login_method: type == 'google' ? 2 : 3,
            email: type == 'google' ? resp.profileObj?.email : resp?.email,
            session_id
        }
        postData(`/user/socialLogin`, data)
            .then(response => {
                if (response.status === 1) {
                    var user = {
                        token: response.data.token,
                        user_id: response.data.user_id,
                        email: response.data.email,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        isLogin: true
                    }
                    localStorage.setItem("cartlength", response.data.cart_item_count);
                    localStorage.setItem("wishlistlength", response.data.wishlist_item_count);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.removeItem("session_id");
                    setUsername("");
                    setPassword('');
                    setButtonLoading(false);
                    setLogin(false);
                    setlogAction(false)
                    sessionStorage.setItem('afterLogin', true);
                    window.location.reload();
                } else {
                    toast.error(response.message, { autoClose: 3000 });
                    setButtonLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            })
    };
    const signin = () => {
        setLogin(true);
        setSignup(false);
        setForgotpassword(false);
        setreload(true);
    }
    const handleClicktop = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosetop = () => {
        setAnchorEl(null);
    };
    const handlelogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('wishlistlength');
        localStorage.removeItem('cartlength');
        localStorage.removeItem("session_id")
        setAnchorEl(null)
        history.push("/")
        window.location.reload(true);
        sessionStorage.setItem('afterLogin', false);
        dispatch(UserData(null));
    }
    const handlesubmitlogin = (e) => {
        setButtonLoading(true);
        e.preventDefault();
        const session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        postData(`/user/login`, { username, password, session_id })
            .then(response => {
                if (response.status === 1) {
                    sessionStorage.setItem('afterLogin', true);
                  
                    var user = {
                        token: response.data.token,
                        user_id: response.data.user_id,
                        email: response.data.email,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        isLogin: true
                    }
                    localStorage.setItem("cartlength", response.data.cart_item_count);
                    localStorage.setItem("wishlistlength", response.data.wishlist_item_count);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.removeItem("session_id")
                    setUsername("");
                    setPassword('');
                    setButtonLoading(false);
                    setLogin(false);
                    dispatch(UserData(user));
                    
                    // window.location.reload(true);



                } else {
                    toast.error(response.message, { autoClose: 3000 });
                    setButtonLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    const handlesignup = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (s_confirmpassword == s_password) {
            var data = {
                first_name: firstname,
                last_name: lastname,
                email: s_email,
                password: s_password
            }
            postData(`/user/register`, data)
                .then(function (response) {
                    if (response.status == 1) {
                        setSignup(false);
                        setButtonLoading(false);
                        toast.success(response.message, { autoClose: 3000 });
                    } else {
                        toast.error(response.message, { autoClose: 3000 });
                        setButtonLoading(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            toast.error("Password & Confirm password do not match!", { autoClose: 3000 });
            setS_password("");
            setS_confirmpassword("");
        }
    }
    const onCloseModal = () => {
        alert("aa");
    }
    const handleforgotpassword = (e) => {
        setButtonLoading(true);
        e.preventDefault();
        var data = {
            email: f_email
        }
        postData(`/user/forgetPassword`, data)
            .then(function (response) {
                if (response.status == 1) {
                    setButtonLoading(false);
                    toast.success(response.message, { autoClose: 3000 });
                    // history.push("/home");
                } else {
                    setButtonLoading(false);
                    toast.error(response.message, { autoClose: 3000 });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        setF_email("");
    }
    return (<>
        {user ?
            <li>
                <Button className="gs_blank_button gs_after_login_button" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClicktop}>{isMobileOnly ? <span className="gs_mobile">{(user.first_name)[0]}</span> : <span className='pl-2'>{user.first_name} <KeyboardArrowDownIcon /></span>}</Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClick={handleClosetop}
                >
                    <MenuItem onClick={() => history.push("/myaccount")}>My Account</MenuItem>
                    <MenuItem onClick={handlelogout}>Logout</MenuItem>
                </Menu>
            </li> :
            isMobileOnly ? <li><Button variant="link" className="gs_before_login sign_in" id="loginbutton" onClick={signin}><AiOutlineUser className='mob_sigin_icon' /> <span className='mob_sign_in_text'> Sign In</span></Button></li> :
                <li><Button variant="link" className="gs_before_login" id="loginbutton" onClick={signin}><span> <BiUser /> Sign In</span></Button></li>


        }
     
        {
        <Modal className="gs_login_modal rightSideModal"
            aria-labelledby="contained-modal-title-vcenter"
            show={logAction} onHide={() => { setlogAction(false); sessionStorage.setItem('afterLogin', false) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="gs_appointment_input gs_login">
                    <p className='text-left d-flex align-items-center border-bottom pb-2 mb-3'>
                        <AiOutlineUser size='18px' /> <span className='fs14 f_roboto ml-1'>Hi {localStorage.getItem('user') !== null && JSON.parse(localStorage.getItem('user'))?.first_name} !</span>
                    </p>
                    <div className='border-bottom pb-3'>
                        <Link className='f_roboto fs14 text-uppercase mb-0' to='/myaccount'>Account Details</Link>
                    </div>
                    <div className='pt-3'>
                        <div onClick={handlelogout} className='f_roboto pointer fs12 text-uppercase mb-0 color666'> Sign Out</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        }


        <Modal className="gs_login_modal rightSideModal"
            aria-labelledby="contained-modal-title-vcenter"
            show={login} onHide={() => { setLogin(false); setreload(false); }}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                {signup ? <div className="gs_appointment_input gs_login">
                    <Modal.Title id="contained-modal-title-vcenter">Sign Up</Modal.Title>
                    <Form onSubmit={handlesignup}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    type="text"
                                    variant="standard"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    required />
                            </Col>
                            <Col xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="Last Name"
                                    type="text"
                                    variant="standard"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-basic"
                                    label="Email Address"
                                    type="email"
                                    variant="standard"
                                    value={s_email}
                                    onChange={(e) => setS_email(e.target.value)}
                                    required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-basic"
                                    label="Password"
                                    type="password"
                                    variant="standard"
                                    value={s_password}
                                    onChange={(e) => setS_password(e.target.value)}
                                    required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-basic"
                                    label="Confirm Password"
                                    type="password"
                                    variant="standard"
                                    value={s_confirmpassword}
                                    onChange={(e) => setS_confirmpassword(e.target.value)}
                                    required />
                            </Col>
                        </Row>
                        <Row className="gs_check_condition mt-0">
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={terms}
                                            onChange={handleChange3}
                                            name="checkedB"
                                            color="primary"
                                            required
                                        />
                                    }
                                />
                                <span className="gs_condition">I agree and accept your <NavLink to="/terms-of-service" target="_blank"> Terms </NavLink> and <NavLink to="/privacy-policy" target="_blank"> Privacy Policy </NavLink></span>
                            </Col>
                        </Row>
                        <Row className="gs_check_condition">
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={news}
                                            onChange={handleChange4}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                />
                                <span className="gs_condition">Subscribe to our newsletter for our mailing list</span>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <Button className="gs_fill_button" type="submit" disabled={buttonLoading}>
                                    Create Account {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6} className="mobile_social_6">
                                <div className="gs_google_btn">

                                    <GoogleLogin
                                        clientId="549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com"
                                        buttonText="Login with Google"
                                        onSuccess={(e) => onResponse(e, 'google')}
                                        onFailure={(err) => console.log("ERR1", err)}
                                    />

                                </div>
                            </Col>

                            <Col className="mobile_social_6" sm={6}>
                                <div className="gs_fb_btn">
                                    <FacebookIcon />
                                    <ReactFacebookLogin
                                        appId="674830840525971" // we created this, remember?
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={(e) => onResponse(e, "facebook")}
                                        onFailure={(err) => console.log("ERR", err)}
                                    />

                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <div className="gs_signup_link">
                                    <p>Already have an account ? <NavLink to="#" onClick={() => setSignup(false)}>Login</NavLink></p>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div> :

                    forgotpassword ?
                        <div className="gs_appointment_input gs_login gs_forgot">
                            <Modal.Title id="contained-modal-title-vcenter">Forgot Password</Modal.Title>
                            <div className="gs_forgot_msg"><span>To reset your password, please enter your email address</span></div>
                            <Form onSubmit={handleforgotpassword}>
                                <Row>
                                    <Col>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email Address"
                                            type="Email"
                                            variant="standard"
                                            value={f_email}
                                            onChange={(e) => setF_email(e.target.value)}
                                            required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="gs_fill_button" type="submit" disabled={buttonLoading}>
                                            Continue {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="gs_signup_link">
                                            <p>Remember your password ? <NavLink to="#" onClick={() => setForgotpassword(false)}>Login</NavLink></p>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div> :
                        <div className="gs_appointment_input gs_login">
                            {/* <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title> */}
                            <p className='text-left d-flex align-items-center border-bottom pb-2 mb-3'>
                                <AiOutlineUser size='18px' /> <span className='fs14 f_roboto ml-1'>Hi there!</span>
                            </p>

                            <h4 className='f_roboto fs14 text-uppercase mb-0'>
                                Sign in
                            </h4>
                            <p className='f_roboto fs14 fw400 text-left pt-0 pb-0 mb_lg_0'>
                                Please sign in to your Gem Stone King Account
                            </p>
                            <Form onSubmit={handlesubmitlogin}>
                                <Row>
                                    <Col>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            type="email"
                                            variant="standard"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TextField
                                            id="outlined-basic"
                                            label="Password"
                                            type="password"
                                            variant="standard"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="gs_fill_button1 gs_cont_btn btn btn-primary" type="submit" disabled={buttonLoading}>
                                            Sign In {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className='justify-content-start mt-4'>
                                    <Col>
                                        <NavLink className="gs_login_forgot f_roboto" to="#" onClick={() => setForgotpassword(true)}>Forgot Password ?</NavLink>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <div className="gs_signup_link border-top pt-2 mt-3">
                                            <p className='pt-2'><NavLink className='pl-0 f_roboto fs14 text-uppercase' to="/sign-in"
                                                onClick={() => setLogin(false)}
                                            //onClick={() => setSignup(true)}
                                            >Create an account</NavLink></p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} md={6} className="mobile_social_6">
                                        <div className="gs_google_btn">
                                            <GoogleLogin
                                                clientId="549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com"
                                                buttonText="Login with Google"
                                                onSuccess={(e) => onResponse(e, 'google')}
                                                onFailure={(err) => console.log("ERR2", err)}
                                                cookiePolicy={'single_host_origin'}
                                            />



                                        </div>
                                    </Col>
                                    <Col className="mobile_social_6" sm={6}>
                                        <div className="gs_fb_btn">
                                            <ReactFacebookLogin
                                                appId="674830840525971"
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                callback={(e) => onResponse(e, "facebook")}
                                                onFailure={(err) => console.log("ERR", err)}
                                                icon={<FacebookIcon />}
                                                textButton=""
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </div>}
            </Modal.Body>
        </Modal>
    </>
    );
};

export default Login;