import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import about_line from "../../assets/images/icon/line_black.png";
import community_banner from "../../assets/images/community_banner.png";

const GivingBackToCommunity = () => {
    return (
        <>
            <Helmet>
                <title>Giving Back To The Community | Gem Stone King ™</title>
                <meta name="description" content="No matter if you are mentally ill, suffering from an addiction, or physically disabled: low incomes and high housing costs are the root causes of homelessness. In fact, recent study shows that nowhere in the United States can someone who works 40 hours a week at minimum wage afford a one bedroom apartment at fair marke" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/giving-back-to-the-community" />
                <meta property="og:title" content="Giving Back To The Community" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="No matter if you are mentally ill, suffering from an addiction, or physically disabled: low incomes and high housing costs are the root causes of homelessness. In fact, recent study shows that nowhere in the United States can someone who works 40 hours a week at minimum wage afford a one bedroom apartment at fair marke" />
            </Helmet>
            <div className='gs_community_banner'>
                <Image src={community_banner} alt="Community Banner"></Image>
            </div>
            <div className="gs_static_pages_content">
                <Container>
                    <Row className="m-0 w-100">
                        <div className="gs_static_heading">
                            <h1>GIVING BACK TO THE COMMUNITY</h1>
                            <Image src={about_line} alt="line"></Image>
                        </div>
                        <div className="gs_static_section">
                            <p>No matter if you are mentally ill, suffering from an addiction, or physically disabled: low incomes and high housing costs are the root causes of homelessness. In fact, recent study shows that nowhere in the United States can someone who works 40 hours a week at minimum wage afford a one bedroom apartment at fair market rent. It costs as little as $38.50 to provide a night of shelter for someone without a home.</p>
                            <p>So in Gem Stone King we decided to donate $1 of every order that will be made on our website to support foundations that provide shelter to the homeless people.</p>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default GivingBackToCommunity
