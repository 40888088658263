import React, { useState, useEffect } from "react";
import { useStripe, useElements,PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { base_url, isLogin, postHeader, user, web_base_url } from "../Helpers/request";
// import { useDispatch, useSelector } from "react-redux";
// import { cartlengthvalue } from "../Reducer/cartReducer";
// import { whishlistlength } from "../Reducer/wishlistReducer";
// import { sessionId } from "../Reducer/homeReducer";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";

export default function Stripe2(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

//   const handlelogout = () => {
//     localStorage.removeItem('bw-user');
//     dispatch(whishlistlength(0))
//     dispatch(cartlengthvalue(0))
//     dispatch(sessionId(''))
//     history.push("/")
//     window.location.reload(true);
// }
  const handleSubmit = async (e) => {

    setLoader(true);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    // elements.create('payment', {
    //   paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna']
    // });

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url:  `${window.location.origin}/order-success/${props?.data?.user_id}/${props?.data?.order_id}/${props?.data?.order_no}/null/?total=${props?.data?.final_amount}`,
        },
        redirect: "if_required",
      })
      .then(function (result) {
        setIsProcessing(false);
        if (result?.error?.type || result?.error?.type === "validation_error") {
          setMessage(result?.error?.message);
          setLoader(false);
        } else {
          let payload = {
                    user_id: isLogin ? props?.data?.user_id : props?.data?.billing_address?.user_id,
                    order_id: props?.data?.order_id,
                    payment_intent: result?.paymentIntent?.id,
                    redirect_status:result?.paymentIntent?.status,
                    order_no: props?.data?.order_no,
                    txn_id: result?.paymentIntent?.id,
                    currency_code: props?.data?.currency_code ,
                    payer_email_address: props?.email
                };
                axios.post(web_base_url + "/api/stripcheckoutview", payload, {
                    headers: postHeader,
                })
                    .then((response) => {
                        if (response.data.status == 1) {
                        
                            setLoader(false);
                            history.push(`/order-success/${props?.data?.user_id}/${props?.data?.order_id}/${props?.data?.order_no}/${response?.data?.data?.txn_id}?total=${props?.data?.final_amount}`)
                            // dispatch(cartlengthvalue(0));
                            // sessionStorage.removeItem("bw-checkoutdata");
                            // history.push(
                            //     `/ordersuccess/${response.data.data.order_id}/${response.data.data.order_no}/${response.data.data.txn_id}`
                            // );
                        }
                         else if (response.data.status == 2) {
                            setLoader(false);
                            history.push("/payment-declined/")
                            // handlelogout();
                        } else {
                            setLoader(false);
                        }
                    })
                    .catch((err) => {
                        setLoader(false);
                        console.log(err);
                    });
        }
      });
    
  };

  return (
    <>
      <form id="payment-form">
        <PaymentElement id="payment-element" />
        <button className="mt-3 btn bj_pay_button" disabled={isProcessing || !stripe || !elements} onClick={handleSubmit}>
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>

        {message && <div id="payment-message">{message}</div>}
      </form>
      {/* <Modal show={loader}>
          Loading...
        </Modal> */}
    </>
  );
}
