import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import ReactFacebookLogin from "react-facebook-login";
import { CircularProgress, TextField } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import FacebookIcon from '@material-ui/icons/Facebook';
import { isLogin, postData } from "../../../Helpers/request";
import { toast } from "react-toastify";
import Login from "../../../Components/Login";
import Modal from 'react-bootstrap/Modal';
import Forget from "./Forget";
import { crossIcon } from "../../../assets/svg";
import { useDispatch } from "react-redux";
import { UserData } from "../../../store/slices/Global";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {

    if (isLogin) {
      history.push("/myaccount");
    }

  }, []);

  //login
  const [login, setLogin] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signup, setSignup] = useState(false);
  const [forgotpassword, setForgotpassword] = useState(false);

  const onResponse = (resp, type) => {
    setButtonLoading(true);
    const session_id = localStorage.getItem("session_id")
      ? localStorage.getItem("session_id")
      : "";
    const data = {
      first_name:
        type == "google"
          ? resp.profileObj?.givenName
          : resp?.name?.split(" ")[0],
      last_name:
        type == "google"
          ? resp.profileObj?.familyName
          : resp?.name?.split(" ")[1],
      social_id: type == "google" ? resp.profileObj?.googleId : resp?.id,
      login_method: type == "google" ? 2 : 3,
      email: type == "google" ? resp.profileObj?.email : resp?.email,
      session_id,
    };
    postData(`/user/socialLogin`, data)
      .then((response) => {
        if (response.status === 1) {
          sessionStorage.setItem('afterLogin', true);
          // window.location.reload(true);
          var user = {
            token: response.data.token,
            user_id: response.data.user_id,
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            isLogin: true,
          };
          localStorage.setItem("cartlength", response.data.cart_item_count);
          localStorage.setItem(
            "wishlistlength",
            response.data.wishlist_item_count
          );
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.removeItem("session_id");
          setUsername("");
          setPassword("");
          setButtonLoading(false);
          setLogin(false);

          // window.location.href('/myaccount');
        } else {
          toast.error(response.message, { autoClose: 3000 });
          setButtonLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlesubmitlogin = (e) => {
    setButtonLoading(true);
    e.preventDefault();
    const session_id = localStorage.getItem("session_id")
      ? localStorage.getItem("session_id")
      : "";
    postData(`/user/login`, { username, password, session_id })
      .then((response) => {
        if (response.status === 1) {
          if (window?.klaviyo) {
            window.klaviyo.push(['identify', {
              'email': username
            }])
          };

          sessionStorage.setItem('afterLogin', true);
          window.location.reload(true);
          var user = {
            token: response.data.token,
            user_id: response.data.user_id,
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            isLogin: true,
          };

          localStorage.setItem("cartlength", response.data.cart_item_count);
          localStorage.setItem(
            "wishlistlength",
            response.data.wishlist_item_count
          );
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.removeItem("session_id");
          setUsername("");
          setPassword("");
          setButtonLoading(false);
          setLogin(false);
          dispatch(UserData(user));
        } else {
          toast.error(response.message, { autoClose: 3000 });
          setButtonLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section>
      <div className="gs_appointment_input gs_login">
        <div className="text-left">
          <h2 className="f_ovo">Sign In</h2>
          <p className="f_roboto fw400 text-left pt-0">
            Please sign in to your Gem Stone King Account
          </p>
        </div>

        <Form onSubmit={handlesubmitlogin}>
          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Email"
                type="email"
                variant="standard"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Password"
                type="password"
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="gs_cont_btn btn btn-primary mt-2 mb-3 f_roboto"
                type="submit"
                disabled={buttonLoading}
              >
                Sign In
                {buttonLoading ? (
                  <CircularProgress className="gs_loader" />
                ) : (
                  ""
                )}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <NavLink
                className="gs_login_forgot1 f_roboto"
                to="#"
                onClick={() => handleShow()}
              >
                Forgot your Password ?
              </NavLink>
            </Col>


          </Row>
          <p className="f_roboto mb-0 pb-0">
            Login with
          </p>
          <Row>
            <Col sm={6} md={6} className="mobile_social_6">
              <div className="gs_google_btn">
                <GoogleLogin
                  clientId="549981789532-4bt0u9a45fujhv35ha0dil32bomr9221.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={(e) => onResponse(e, "google")}
                  onFailure={(err) => console.log("ERR2", err)}
                  disabled={false}
                  accessType="offline"
                  responseType="code"
                />

              </div>
            </Col>
            <Col className="mobile_social_6" sm={6}>
              <div className="gs_fb_btn">
                <ReactFacebookLogin
                  appId="674830840525971"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={(e) => onResponse(e, "facebook")}
                  onFailure={(err) => console.log("ERR", err)}
                  icon={<FacebookIcon />}
                  textButton=""
                />
              </div>
            </Col>
          </Row>

        </Form>
        <Modal show={show} onHide={handleClose} centered>
          <div className="d-flex justify-content-end">
            <div className="btn-link pointer p-3" onClick={handleClose}>
              {crossIcon}
            </div>
          </div>

          <Modal.Body><Forget /></Modal.Body>
        </Modal>
      </div>
    </section>
  );
};

export default SignIn;
