import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Button, Divider } from '@material-ui/core';
import '../../assets/css/wishlist.css'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { deleteData, getData, postData } from '../../Helpers/request';
import emptyCart from "../../assets/images/icon/wishlist.svg";
import { Skeleton } from '@material-ui/lab';
import { Modal } from 'react-bootstrap';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { toast } from 'react-toastify';
import gs_ringsicon from '../../assets/images/Home/rings.jpg';
import gs_pendanticon from '../../assets/images/Home/Pendant.jpg';
import gs_earringicon from '../../assets/images/Home/Earrings.jpg';
import gs_braceletsicon from '../../assets/images/Home/Bracelet.jpg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const Wishlist = () => {
    const klaviyoWishlist = async (val) => {
        var item = {
            "url": window.location.href,
            "product_list": val,
        };
        if (window.klaviyo) {
            await window.klaviyo.push(["track", "View Wishlist", item]);
        }
    }

    const classes = useStyles();
    const [wishlistitem, setWishlistitem] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [wishid, setWishid] = React.useState(null);
    const [remove, setRemove] = React.useState(false);
    const [mywish, setMywish] = React.useState(localStorage.getItem("wishlistlength"));
    useEffect(() => {
        getWishlistitem();
    }, [])


    const history = useHistory()
    const getWishlistitem = () => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/wishlist?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then(res => {
                if (res.status == 1) {
                    setWishlistitem(res.data);
                    setMywish(res.data.wishlist_count)
                    localStorage.setItem("wishlistlength", res.data.wishlist_count)
                    klaviyoWishlist(res.data?.wishlist)
                }
            })
    }
    const deleteitem = (id) => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        deleteData(`/order/wishlist?wishlist_id=${id}&${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getWishlistitem();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const addtocart = (id, product_id, stone_data) => {
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            product_id: product_id,
            stone_data: stone_data?.length ? Object.values(stone_data)?.toString()?.replaceAll(",", "_") : "",
            ring_size: 0,
            quantity: 1
        }
        postData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    localStorage.setItem("cartlength", response.data.cart_item_count)
                    deleteitem(id);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const imageLoaded = () => {
        setLoading(true)
    }
    return (
        <>
            <Helmet>
                <title>Wish List | Gem Stone King ™</title>
                <meta name="description" content="Wishlist" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/wishlist" />
                <meta property="og:title" content="Wishlist" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Wishlist" />
            </Helmet>
            <div className="gs_wishlist_content">
                <Container className="gs_customer_container">
                    <Row className="m-0 w-100">
                        <div className="gs_wish_heading">
                            <h1>My Wish list <span>{mywish == 1 ? `( ${mywish} Item )` : mywish > 1 ? `( ${mywish} Items )` : ""}</span></h1>
                        </div>
                    </Row>
                    {mywish > 0 ?
                        <Row className="pb-3">
                            {wishlistitem.wishlist?.map(val =>
                                <Col sm={6} md={6} lg={4} xl={3} className="mb-3">
                                    <div className="gs_my_wishlist">
                                        <DeleteIcon onClick={() => { setWishid(val.id); setRemove(true) }} className="gs_remove_icon" />
                                        <Skeleton style={{ display: loading ? "none" : "block", margin: "5px auto 20px auto" }} variant="rect" animation="wave" width={200} height={180} />
                                        <img style={{ display: loading ? "block" : "none" }} onLoad={() => imageLoaded()} src={val.product[0]?.img_url} onClick={() => { window.scrollTo(0, 0); history.push('/productdetail/' + val.product[0]?.product_slug + "/" + val.product[0]?.Product_sku + (val.product[0]?.stone_data?.length ? "/" + Object.values(val.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "")) }} />
                                        <h2 className="gs_wish_title" onClick={() => { window.scrollTo(0, 0); history.push('/productdetail/' + val.product[0]?.product_slug + "/" + val.product[0]?.Product_sku + (val.product[0]?.stone_data?.length ? "/" + Object.values(val.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "")) }}>{val.product[0]?.product_name?.substring(0, 50)}..</h2>
                                        <p className="gs_sku">{val.product[0]?.Product_sku}</p>
                                        <p className="gs_wish_price">{val.product[0]?.currency_icon}{val.product[0]?.sale_price}</p>
                                        <div >
                                            <Button onClick={() => addtocart(val.id, val.product[0]?.id, val.product[0]?.stone_data)} className="gs_wish_btn" variant="outline-secondary">Add to Cart</Button>
                                        </div>
                                    </div>
                                </Col>
                            )}
                            <Modal
                                aria-labelledby="contained-modal-title-vcenter"
                                centered className="gs_appointment_modal gs_cart" show={remove} onHide={() => setRemove(false)}>
                                <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
                                <Modal.Body>
                                    <div className="gs_appointment_input">
                                        <DeleteOutlineIcon className="gs_remove_cart" />
                                        <Modal.Title>Are you sure you want to remove this item ?</Modal.Title>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" className="gs_fill_button" onClick={() => { deleteitem(wishid); setRemove(false) }}>
                                        Yes
                                    </Button>
                                    <Button variant="primary" className="gs_outline_button" onClick={() => setRemove(false)}>
                                        No
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Row> : <Row className='w-100 d-block m-auto'><NavLink to="/"> <Image className="gs_empty_cart" src={emptyCart} alt="cart empty image"></Image> </NavLink>
                            <Divider />
                            <ul className="gs_no_prod_item mt-5">
                                <li onClick={() => history.push('/category/shop-rings/category=ring')}>
                                    <img className="gs_noprod_img" src={gs_ringsicon} />
                                    Shop Rings
                                </li>
                                <li onClick={() => history.push('/category/shop-pendants/category=pendant')}>
                                    <img className="gs_noprod_img" src={gs_pendanticon} />
                                    Shop Pendants</li>
                                <li onClick={() => history.push('/category/shop-earrings/category=earrings')}>
                                    <img className="gs_noprod_img" src={gs_earringicon} />
                                    Shop Earrings</li>
                                <li onClick={() => history.push('/category/shop-bracelets/category=bracelet')}>
                                    <img className="gs_noprod_img" src={gs_braceletsicon} />
                                    Shop Bracelets</li>
                            </ul>
                        </Row>}
                </Container>
            </div>
        </>
    )
}

export default Wishlist
