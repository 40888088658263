import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../../Helpers/request';

const SignUp = () => {
    const [buttonLoading, setButtonLoading] = useState(false);
    //signup
    const [s_email, setS_email] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [s_password, setS_password] = useState('');
    const [s_confirmpassword, setS_confirmpassword] = useState('');
    const [signup, setSignup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [terms, setTerms] = useState(false);
    const [news, setNews] = useState(false);

    const handlesignup = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (s_confirmpassword == s_password) {
            var data = {
                first_name: firstname,
                last_name: lastname,
                email: s_email,
                password: s_password
            }
            postData(`/user/register`, data)
                .then(function (response) {
                    if (response.status == 1) {
                        if (window?.klaviyo) {
                            window.klaviyo.push(['identify', {
                                'email': s_email,
                                'Name': firstname+" "+lastname
                            }])
                        };
                        setSignup(false);
                        setButtonLoading(false);
                        toast.success(response.message, { autoClose: 3000 });
                    } else {
                        toast.error(response.message, { autoClose: 3000 });
                        setButtonLoading(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            toast.error("Password & Confirm password do not match!", { autoClose: 3000 });
            setS_password("");
            setS_confirmpassword("");
        }
    }

    const handleChange3 = (event) => {
        setTerms(event.target.checked);
    };
    const handleChange4 = (event) => {
        setNews(event.target.checked);
    };


    return (
        <section className='mb-5'>
            <div className="text-left">
                <h2 className="f_ovo">Create an  Account</h2>
                <p className="f_roboto fs12 fw400 text-left pt-0">
                    Create your account  for express checkout  with stored shipping  and billing  information
                </p>
            </div>
            <Form onSubmit={handlesignup} className='signUpData'>
                <Row>
                    <Col xs={12} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="First Name"
                            type="text"
                            variant="standard"
                            className='w-100 mb-3 f_roboto'
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            required />
                    </Col>
                    <Col xs={12} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="Last Name"
                            type="text"
                            className='w-100 mb-3 f_roboto'
                            variant="standard"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id="outlined-basic"
                            label="Email Address"
                            type="email"
                            className='w-100 mb-3 f_roboto'
                            variant="standard"
                            value={s_email}
                            onChange={(e) => setS_email(e.target.value)}
                            required />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id="outlined-basic"
                            label="Password"
                            type="password"
                            className='w-100 mb-3 f_roboto'
                            variant="standard"
                            value={s_password}
                            onChange={(e) => setS_password(e.target.value)}
                            required />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id="outlined-basic"
                            label="Confirm Password"
                            type="password"
                            variant="standard"
                            className='w-100 mb-3 f_roboto'
                            value={s_confirmpassword}
                            onChange={(e) => setS_confirmpassword(e.target.value)}
                            required />
                    </Col>
                </Row>
                <Row className="gs_check_condition mt-1 mb-0">
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={terms}
                                    onChange={handleChange3}
                                    name="checkedB"
                                    color="primary"
                                    required
                                />
                            }
                        />
                        <span className="gs_condition f_roboto">I agree and accept your <NavLink to="/terms-of-service" target="_blank" className='fw400'> Terms </NavLink> and <NavLink to="/privacy-policy" target="_blank" className='fw400'> Privacy Policy </NavLink></span>
                    </Col>
                </Row>
                <Row className="gs_check_condition mb-1 mt-0">
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={news}
                                    onChange={handleChange4}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                        />
                        <span className="gs_condition f_roboto">Subscribe to our newsletter for our mailing list</span>
                    </Col>
                </Row>
                <Row className="mt-0">
                    <Col>
                        <Button className="gs_fill_button1 gs_cont_btn btn btn-primary mt-2 f_roboto" type="submit" disabled={buttonLoading}>
                            Create Account {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                        </Button>
                    </Col>
                </Row>

            </Form>
        </section>
    )
}

export default SignUp
