import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Collection2 from "../../assets/images/Home/collection2.png";
import "../../assets/css/Shoping-bag.css";
import { MenuItem, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { BsHeart } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Paypal from "../../assets/images/Home/new-home/paypal.png";
const Payment = () => {
  return (
    <>
      <div className="breadCrumb cart_breadcrumb">
        <Container className="gs_customer_container_fluid" fluid>
          <nav aria-label="breadcrumb" className="breadCrumbWrapper">
            <ol className="breadcrumb bg-transparent">
              <li className="breadcrumb-item">
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                Payment
              </li>
            </ol>
          </nav>
          <div className="gs_product_top_heading">
            <h1>Payment</h1>

            <p>All transactions are secure and encrypted</p>
          </div>
        </Container>
      </div>

      <section className="gsk_shoping_bag_section">
        <Container className="gs_customer_container">
          <Row className="">
            <Col lg={5} className="gs_shoping_cart_order_2">
              <Row>
                <Col lg={8}>
                  <Col className="p-0">
                    <div className="gs_payment_card_radio">
                      <Form.Check
                        inline
                        label="Credit Card"
                        name="group1"
                        type="radio"
                        id="s1"
                      />
                    </div>
                  </Col>

                  <Col className="p-0">
                    <TextField
                      id="card-number"
                      label="Card number"
                      type="number"
                      defaultValue=""
                      variant="standard"
                      className="w-100 mb-3"
                    />
                  </Col>
                  <Col className="p-0">
                    <TextField
                      id="holder-Name"
                      label="Card holder number"
                      defaultValue=""
                      variant="standard"
                      className="w-100 mb-3"
                    />
                  </Col>

                  <Row className="w-100 m-0">
                    <Col lg={6} className="pl-0">
                      <TextField
                        id="date"
                        defaultValue=""
                        label=" "
                        variant="standard"
                        type="date"
                        className="w-100 mb-3"
                      />
                    </Col>

                    <Col lg={6} className="pr-0 pl-0">
                      <TextField
                        id="security-code"
                        label="Security code"
                        defaultValue=""
                        variant="standard"
                        className="w-100 mb-3"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="gs_shiping_form">
                <Row>
                  <Col sm={12}>
                    <ul>
                      <li>
                        <div className="gs_payment_paypal_radio paypal_inp">
                          <div class="form-check">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="paypal"
                              name="optradio"
                              value=""
                              checked
                            />
                            <label class="form-check-label" for="paypal">
                              <img src={Paypal} />
                            </label>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="gs_payment_paypal_radio amazon-inp">
                          <div class="form-check">
                            <input
                              type="radio"
                              class="form-check-input "
                              id="amazon"
                              name="optradio"
                              value=""
                            />
                            <label class="form-check-label" for="amazon">
                              Amazon Pay
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>

                  <Col lg={12} className="mt-1 mb-3">
                    <div className="gs_payment_shiping_address">
                      <Form.Check
                        inline
                        label="Same as Shipping Address"
                        name="group1"
                        type="checkbox"
                        id="Shipping-Address"
                      />
                    </div>
                    <div className="gs_payment_shiping_address">
                      <Form.Check
                        inline
                        label="Use a Different Billing Address"
                        name="group1"
                        type="checkbox"
                        id="biling-Address"
                      />
                    </div>
                  </Col>

                  <Row className="w-100 m-0 mb-4">
                    <Col>
                      <p
                        className="gs_montserrat mb-1"
                        style={{ fontWeight: "500" }}
                      >
                        Remember me
                      </p>
                    </Col>
                    <Col sm={12}>
                      <div className="gs_payment_shiping_address">
                        <Form.Check
                          inline
                          label="Save my information for a faster checkout"
                          name="group1"
                          type="checkbox"
                          id="biling-Address"
                        />
                      </div>
                    </Col>
                  </Row>
                </Row>
              </div>
              <div className="gs_shiping_bag_btn gs_payment_place_btn">
                <Button> Place Order </Button>
              </div>
            </Col>
            <Col lg={7}>

              {/* product item main div  */}
              <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box payment">
                <Row>
                  <Col sm={12} md={4} lg={6} xl={3}>
                    <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={8}>
                    <h2 className="gs_wish_title gs_shooping_bag">
                      Sterling Silver Women Solitaire Ring
                    </h2>

                    <h2 className="gs_wish_title gs_shooping_bag_price">
                      $49.99
                    </h2>
                    <div className="gs_cart_quantitys gs_shoping_bag">
                      <span className="mr-3 gs_q_size">Quantity </span>
                      <InputGroup className="mt-2 input-group">
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <RemoveIcon />
                        </Button>
                        <input
                          className="gs_shoping_cart_count_inp"
                          type="text"
                          value="1"
                          disabled
                          name="name"
                        />
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>

                    <Row className="w-100 m-auto">
                      {/* {val.product[0].engraving?.filter(val => val.value?.length > 0).length ? */}
                      <Col xs={12} sm={12} className="p-0">
                        <span className=" mt-2 gs_eng_txt">
                          <BsHeart className="mr-3 f-20" />
                          <AiOutlineDelete className="" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="gs_shoping_bag_cart_accordion">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Customizations
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="gs_shoping_bag_cart_customiz">
                          <ul>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone Selection:
                              </span>
                              <span> 1</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 1:
                              </span>
                              <span> Emerald</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 2:
                              </span>
                              <span> Citrine</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving Options:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Fonts:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 1:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 2:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 3:
                              </span>
                              <span> test</span>
                            </li>
                          </ul>
                          <p> Edit </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>

              {/* product item main div  */}
              <div className="gs_my_wishlist gs_my_cart gs_shoping_cart_box payment">
                <Row>
                  <Col sm={12} md={4} lg={6} xl={3}>
                    <Image src="https://gemstoneking.com/img/MEG-0003/fe379f20d76d4691f6a22765355c4496.png"></Image>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={8}>
                    <h2 className="gs_wish_title gs_shooping_bag">
                      Sterling Silver Women Solitaire Ring
                    </h2>

                    <h2 className="gs_wish_title gs_shooping_bag_price">
                      $49.99
                    </h2>
                    <div className="gs_cart_quantitys gs_shoping_bag">
                      <span className="mr-3 gs_q_size">Quantity </span>
                      <InputGroup className="mt-2 input-group">
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <RemoveIcon />
                        </Button>
                        <input
                          className="gs_shoping_cart_count_inp"
                          type="text"
                          value="1"
                          disabled
                          name="name"
                        />
                        <Button
                          variant="outline-secondary"
                          className="gs_increment_btn gs_shoping_increament"
                          id="button-addon1"
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>

                    <Row className="w-100 m-auto">
                      {/* {val.product[0].engraving?.filter(val => val.value?.length > 0).length ? */}
                      <Col xs={12} sm={12} className="p-0">
                        <span className=" mt-2 gs_eng_txt">
                          <BsHeart className="mr-3 f-20" />
                          <AiOutlineDelete className="" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="gs_shoping_bag_cart_accordion">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Customizations
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="gs_shoping_bag_cart_customiz">
                          <ul>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone Selection:
                              </span>
                              <span> 1</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 1:
                              </span>
                              <span> Emerald</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Stone 2:
                              </span>
                              <span> Citrine</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving Options:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Fonts:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 1:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 2:
                              </span>
                              <span> test</span>
                            </li>
                            <li>
                              <span className="gs_customiz_bold_text">
                                Engraving 3:
                              </span>
                              <span> test</span>
                            </li>
                          </ul>
                          <p> Edit </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>

              {/* subtotal  */}
              <div className="gs_shoping_bag_cart_subTotal">
                <ul>
                  <li>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto"> Subtotal </h5>
                      <h5> $252.56 </h5>
                    </div>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto"> Expedited Shipping </h5>
                      <h5 style={{ textTransform: "uppercase" }}> free </h5>
                    </div>

                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto">Estimated Tax </h5>
                      <h5 style={{ textTransform: "uppercase" }}> $24.00 </h5>
                    </div>

                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto"> Gift Card / Promo code </h5>
                      <div className="gs_promo_main">
                        <TextField
                          id="promo-code"
                          defaultValue=""
                          label=" "
                          variant="outlined"
                          type="text"
                          className="w-100  mt-0 promo_code_inp"
                        />{" "}
                        <AiOutlineDelete className="" />
                      </div>
                      <h5 style={{ textTransform: "uppercase" }}>$52.00 </h5>
                    </div>
                  </li>

                  <li>
                    <div className="gs_shoping_bag_cart_subTotal_text">
                      <h5 className="gs_roboto">
                        Grand Total <br />
                      </h5>

                      <h5> $259.99 </h5>
                    </div>
                  </li>
                </ul>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Payment;
