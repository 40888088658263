import React, { Component } from 'react';
import { Container, Col, Row, Image, InputGroup, FormGroup, Modal, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SideBySideMagnifier } from "react-image-magnifiers";
import ImageGallery from "react-image-gallery";
import "../../assets/css/product_details.css"
import { withStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Link, NavLink, useHistory } from 'react-router-dom';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import adviceImage from "../../assets/images/product_details/Consultants_banner.jpg";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { base_url, deleteData, getData, isLogin, postData } from '../../Helpers/request';
import { toast, ToastContainer } from 'react-toastify';
import { FormControlLabel, FormControl, Checkbox, InputLabel, Select, MenuItem, Box, TextField, IconButton } from '@material-ui/core';
import { Skeleton, Rating } from '@material-ui/lab';
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FacebookShareButton, EmailShareButton, PinterestShareButton, TwitterShareButton } from "react-share";
import Engraving from '../../Components/engraving';
import { MdDeleteForever, MdOutlineLocalShipping } from 'react-icons/md';
import { AngleBottom, AngleTop, callIcon, crossIcon, envelopIcon, fbIcon, HeartEmpty, HeartFilled, HeartIcon, pinterestIcon, QuestionIcon, share, Shipping, ShippingIcon, twitterIcon } from '../../assets/svg';
import exchangeIcon from '../../assets/images/exchange.png'
import { BsEnvelope, BsHeart, BsQuestionCircle, FaFacebookF } from 'react-icons/bs';
import GskDifference from '../Landing/GskDifference';
import { AiTwotoneHeart } from 'react-icons/ai';
import guidePdf from '../../assets/images/ring-sizing-guide.pdf';
import Testing from './Testing';
import { Redirect } from "react-router-dom";
var settings12 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};

const style = (theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        minWidth: 120,
    }
});

var wishlistId = localStorage.getItem('wishlistListID');


class Productdetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            statusID: 1,
            showFilter: true,
            images: [],
            currentImageUrl: [],
            filterdata: [],
            MetalColor: null,
            product_slug: "",
            stone_data: this.props.match.params.stone_data,
            category_slug: "",
            RingSize: '',
            productdata: [],
            similarproductdata: [],
            MetalDescription: null,
            loadingF: false,
            loading: false,
            loading2: false,
            loading3: false,
            loading1: false,
            num: 1,
            title: "",
            exp_text: "",
            rating: 0,
            show: false,
            imageURI: null,
            reviewdata: [],
            appointment: false,
            hint: false,
            offers: false,
            product_id: null,
            date: moment(new Date()).format("YYYY-MM-DD"),
            fields: [{ name: '', email: '' }],
            hintname: '',
            hintemail: "",
            hintcomment: "",
            panelExpand: ["panel0", "panel1"],
            isExpanded1: "panel1",
            noRingsize: false,
            addcartbutton: false,
            norating: false,
            engravingshow: false,
            giftbox: false,
            giftboxid: 0,
            engraving1: '',
            engraving2: '',
            engraving3: '',
            engraving: '',
            engravingVal: [],
            textstyle: 'great_vibes',
            eng_color: false,
            product_type: 0,
            eng_popup: false,
            eng_data: [],
            eng_img: [],
            activeRing: '',
            shareBtn: false,
            moreInfo: false,
            hoverpb: '',
            selectedFilter: [],
            categoryName: "",
            wishlistIndex: '',
            activeBtn: '',
            ringSizeValid: false,
            ringSizeModal: false,
            ifMetalSelect: false,
            redirection: false,
            productDetalMsg: ""
        }

    
    }
    hintsubmit = (e) => {
        e.preventDefault();
        var data = {
            friend: this.state.fields,
            friends_message: this.state.hintcomment,
            sender_name: this.state.hintname,
            sender_email: this.state.hintemail,
            product_url: window.location.href,
            product_image: this.state.currentImageUrl,
            product_price: this.state.productdata.sale_price,
        }
        postData("/api/drophint", data)
            .then(res => {
                toast.success(res.message, { autoClose: 3000 });
                this.setState({ hint: false })
            })
            .catch(err => {
                console.log(err)
            })
    }
    productApi = (metalcolor, stone_data) => {
        
        this.setState({ loading: false, loading2: false, loadingF: false })

        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/api/productDetail?ProductSku=${this.props.match.params.sku}&stone_data=${stone_data?.length ? stone_data : ""}${metalcolor ? "&MetalColor=" + metalcolor : "&ProductSlug=" + this.props.match.params.slug}&${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then((response) => {
                this.setState({
                    productDetalMsg: response?.message
                })
                if (response.status == 1) {

                    this.setState({ statusID: response?.data?.status_id })
                    setTimeout(() => {
                        this.setState({ loadingF: true })
                    }, 6000);
                    // this.setState({ loading: true, loading2: true })

                    sessionStorage.setItem("review_pid", response.data.id)
                    this.klaviyoViewedProduct(response?.data)
                    this.setState({
                        product_id: response.data.id,
                        productdata: response.data,
                        currentImageUrl: response.data.img_url[0].image_url,
                        category_slug: response.data.category_slug,
                        product_slug: response.data.product_slug,
                        MetalColor: response.data.metal_name,
                        MetalDescription: response.data.color_description,
                        product_type: response.data.product_type,
                        categoryName: response.data.category_name
                    });

                    localStorage.setItem('wishlistListID', response?.data?.wishlist_id)

                    var arr = [];
                    if (response.data.img_url.length) {
                        if (this.state.eng_img?.length) {
                            var obj = { original: this.state.eng_img, thumbnail: this.state.eng_img }
                            arr.push(obj);
                            this.setState({ currentImageUrl: this.state.eng_img })
                        }
                        response.data.img_url?.map(val => {
                            var obj = { original: val.image_url, thumbnail: val.thumbnail_url }
                            arr.push(obj);
                        })
                    } else {
                        if (this.state.eng_img?.length) {
                            var obj = { original: this.state.eng_img, thumbnail: this.state.eng_img }
                            arr.push(obj);
                            this.setState({ currentImageUrl: this.state.eng_img })
                        }
                        var obj = { original: response.data.img_url[0].image_url, thumbnail: response.data.img_url[0].thumbnail_url }
                        arr.push(obj);
                    }
                    this.setState({ images: arr, addcartbutton: false })

                    if (this.state.ifMetalSelect) {
                        stone_data?.length > 0 ?
                            this.props.history.push('/productdetail/' + response.data.product_slug + "/" + this.props.match.params.sku + "/" + stone_data) :
                            this.props.history.push('/productdetail/' + response.data.product_slug + "/" + this.props.match.params.sku);
                    }
                    setTimeout(() => {
                        this.setState({
                            ifMetalSelect: false
                        })
                    }, 800);


                    this.similarProductApi();

                    let onLoadSelectedFilters = this.state.selectedFilter;
                    onLoadSelectedFilters.forEach((e) => {
                        if (e.title === 'Metal Color') {
                            e['selectedItems'] = response?.data?.metal_name;
                            e['image'] = response?.data?.metal_color_image;
                        } else {
                            response?.data?.stone_data.length > 0 && response?.data?.stone_data.forEach((elem) => {
                                if (e.title.replace(' ', '').toLowerCase() === elem.title) {
                                    e['selectedItems'] = elem?.data?.display_name;
                                    e['image'] = elem?.data?.Image;
                                }
                            })
                        }
                    })


                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    similarProductApi = () => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";

        getData(`/api/similarProducts?ProductType=${this.state.product_type}&Category=${this.state.category_slug ? this.state.category_slug : ""}&MetalColor=${this.state.MetalColor ? this.state.MetalColor : ""}&stone_data=${this.props.match.params.stone_data}&${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then((response) => {
                if (response.status == 1) {
                    this.setState({
                        similarproductdata: response.data.results,
                        loading2: true
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    filterApi = () => {

        let temp = [];
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        getData(`/api/productDetailFilter?SKU=${this.props.match.params.sku}&user_id=${user_id}&ProductSlug=${escape(this.props.match.params.slug)}`)
            .then((response) => {
                if (response.status == 1) {
                    response.data.forEach((elem) => {
                        temp.push({
                            title: elem.title
                        });
                    })

                    this.setState({
                        filterdata: response.data,
                        RingSize: response.data?.filter(val => val.title == "Selected Ring")[0]?.data > 0 ? response.data?.filter(val => val.title == "Selected Ring")[0]?.data : "",
                        selectedFilter: temp,

                    })

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    review = () => {
        getData(`/api/review/?product_id=${sessionStorage.getItem("review_pid")}`)
            .then((response) => {
                if (response.status == 1) {
                    this.setState({ reviewdata: response.data })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    componentDidMount() {
        if(window.location.href.includes("no-slug")){
            this.setState({
                redirection: true
            })
        }
        
        this.filterApi();
        this.productApi(this.state.MetalColor, this.props.match.params.stone_data);
        const script = document.createElement("script");

        script.src = "https://code.buywithprime.amazon.com/bwp.v1.js";
        script.async = true;

        document.body.appendChild(script);

        setTimeout(() => {
            window.bwp.updateWidget(this.state.productdata.product_sku);
        }, 2000);

    }
    componentDidUpdate(prevProps, prevState) {
        // if (prevState.MetalDescription != this.state.MetalDescription ||
        //     prevState.Stone1 != this.state.Stone1 ||
        //     prevState.Stone2 != this.state.Stone2 ||
        //     prevState.Stone3 != this.state.Stone3 ||
        //     prevState.RingSize != this.state.RingSize) {
        //     this.productApi();
        // }

        // if(prevState.RingSize != this.state.RingSize){
        //     bwpWidgetUpdate(this.state.productdata.product_sku);
        //     this.productApi();
        // }

        function bwpWidgetUpdate(selectedSkuValue) {
            //console.log("TEST_sku: " +selectedSkuValue);
            window.bwp.updateWidget(selectedSkuValue, 1, []);
        }


        if (prevState.productdata != this.state.productdata) {
            this.setState({ eng_data: [], eng_img: [] })
            this.review();
        }

        // if(  prevState.wishlistToggle != this.state.wishlistToggle){
        //    this.similarProductApi() 
        // }
        if (prevProps.match.params.slug != this.props.match.params.slug && prevProps.match.params.sku != this.props.match.params.sku || prevState.RingSize != this.state.RingSize) {
            //console.log("rrr",this.props.match.params.stone_data);

            this.productApi(this.state.MetalColor, this.props.match.params.stone_data);
            this.filterApi();
            setTimeout(() => {
                bwpWidgetUpdate(this.state.productdata.product_sku);
            }, 2000);

        }
        // if (prevState.engravingtext != this.state.engravingtext) {
        //     // this.letterTastic()
        //     this.circularText(this.state.engravingtext, 400, 0);
        // }
    }



    handlecheckChange = (e, title, item, img) => {
        this.setState({
            ifMetalSelect: true
        })

        let tempSelected = [...this.state.selectedFilter];
        tempSelected.forEach((elem) => {
            if (elem.title === title) {
                elem['selectedItems'] = item;
                elem['img'] = img;
            }
        })

        if (title == "Metal Color") {
            this.setState({ MetalDescription: item })
            this.productApi(item, this.state.stone_data);
        } else {
            var data_title = title?.split(" ");
            var data_index = Number(data_title[1]);
            var data = this.props.match.params.stone_data?.split("_");
            data[data_index - 1] = item;
            var final_data = Object.values(data)?.toString()?.replaceAll(",", "_")
            this.setState({ stone_data: final_data })
            this.productApi(this.state.MetalDescription, final_data);
        }
    }
    handleSelectChange = (e) => {
        this.setState({ RingSize: e.target.innerText, noRingsize: false, addcartbutton: false, activeRing: e.target.innerText })
        // this.setState({
        //     loading: false
        // });

        sessionStorage.setItem("ringSize", e.target.innerText)

    }
    checkboxcheck = (title, item) => {
        if (title == 'Metal Color') {
            if (this.state.MetalDescription == item) {
                return true;
            } else {
                return false;
            }
        } else {
            var data_title = title?.split(" ");
            var data_index = Number(data_title[1]);
            var data = this.props.match.params.stone_data?.split("_");
            if (data?.length && data[data_index - 1] == item) {
                return true;
            } else {
                return false;
            }
        }
    }
    setView = (event, index) => {
        this.setState({
            currentImageUrl: this.state.images[index].original
        });
    };
    opensimilar = (data) => {
        var stone_data = data.stone_data?.length ? Object.values(data.stone_data)?.toString()?.replaceAll(",", "_") : "";
        this.props.history.push('/productdetail/' + data.product_slug + "/" + data.variant + "/" + stone_data);
        window.scrollTo(0, 0)
    }
    imageLoaded = () => {
        setTimeout(() => {
            this.setState({
                loadingF: true
            });
        }, 1500);

    }
    imageLoaded2 = () => {
        this.setState({
            loading2: true
        });
    }
    imageLoaded3 = () => {
        this.setState({
            loading3: true
        });
    }
    incNum = () => {
        this.setState({ num: this.state.num + 1, addcartbutton: false });
    };
    decNum = () => {
        if (this.state.num > 1) {
            this.setState({ num: this.state.num - 1, addcartbutton: false });
        }
    };
    addmore = () => {
        this.setState({ fields: [...this.state.fields, { name: '', email: '' }] })
    }
    removefield = (i) => {
        this.setState({ fields: this.state.fields.filter((val, index) => index !== i) })
    }
    // add to cart
    AddCartProductEvent = (product_event) => {

        const gaProducts = {
            item_id: this.state.productdata?.product_id,
            item_name: this.state.productdata?.product_name,
            price: this.state.productdata?.sale_price
        };
        if (window.gtag) {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('event', 'add_to_cart', {
                event: 'add_to_cart',
                currency: "USD",
                value: this.state.productdata?.sale_price,
                ecommerce: {
                    items: this.state.productdata
                }
            });
        }
    };
    kleviyoTracking = (val) => {

        var item = {
            "url": window.location.href,
            "user_id": val?.user_id,
            "product_id": val?.product_id,
            "session_id": val?.session_id,
            "stone_data": val?.stone_data,
            "product_type": val?.product_type,
            "ring_size": val?.ring_size,
            "quantity": val?.quantity,
            "engraving": val?.engraving,
            "jewelry_box": val?.jewelry_box,
            "engraving_image": val?.engraving_image
        };
        if (window?.klaviyo) {
            window.klaviyo.push(["track", "Added to Cart", item]);
        }
    }
    addtocart = (i) => {

        this.state.filterdata.forEach((item) => {
            if (item.title == 'Ring Size') {
                if (item.data.length > 0 && this.state.activeRing === '') {
                    this.setState({
                        ringSizeValid: true
                    })
                    //toast.error('Please select ring size', { autoClose: 1700,closeOnClick: true });
                }
            }
        })

        if (this.state.productdata.category_slug === "Ring") {
            if (this.state.filterdata?.filter(val => val.title == "Ring Size" && val.data?.length > 0) && sessionStorage.getItem("ringSize") !== null) {
                // const session_id = localStorage.getItem('session_id') ? localStorage.getItem('session_id') : "",
                const data = {
                    user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
                    product_id: this.state.productdata.id,
                    session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
                    stone_data: this.props.match.params.stone_data,
                    product_type: this.state.product_type,
                    ring_size: sessionStorage.getItem("ringSize"),
                    quantity: this.state.num,
                    engraving: this.state.engravingVal,
                    jewelry_box: this.state.giftboxid,
                    engraving_image: this.state.eng_img
                }

                postData(`/order/cartItems`, data)
                    .then((response) => {

                        if (response.status == 1) {
                            this.kleviyoTracking(data)
                            this.AddCartProductEvent(i)
                            // toast.success(response.message, { autoClose: 3000 });
                            this.setState({ buttonLoading: false, addcartbutton: true })
                            localStorage.setItem("cartlength", response.data.cart_item_count);
                            sessionStorage.setItem("ringSize", null)
                            if (i == 1) {
                                this.props.history.push('/cart')
                            }
                            document.getElementById('cartTrigger').click();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else {

                this.setState({ noRingsize: true })
                setTimeout(() => { this.setState({ noRingsize: false }) }, 2000);
            }

        } else {
            const data = {
                user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
                product_id: this.state.productdata.id,
                session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
                stone_data: this.props.match.params.stone_data,
                product_type: this.state.product_type,
                ring_size: null,
                quantity: this.state.num,
                engraving: this.state.engravingVal,
                jewelry_box: this.state.giftboxid,
                engraving_image: this.state.eng_img
            }
            postData(`/order/cartItems`, data)
                .then((response) => {
                    if (response.status == 1) {
                        // toast.success(response.message, { autoClose: 3000 });
                        this.setState({ buttonLoading: false, addcartbutton: true })
                        localStorage.setItem("cartlength", response.data.cart_item_count);
                        sessionStorage.setItem("ringSize", null)
                        if (i == 1) {
                            this.props.history.push('/cart')
                        }
                        document.getElementById('cartTrigger').click();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        // if (this.state.filterdata?.filter(val => val.title == "Ring Size" && val.data?.length > 0) && sessionStorage.getItem("ringSize") !== null) {

        //     const data = {
        //         user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
        //         product_id: this.state.productdata.id,
        //         session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
        //         stone_data: this.props.match.params.stone_data,
        //         product_type: this.state.product_type,
        //         ring_size: sessionStorage.getItem("ringSize"),
        //         quantity: this.state.num,
        //         engraving: this.state.engravingVal,
        //         jewelry_box: this.state.giftboxid,
        //         engraving_image: this.state.eng_img
        //     }
        //     postData(`/order/cartItems`, data)
        //         .then((response) => {
        //             if (response.status == 1) {
        //                 // toast.success(response.message, { autoClose: 3000 });
        //                 this.setState({ buttonLoading: false, addcartbutton: true })
        //                 localStorage.setItem("cartlength", response.data.cart_item_count);
        //                 sessionStorage.setItem("ringSize", null)
        //                 if (i == 1) {
        //                     this.props.history.push('/cart')
        //                 }
        //                 document.getElementById('cartTrigger').click();
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        // } else {

        //     this.setState({ noRingsize: true })
        //     setTimeout(() => { this.setState({ noRingsize: false }) }, 2000);
        // }
    }



    // deleteitem = (id, wishindex,type) => {


    //     if(type==='single'){
    //         this.setState({
    //             singleWishlist: !this.state.singleWishlist 
    //         })
    //     }
    //     //console.log('dele',id?.wishlist_id);

    //     var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
    //       ? JSON.parse(localStorage.getItem("user"))?.user_id
    //       : "";
    //     var session_id = localStorage.getItem("session_id")
    //       ? localStorage.getItem("session_id")
    //       : "";
    //     deleteData(
    //       `/order/wishlist?wishlist_id=${Number(wishlistId)}&${
    //         user_id
    //           ? "user_id=" + user_id
    //           : session_id
    //           ? "session_id=" + session_id
    //           : ""
    //       }`
    //     )
    //       .then((response) => {
    //         if (response.status == 1) {
    //           localStorage.setItem(
    //             "wishlistlength",
    //             response.data.wishlist_item_count
    //           );
    //           const productlist = this.state.productdata.map((obj, index) =>
    //             index == wishindex ? Object.assign(obj, { wishlist_id: 0 }) : obj
    //           );
    //           this.setState({ productdata: productlist });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   };

    handleChangeimg = (e) => {
        if (e.target.files && e.target.files[0]) {
            this.setState({ review_img: e.target.files[0] })
            let reader = new FileReader();
            reader.onload = function (ev) {
                this.setState({ imageURI: ev.target.result });
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
        if (this.props.onChange !== undefined)
            this.props.onChange(e); // propagate to parent component
    }
    buildImgTag() {
        let imgTag = null;
        if (this.state.imageURI !== null)
            imgTag = (<div className="row">
                <div className="small-9 small-centered columns" style={{ border: "1px solid #ccc", width: "90px", height: "90px", margin: "12px 16px 0", position: "absolute", bottom: "31px", right: "41px" }}>
                    <img className="thumbnail" src={this.state.imageURI} style={{ width: "100%", height: "100%", objectFit: "cover" }}></img>
                </div>
            </div>);
        return imgTag;
    }

    sendreview = (e) => {
        e.preventDefault();
        if (this.state.rating == 0) {
            this.setState({ norating: true })
            setTimeout(() => { this.setState({ norating: false }) }, 2000);
        } else {
            let formData = new FormData();
            formData.append('user_id', JSON.parse(localStorage.getItem("user"))?.user_id);
            formData.append('product_id', this.state.productdata.id);
            formData.append('title', this.state.title);
            formData.append('rating', this.state.rating);
            formData.append('message', this.state.exp_text);
            formData.append('image', this.state.review_img);
            formData.append('url', window.location.href);
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`${base_url}/api/review/`, formData, config)
                .then(response => {
                    if (response.data.status == 1) {
                        toast.success(response.data.message, { autoClose: 3000 });
                    } else {
                        toast.error(response.data.message, { autoClose: 3000 });
                    }
                    this.setState({ title: "", rating: "", exp_text: "", review_img: "" })
                    this.setState({ show: false });
                    this.review();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            this.setState(prevState => ({
                panelExpand: [...prevState.panelExpand, panel]
            }));
        } else {
            var removeAction = this.state.panelExpand.filter((item) => item !== panel);
            this.setState(prevState => ({
                panelExpand: removeAction
            }));
        }
    };


    openDetail = (item) => {
        window.scrollTo(0, 0);
        var stone_data = item?.stone_data?.length ? Object.values(item?.stone_data)?.toString()?.replaceAll(",", "_") : "";
        this.props.history.push('/productdetail/' + item.product_slug + "/" + item.variant + "/" + stone_data);
    }

    // openRelated=(item)=>{
    //     window.scrollTo(0, 0);
    //     var stone_data = item?.stone_data?.length ? Object.values(item?.stone_data)?.toString()?.replaceAll(",", "_") : "";
    //     var dynamicUrl='/productdetail/' + item.product_slug + "/" + item.variant + "/" + stone_data;
    //     return dynamicUrl;
    // }
    kleviyoTrackingWishlist = (val) => {
        var item = {
            "url": window.location.href,
            "user_id": val?.user_id,
            "product_id": val?.product_id,
            "session_id": val?.session_id,
            "stone_data": val?.stone_data
        };
        if (window?.klaviyo) {
            window.klaviyo.push(["track", "Added to wishlist", item]);
        }
    }

    klaviyoViewedProduct=async(params)=> {
        var item = {
          "ProductName": params?.product_name,
          "ProductID": params?.id,
          "SKU": params?.product_sku,
          "Categories": params?.category_name,
          "ImageURL": params?.img_url[0]?.image_url,
          "URL": window.location.href,
          "Price": params?.sale_price,
        };
        if (window?.klaviyo) {
          await window.klaviyo.push(["track", "Viewed Product", item]);
        }
      }

    addtowishlist = (item, wishindex, type) => {
        if (item.product_type !== 1) {
            if (type === 'single') {
                var joinedString = item?.stone_data.length > 1 ? item?.stone_data.reduce(function (a, b) {
                    return (a.data?.name || a) + "_" + b.data?.name
                }) : item?.stone_data[0].data.name

            } else {
                joinedString = item?.stone_data?.length ? Object.values(item?.stone_data)?.toString()?.replaceAll(",", "_") : ""
            }

        }

        var data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            product_id: item.id,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            stone_data: item.product_type === 1 ? "" : joinedString
        }


        postData(`/order/wishlist`, data)
            .then((response) => {
                if (response.status == 1) {
                    this.kleviyoTrackingWishlist(data)
                    this.setState({
                        wishlistIndex: wishindex
                    })
                    localStorage.setItem("wishlistlength", response.data.wishlist_item_count)
                    if (type === 'single') {
                        var singleProduct = this.state.productdata;
                        singleProduct.wishlist_id = response.data.wishlist_id;

                        this.setState({ productdata: singleProduct })
                    } else {
                        const productlist = this.state.similarproductdata?.map(
                            (obj, index) => (index == wishindex ? Object.assign(obj, { wishlist_id: response.data.wishlist_id }) : obj)
                        )

                        this.setState({ similarproductdata: productlist })
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    deleteitem = (id, wishindex, type) => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
            ? JSON.parse(localStorage.getItem("user"))?.user_id
            : "";
        var session_id = localStorage.getItem("session_id")
            ? localStorage.getItem("session_id")
            : "";
        deleteData(
            `/order/wishlist?wishlist_id=${id?.wishlist_id}&${user_id
                ? "user_id=" + user_id
                : session_id
                    ? "session_id=" + session_id
                    : ""
            }`
        )
            .then((response) => {
                if (response.status == 1) {
                    localStorage.setItem(
                        "wishlistlength",
                        response.data.wishlist_item_count
                    );
                    if (type === 'single') {
                        var singleProduct = this.state.productdata;
                        singleProduct.wishlist_id = response.data.wishlist_id;
                        this.setState({ productdata: singleProduct })
                    } else {
                        const productlist = this.state.similarproductdata.map((obj, index) =>
                            index == wishindex ? Object.assign(obj, { wishlist_id: 0 }) : obj
                        );
                        this.setState({ productdata: productlist });
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // circularText = (txt, radius, classIndex) => {
    //     $('.circTxt').empty();
    //     txt = txt.split("");
    //     classIndex = document.getElementsByClassName("circTxt")[classIndex];

    //     var deg = 2,
    //         origin = -txt.length;
    //     var i = 100;
    //     txt.forEach((ea1) => {

    //         var ea = `<p style='height:${radius}px;position:absolute;transform:rotate(${origin}deg);transform-origin:0 ${i}%'>${ea1}</p>`;
    //         if (ea1 == "I") {
    //             i = 120;
    //         } else {
    //             i = 100;
    //         }
    //         classIndex.innerHTML += ea;
    //         origin += deg;
    //     });
    // }

    // letterTastic = () => {
    //     $('#name').empty();
    //     var text = this.state.engravingtext;
    //     var angleStep = 4,
    //         angleOffset = -angleStep * text.length * 0.45;
    //     for (var i = 0; i < text.length; ++i) {
    //         var angle = i * angleStep + angleOffset;
    //         $('#name').append("<span className='let letter" + i + "'>" + text[i] + "</span>");

    //         $('#name').children().last().css("transform", "rotate(" + angle + "deg)");
    //     }
    // }
    engravingfunc = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleEngravingChange = (index, event, font) => {
        const { engravingVal } = this.state;
        const updatedValues = [...engravingVal];
        updatedValues[index] = { engraving: event.target.value, font_style: font };
        this.setState({
            engravingVal: updatedValues,
        });
    };

    handlesavebtn = (data) => {
        var engravingdata = this.state.eng_data;
        engravingdata.push(data);
        var obj = { original: data.img, thumbnail: data.img }
        var arr = this.state.images;
        if (data.id > 1) {
            arr.shift()
        }
        arr.unshift(obj)
        this.setState({
            engraving: "",
            textstyle: "great_vibes",
            eng_data: engravingdata,
            eng_img: data.img,
            images: arr,
            currentImageUrl: data.img
        })
    }
    deleteengraving = (id) => {
        var engravingdata = this.state.eng_data;
        var newdata = engravingdata?.filter(val => val.id < id);
        if (newdata?.length && id != 1) {
            var obj = { original: newdata[newdata?.length - 1].img, thumbnail: newdata[newdata?.length - 1].img }
            var arr = this.state.images;
            arr.shift();
            arr.unshift(obj);
            this.setState({
                eng_data: newdata,
                eng_img: newdata[newdata?.length - 1].img,
                images: arr,
                currentImageUrl: newdata[newdata?.length - 1].img
            })
        } else {
            var arr = this.state.images;
            arr.shift();
            this.setState({
                eng_data: [],
                eng_img: "",
                images: arr,
                currentImageUrl: arr[0].original
            })

        }
    }



    render() {
        const imgdata = this.buildImgTag();
        var settings = {
            dots: false,
            infinite: false,
            autoplay: true,
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />,
            autoplaySpeed: 2000,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        initialSlide: 4
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const timestampToDate = (timestamp) => {
            const dateObject = new Date(timestamp);
            const month = dateObject.getMonth();
            let day = dateObject.getDate();
            const year = dateObject.getFullYear();
            const monthString = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
            if (day < 10) {
                day = `0${day} `;
            }
            return (`${day} ${monthString[month]} ${year}`);
        };
        const { classes } = this.props;
        const stoneimg = base_url + "/" + this.state.productdata?.image;
        var button = document.getElementById("loginbutton");
        const footerdata = JSON.parse(localStorage.getItem('footerdetails'))




        return (
            <>
                {
                     this.state.statusID == 0 && <Redirect to="/not-found" /> ||
                     this.state.redirection && <Redirect to="/" /> ||
                     this.state.productDetalMsg=="Partial Set Stone Missing" && <Redirect to="/404" />
                
                }

               
                <Helmet>
                    <title>{`${this.state.productdata.product_name ? this.state.productdata.product_name + " |" : ''}`} Gem Stone King ™</title>
                    <meta name="description" content={`${this.state.productdata?.product_discription}`} />
                    <meta property="og:site_name" content="Gem Stone King ™" />
                    <meta property="og:url" content="http://gsk.tasksource.in:8080/accessibility-statement" />
                    <meta property="og:title" content={`${this.state.productdata?.product_name}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={`${this.state.productdata?.product_discription}`} />
                </Helmet>

                <ToastContainer autoClose={500} limit={1} />
                <div className='breadCrumb blueBorderBottom'>
                    <Container className='gs_customer_container_fluid' fluid>
                        <nav aria-label="breadcrumb" className='breadCrumbWrapper'>
                            <ol className="breadcrumb bg-transparent">
                                <li className="breadcrumb-item">    <Link className='text-dark' to='/'>Home</Link></li>
                                <li className="breadcrumb-item active text-capitalize" aria-current="page">{this.props?.match?.params?.slug.replaceAll('-', ' ')}</li>
                            </ol>
                        </nav>
                        <div className="gs_product_top_heading">
                            <h1>{this.state.categoryName ? this.state.categoryName : <Skeleton variant="rect" animation="wave" width={100} height={26} />}</h1>
                            {/* <p className='f_roboto fs12'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad rerum officiis nesciunt molestiae inventore perferendis
                     </p> */}
                        </div>

                    </Container>
                </div>

                <div className="gs_product_details mb-0 pb-0">
                    <Container className="gs_customer_container">
                        <Row className="justify-content-between">
                            <Col xl={3} lg={3} md={12} sm={12} className="d-none d-lg-block gs_details_tablet1" >
                                {
                                    this.state.productdata.product_type === 1 && <span className='fs12 f_roboto'>Free Expedited Shipping (2-3 days)</span>
                                }

                                {
                                    this.state.productdata.product_type === 1 && <div className='mt-3 mb-3 wrapperContactInfo'>
                                        <div className='border-bottom d-block pb-3 mb-3'>
                                            <div>
                                                <p className="gs_esti f_roboto fs13"><MdOutlineLocalShipping size='18px' /> Delivery by {this.state.productdata.estimate_delivery}

                                                    <OverlayTrigger
                                                        delay={{ hide: 450, show: 300 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                Our daily cutoff times are Monday - Friday 5pm EST and Saturdays 3am EST. Any order placed within before these cutoff times we can ship the same day.
                                                            </Tooltip>
                                                        )}
                                                        placement="bottom"
                                                    >
                                                        <button className='noBtn ml-2'><BsQuestionCircle /> </button>
                                                    </OverlayTrigger>
                                                </p>


                                            </div>
                                            <div className='mr-3'>
                                                <img className='img-fluid' src={exchangeIcon} alt="" /> <span className='fs12 f_roboto'>Free Returns & Exchanges</span>
                                            </div>

                                        </div>

                                        <div className='fs11 pt-2'>
                                            <div className='text-uppercase mb-2 f_roboto'>Need Help?</div>
                                            <div className='mb-2'>
                                                <a className='text-dark mr-2' href="tel:+1 (877) 706-5333"><span className='blackIcon f_roboto'>{callIcon}</span> +1 (877) 706-5333</a>
                                            </div>


                                            <a href="mailto:support@gemstoneking.com" className='text-dark f_roboto'>
                                                <span className='mr-2'>{envelopIcon}</span> Email Us
                                            </a>
                                        </div>
                                    </div>
                                }
                                <div className="gs_accordion_section hidePlusIcon singleFilters">
                                    <div className={classes.root}>
                                        {this.state.product_type == "0" ? <>
                                            {/* <div className="gs_create_title">
                                                <h3 className="gs_top_tit" ><p>CUSTOMIZE YOUR </p> {this.state.productdata?.category_name} </h3>

                                            </div> */}
                                            {this.state.filterdata?.filter(val => val.title !== "Ring Size" && val.data?.length > 0).map((item, index) =>
                                                <Accordion defaultExpanded={true} expanded={this.state.panelExpand.includes('panel' + index)} onChange={this.handleChange('panel' + index)}>
                                                    <AccordionSummary
                                                        expandIcon={AngleBottom}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <h3 className="gs_accord_title2 f_roboto d-flex justify-content-between w-100">
                                                            <span className='d-flex fsFilter align-items-center'>
                                                                <span className='fs11 fsFilter text-uppercase'>{item.title}</span>

                                                                {
                                                                    this.state.selectedFilter.map(elem =>
                                                                        item.title === elem.title && elem?.selectedItems && <span className='fs11 ml-2 selItem d-flex align-items-center'> <span className='mr-1'>-</span>  <img className='img-fluid filterSelImg' src={elem?.image} alt={elem?.selectedItems} /> <span className='mx-1'>{elem?.selectedItems}</span></span>

                                                                    )
                                                                }
                                                            </span>

                                                            <span className='angleDown'>{AngleBottom}</span> </h3>
                                                    </AccordionSummary>
                                                    {item.data?.map((data, i) =>
                                                        <AccordionDetails className="gs_accordion_section1">
                                                            <Typography>
                                                                <FormGroup className="gs_acc_checkbox">
                                                                    <FormControlLabel control={<Checkbox checked={this.checkboxcheck(item.title, data.Value)} onChange={(e) => this.handlecheckChange(e, item.title, data.Value, data?.Image)} />} label={data.display_name} />
                                                                    <Image className="gs_filter_plcholder" src={data?.Image} alt=""></Image>
                                                                </FormGroup>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    )}
                                                </Accordion>
                                            )}
                                        </> :


                                            this.state.productdata.product_type === 0 && <div className="gs_create_title">
                                                <h1>{this.state.productdata.product_name}</h1>
                                                <p>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ").substring(0, 300)}.. </p>
                                            </div>


                                        }
                                        {/* </> : <h4 className="gs_show_filter" onClick={() => this.setState({ showFilter: true })}>Show Filter <ArrowDropDownIcon /></h4>} */}
                                    </div>
                                </div>
                            </Col>

                            <Col xl={5} lg={5} md={12} sm={12} className="gs_productdetails gs_details_tablet" >

                                {
                                    isMobile ?
                                        <>
                                            <Slider {...settings12}>
                                                {
                                                    this.state.images.map((e, i) => (
                                                        <div key={i}>
                                                            <img src={e.original} alt='slider' className='img-fluid' />
                                                        </div>
                                                    ))
                                                }
                                            </Slider>
                                            <p className='mt-4 mb-0 text-muted f_roboto fs12 text-center'>
                                                Note: Display is an approximate preview.
                                            </p>
                                        </>


                                        :

                                        this.state.currentImageUrl !== null ? <>
                                            {/* <h2>
                                                Heee
                                            </h2> */}
                                            <Image style={{ display: "none" }} onLoad={() => this.imageLoaded()} src={this.state.currentImageUrl} alt=""></Image>

                                            <div className="magnify-Image" >
                                                {isMobileOnly ?
                                                    <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={280} height={280} /> : isTablet ?
                                                        <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={300} height={300} /> : <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={400} height={400} />
                                                }
                                                {
                                                    this.state.loadingF && <SideBySideMagnifier
                                                        alwaysInPlace={false}
                                                        className="image-to-magnify"
                                                        fillAvailableSpace={false}
                                                        fillAlignTop={true}
                                                        // style={{ display: this.state.loading ? "block" : "none" }}
                                                        imageSrc={this.state.currentImageUrl}
                                                        largeImageSrc={this.state.currentImageUrl}
                                                    />
                                                }


                                                <br />
                                                {isMobileOnly ?
                                                    <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={100} height={100} /> : isTablet ?
                                                        <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={100} height={100} /> : <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={100} height={100} />
                                                }
                                                {this.state.loadingF ?
                                                    <>
                                                        <ImageGallery
                                                            items={this.state.images}
                                                            onThumbnailClick={this.setView}
                                                            showFullscreenButton={false}
                                                            showPlayButton={false}
                                                            showNav={true}
                                                        // style={{ display: this.state.loadingF ? "block" : "none" }}
                                                        //showBullets={true}
                                                        // disableThumbnailSwipe={true}
                                                        />
                                                    </>

                                                    : ""}
                                            </div> </> :
                                            isMobileOnly ?
                                                <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={280} height={280} /> : isTablet ?
                                                    <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={300} height={300} /> : <Skeleton style={{ display: this.state.loadingF ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={400} height={400} />


                                }

                                {
                                    (isMobile && this.state.product_type !== 1) && <div className='mt-2'>
                                        {this.state.filterdata?.filter(val => val.title !== "Ring Size" && val.data?.length > 0).map((item, index) =>
                                            <div className='filterWrapper mb-3'>
                                                <h3 className="gs_accord_title2 pb-1 d-flex"> <span className='text-red mr-1'>*</span> {item.title} :     {
                                                    this.state.selectedFilter.map(elem =>
                                                        item.title === elem.title && elem?.selectedItems && <span className='fs11 ml-2 selItem d-flex align-items-center'> <img className='img-fluid filterSelImg' src={elem?.image} alt={elem?.selectedItems} /> <span className='mx-1 text-capitalize'>{elem?.selectedItems}</span></span>

                                                    )
                                                }</h3>

                                                <div class="scrollmenu">
                                                    {
                                                        item?.data?.map((data, i) => (
                                                            <button className={`filterBtn ${this.state.activeBtn === data.Value.replaceAll(' ', '_') + i && 'activeStone'}`} onClick={(e) => this.handlecheckChange(e, item.title, data.Value)}>
                                                                <div>
                                                                    <Image className="btnImgFilter" src={data?.Image} alt=""></Image>
                                                                </div>
                                                                {data.Value}
                                                            </button>
                                                        )



                                                        )}
                                                </div>
                                            </div>



                                        )}
                                    </div>
                                }




                                {
                                    isMobile &&
                                    <div className="gs_product_details_info mt-4">

                                        {
                                            this.state.productdata.product_type === 1 && isMobile && <div className="">                                                 <h1 className='fs20 colorBlack fw400 mb-0'>{this.state.productdata.product_name}</h1>                                                 <p>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ").substring(0, 300)}</p>                                             </div>
                                        }

                                        {this.state.product_type == "0" ? <>
                                            <h1 className='text-dark f_roboto'>{this.state.productdata.product_name}</h1>
                                            {
                                                this.state.productdata.product_description && <p className='f_roboto'>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ")} </p>
                                            }

                                        </> : ""}

                                        {
                                            this.state.reviewdata?.total_review > 0 && <div className="gs_review_content_left">
                                                <div className="gs_review gs_review_top ">
                                                    {this.state.reviewdata?.total_review ? <>
                                                        <a href="#gs_rating">
                                                            <Box component="fieldset" mb={3} borderColor="transparent">
                                                                <div className='d-flex align-items-center'>
                                                                    <span className='fs11 font-weight-normal fw500 mr-1 text-dark'>4.6</span> <Rating name="read-only" precision={0.5} value={Number(this.state.reviewdata.avg_rating)} readOnly />   <span className='fs11 font-weight-normal fw500 ml-1 text-dark'><span className='arrowReview'>{AngleBottom}</span></span>
                                                                </div> </Box>
                                                        </a>
                                                        <span>({this.state.reviewdata?.total_review ? this.state.reviewdata?.total_review : 0})</span></> : ""}
                                                </div>
                                            </div>
                                        }



                                        <span className="gs_product_price f_roboto">{this.state.productdata.currency_icon}{this.state.productdata.sale_price}</span> {/* <span className="gs_product_cut_price">{this.state.productdata.currency_icon}{this.state.productdata.sale_price}</span> */}
                                        <div className="gs_detail_quantity">
                                            <InputGroup className="mb-1">
                                                <span className="mr-3 mt-2 gs_q_size f_roboto">Quantity </span>
                                                <Button variant="outline-secondary" className=" noBorder" id="button-addon1" onClick={this.decNum}>
                                                    <RemoveIcon />
                                                </Button>
                                                <input type="text" className='noBorder' value={this.state.num} disabled name="name" />
                                                <Button variant="outline-secondary" className=" noBorder" id="button-addon1" onClick={this.incNum}>
                                                    <AddIcon />
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        {this.state.filterdata?.filter(val => val.title == "Ring Size" && val.data?.length > 0).length ?
                                            <Row className="d-block">

                                                <div className="gs_ringsizing">

                                                    {/* <span className="mr-3 mt-2 gs_q_size">Ring Size </span> */}
                                                    {/* <p>Need Help? </p> */}

                                                    {/* <div className={this.state.noRingsize ? "gs_rsize_btn w-100 gs_appointment_input gs_details_ring_size animate__animated animate__shakeX gs_sel_sizerequired" : "gs_rsize_btn gs_appointment_input gs_details_ring_size"}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel className="gs_sel_required" id="demo-simple-select-outlined-label">Select Size</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={this.state.RingSize}
                                                            label="Select Size"
                                                            onChange={(e) => this.handleSelectChange(e)}
                                                            size="small"
                                                        >
                                                            {this.state.filterdata?.filter(val => val.title == "Ring Size").map(item =>
                                                                item.data?.map(val =>
                                                                    <MenuItem value={val.Value} >{val.Value}</MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                                                    <div className='ringSizeWrapper border-bottom border-top w-100 pt-2 pb-2 d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <span className="mr-3 mt-2 gs_q_size f_roboto">Size: </span>
                                                            <span onClick={() => this.setState({ ringSizeValid: false })}>


                                                                {this.state.filterdata?.filter(val => val.title == "Ring Size").map(item =>
                                                                    item.data?.map(val =>
                                                                        <span className={`pointer fs12 px-2 py-1 f_roboto ${this.state.activeRing === val.Value ? 'activeRing' : ''}`} onClick={(e) => this.handleSelectChange(e)}>{val.Value}</span>
                                                                    ))}
                                                            </span>
                                                        </div>

                                                        <div className='fs11'>
                                                            <a onClick={() => this.setState({ ringSizeModal: true })} href="javascript:void()" className='text-dark f_roboto' rel="noreferrer">Ring Size Guide</a>
                                                        </div>

                                                    </div>
                                                    <div className={`tooltip tooltipRs ${this.state.ringSizeValid && 'showTooltip'}`}>
                                                        <span class="tooltiptext"> Please select ring size</span>
                                                    </div>

                                                </div>

                                            </Row> : ""}



                                        {this.state.addcartbutton ? <Button onClick={() => this.props.history.push("/cart")} variant="outlined" className="gs_save_btn d-block  mt-1 f_roboto" >VIEW BAG </Button> : <button onClick={() => this.addtocart(0)} className="gs_save_btn1 rounded-0 w-100  mt-1 btn btn-dark f_roboto" >ADD TO BAG  </button>}
                                        {/* <Button variant="outlined" onClick={() => this.addtocart(1)} className="gs_addcart_btn mt-1" >BUY NOW </Button> */}




                                        {this.state.productdata?.is_engraving == 1 ?
                                            <Button className="gs_save_btn1 rounded-0 w-100 mt-3  mt-1 btn btn-dark f_roboto" onClick={() => this.setState({ engravingshow: !this.state.engravingshow })}>
                                                {this.state.engravingshow ? <> Free Engraving  </> : <> Free Engraving </>}
                                            </Button> : ""}

                                        {this.state.engravingshow ?
                                            <>
                                                {this.state.eng_popup ?
                                                    <Engraving image={this.state.images[0].original} handlesavebtn={this.handlesavebtn} textstyle={this.state.textstyle} engtext={this.state.engraving} handleClose={() => this.setState({ eng_popup: false })} eng_data={this.state.eng_data} /> : ""}
                                                <div className="gs_engraving">

                                                    <Row className="w-100 m-auto gs_engraving_input">
                                                        {this.state.eng_data?.map(val =>
                                                            <Col lg={12} className='gs_engraving_input_close_main p-0'>
                                                                <div>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        label={"Engraving" + val.id}
                                                                        type="text"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        autoComplete='off'
                                                                        value={val.engraving}
                                                                    />
                                                                </div>
                                                                <div className='gs_engraving_input_close_icn'>
                                                                    <MdDeleteForever onClick={() => this.deleteengraving(val.id)} />
                                                                </div>
                                                            </Col>)}

                                                    </Row>
                                                    {this.state.productdata?.engraving_data?.length > this.state.eng_data.length ?
                                                        <Row className="w-100 m-auto">
                                                            {
                                                                this.state.productdata?.engraving_data.map((e, i) => (
                                                                    <Col lg={12} className='p-0'>
                                                                        <TextField
                                                                            className='w-100'
                                                                            id="outlined-basic"
                                                                            label={"Enter engraving text"}
                                                                            type="text"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            autoComplete='off'
                                                                            name={`engraving${i}`}
                                                                            onBlurCapture={() => this.setState({ eng_color: true })}
                                                                            // value={this.state.engraving}
                                                                            onChange={(e) => this.engravingfunc(e)}
                                                                            inputProps={{
                                                                                maxLength: 8,
                                                                            }} />
                                                                    </Col>
                                                                ))
                                                            }

                                                            <Col className="p-0">
                                                                <div className="gs_engraving_text2 gs_engraving_text_new">
                                                                    <div className='gs_engraving_d1'>
                                                                        <ul>
                                                                            <li onClick={() => this.setState({ textstyle: 'great_vibes' })} className={this.state.textstyle == "great_vibes" ? "active" : ""}><span className="gs_great_vibes">Aa</span></li>
                                                                            <li onClick={() => this.setState({ textstyle: 'montserrat' })} className={this.state.textstyle == 'montserrat' ? "active" : ""}><span className="gs_montserrat">Aa</span></li>
                                                                        </ul>
                                                                    </div>

                                                                    <div className='gs_engraving_d2'>
                                                                        <Button
                                                                            className='gs_save_btn'
                                                                            variant="outlined"
                                                                            disabled={this.state.engraving == ""}
                                                                        //onClick={() => this.setState({ eng_popup: true })}
                                                                        >
                                                                            Set Engraving </Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row> : ""}
                                                </div> </> : ""}



                                        {/* <Button className="gs_save_btn gs_engraving_btn mt-3" onClick={() => this.setState({ giftbox: !this.state.giftbox })}>
                                        Gift Box
                                    </Button> */}

                                        {/* <li>  <NavLink to="#"> | </NavLink> </li>
                                            <li>  <NavLink to="#" onClick={() => this.setState({ appointment: true })}> <EventAvailableOutlinedIcon /> Appointment  </NavLink> </li> */}

                                        <div className="gs_social_media">

                                            <ul>
                                                <li>
                                                    {
                                                        (this.state.productdata.wishlist_id > 0) ?
                                                            <button onClick={() => this.deleteitem(this.state.productdata, 100, 'single')} variant="outlined"

                                                                className='noBtn singleWishList'>{HeartFilled}</button>
                                                            :
                                                            <button onClick={() => this.addtowishlist(this.state.productdata, 100, 'single')} variant="outlined"

                                                                className='noBtn singleWishList'>{HeartEmpty} </button>
                                                    }
                                                    {/* <button  onClick={() => this.addtowishlist(this.state.productdata,100,'single')} variant="outlined" 
                                   
                                         className='noBtn singleWishList'>{(this.state.productdata.wishlist_id > 0 || this.state.singleWishlist) ? HeartFilled  : HeartEmpty }</button> */}
                                                </li>
                                                <li>  <NavLink to="#" onClick={() => this.setState({ hint: true })}> <BsEnvelope size='15px' color='#3e3e3e' /> </NavLink> </li>
                                                <li>
                                                    <button className='noBtn' onClick={() => this.setState({ shareBtn: !this.state.shareBtn })}>{share}</button>

                                                </li>
                                                {
                                                    this.state.shareBtn &&
                                                    <>
                                                        <li>
                                                            <FacebookShareButton
                                                                url={String(window.location)}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                {fbIcon}
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li>
                                                            <PinterestShareButton
                                                                url={String(window.location)}
                                                                media={String(window.location)}
                                                                windowWidth={1000}
                                                                windowHeight={730}
                                                            >
                                                                {pinterestIcon}
                                                            </PinterestShareButton> </li>
                                                        {/* <li>
                                                <EmailShareButton subject={'Gemstoneking'} body={String(window.location)}>
                                                    <EmailIcon size={30} round />
                                                </EmailShareButton> </li> */}
                                                        <li>
                                                            <TwitterShareButton
                                                                title={"Gemstoneking"}
                                                                url={String(window.location)}
                                                            >
                                                                {twitterIcon}
                                                            </TwitterShareButton> </li>
                                                    </>

                                                }

                                            </ul>
                                        </div>



                                        {
                                            isMobile && <div className='bgGreyish f_roboto'>
                                                <p className='f_roboto fs14'> Free Expedited Shipping (2-3 days)</p>
                                                <p className="gs_esti f_roboto fs13 f_roboto ml-4 fw600"><MdOutlineLocalShipping size='18px' /> Delivery by {this.state.productdata.estimate_delivery} <BsQuestionCircle /></p>
                                                <p className='f_roboto fs14 mb-0'>
                                                    Free Returns & Exchanges
                                                </p>
                                                <div className='fs11 pt-2'>
                                                    <span className='text-uppercase mr-2 f_roboto fs14'>Need Help?</span><a className='text-dark mr-2 fs14' href="tel:+1 (877) 706-5333"><span className='blackIcon f_roboto'>{callIcon}</span> +1 (877) 706-5333</a>

                                                    <a href="mailto:support@gemstoneking.com" className='text-dark f_roboto fs14'>
                                                        <span className='mr-2'>{envelopIcon}</span> Email Us
                                                    </a>
                                                </div>
                                            </div>
                                        }

                                        {
                                            isMobile && <button className='noBtn mb-3 f_roboto fs13 d-flex align-items-center mt-3' onClick={() => this.setState({ moreInfo: !this.state.moreInfo })}>
                                                More <span className='angleHandle'>{this.state.moreInfo ? AngleTop : AngleBottom}</span>
                                            </button>

                                        }


                                        {
                                            isMobile &&
                                            this.state.moreInfo &&

                                            (this.state.product_type == 1 ?
                                                <div className="mj_product_specification mt-5">
                                                    <Row className="m-auto w-100">
                                                        <Col className="p-0">
                                                            <div className='gs_content_stone'>
                                                                <h2>PRODUCT DETAILS</h2>
                                                            </div>
                                                            <Row>
                                                                <Col sm={1} md={1} xs={12}></Col>
                                                                {this.state.productdata?.attributes?.map((val, index) => <>
                                                                    {isMobileOnly ? "" : index && index % 5 == 0 ? <><Col sm={1} md={1} xs={12}></Col><Col sm={1} md={1} xs={12}></Col></> : ""}
                                                                    <Col sm={3} md={2} xs={6}>
                                                                        <div className="gs_stonelist_content">
                                                                            <h5>{val.title}</h5>
                                                                            <p>{val.value}</p>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                <Row className="w-100 m-0">
                                                    <div className="w-100">
                                                        <div className="gs_stone_title">
                                                            {/* <Col sm={12} md={12} lg={5}>
                                                <div className="gs_stone_content_left">
                                                    {isMobileOnly ?
                                                        <Skeleton style={{ display: this.state.loading3 ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={100} height={100} /> : isTablet ?
                                                            <Skeleton style={{ display: this.state.loading3 ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={150} height={150} /> : <Skeleton style={{ display: this.state.loading3 ? "none" : "block" }} variant="rect" animation="wave" width={150} height={150} />
                                                    }
                                                    <Image style={{ display: this.state.loading3 ? "block" : "none" }} onLoad={() => this.imageLoaded3()} src={stoneimg} alt=""></Image>
                                                    <h2>{this.state.productdata?.name}</h2>
                                                    <p>{this.state.productdata?.descriptions}</p>
                                                </div>
                                            </Col> */}
                                                            <div className="gs_stone_content_right mb-3">
                                                                <div className="mb-0 f_roboto">
                                                                    {/* <h4>About your</h4>
                                                        <h2>{this.state.productdata?.category_name}</h2> */}
                                                                    <span className='fs13'>REFERENCE CODE: {this.state.productdata?.variant}</span>
                                                                </div>

                                                                <Row>

                                                                    {this.state.productdata?.stone_data?.map((val, ind) =>

                                                                        <React.Fragment key={ind}>

                                                                            <Col lg={12} className={`${val.title.includes('stone') && 'mt-3 mb-1'}`}>
                                                                                <h6 className='mb-0 pb-0  text-capitalize fs13'><strong>{val.title}</strong> </h6>

                                                                            </Col>

                                                                            <Col lg={4}>
                                                                                <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Type </h6>
                                                                            </Col>
                                                                            <Col lg={8}>
                                                                                <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{val.data.display_name}</p>
                                                                            </Col>


                                                                        </React.Fragment>




                                                                    )}


                                                                    <Col lg={4}>
                                                                        <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Metals </h6>
                                                                    </Col>
                                                                    <Col lg={8}>
                                                                        <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{this.state.productdata?.metal_name}</p>
                                                                    </Col>



                                                                    <Col lg={4}>
                                                                        <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Item Code </h6>
                                                                    </Col>
                                                                    <Col lg={8}>
                                                                        <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{this.state.productdata?.variant}</p>
                                                                    </Col>




                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>)
                                        }

                                        {
                                            !isMobile && <p className="gs_esti f_roboto fs13"><MdOutlineLocalShipping size='18px' /> Delivery by {this.state.productdata.estimate_delivery} <BsQuestionCircle /></p>
                                        }


                                        {this.state.product_type == "0" ? <>
                                            {
                                                this.state.productdata.product_description &&
                                                <>
                                                    <h6 className='f_roboto'>Description & Details</h6>
                                                    <p className='f_roboto'>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ")} </p>
                                                </>

                                            }

                                        </> : ""}
                                    </div>
                                }





                            </Col>

                            <Col xl={4} lg={4} md={12} sm={12} className='gs_details_tablet'>
                                {
                                    this.state.productdata.product_type === 1 && !isMobile && <div className="">                                                 <h1 className='fs20 colorBlack fw400 mb-0'>{this.state.productdata.product_name}</h1>                                                 <p>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ").substring(0, 300)}</p>                                             </div>
                                }

                                <div className="gs_product_details_info">
                                    {
                                        !isMobile &&
                                            this.state.product_type == "0" ? <>
                                            <h1 className='text-dark f_roboto'>{this.state.productdata.product_name}</h1>
                                            {
                                                this.state.productdata.product_description && <p className='f_roboto'>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ")} </p>
                                            }

                                        </> : ""}

                                    {
                                        !isMobile &&
                                        this.state.reviewdata?.total_review > 0 && <div className="gs_review_content_left">
                                            <div className="gs_review gs_review_top ">
                                                {this.state.reviewdata?.total_review ? <>
                                                    <a href="#gs_rating">
                                                        <Box component="fieldset" mb={3} borderColor="transparent">
                                                            <div className='d-flex align-items-center'>
                                                                <span className='fs13 font-weight-normal fw500 mr-1 text-dark'>4.6</span> <Rating name="read-only" precision={0.5} value={Number(this.state.reviewdata.avg_rating)} readOnly />   <span className='fs11 font-weight-normal fw500 ml-1 text-dark'><span className='arrowReview'>{AngleBottom}</span></span>
                                                            </div> </Box>
                                                    </a>
                                                    <span>({this.state.reviewdata?.total_review ? this.state.reviewdata?.total_review : 0})</span></> : ""}
                                            </div>
                                        </div>
                                    }

                                    {
                                        !isMobile &&
                                        <>
                                            <span className="gs_product_price f_roboto">{this.state.productdata.currency_icon}{this.state.productdata.sale_price}</span> {/* <span className="gs_product_cut_price">{this.state.productdata.currency_icon}{this.state.productdata.sale_price}</span> */}
                                            <div className="gs_detail_quantity">
                                                <InputGroup className="mb-1">
                                                    <span className="mr-3 mt-2 gs_q_size f_roboto">Quantity </span>
                                                    <Button variant="outline-secondary" className=" noBorder" id="button-addon1" onClick={this.decNum}>
                                                        <RemoveIcon />
                                                    </Button>
                                                    <input type="text" className='noBorder' value={this.state.num} disabled name="name" />
                                                    <Button variant="outline-secondary" className=" noBorder" id="button-addon1" onClick={this.incNum}>
                                                        <AddIcon />
                                                    </Button>
                                                </InputGroup>
                                            </div>
                                        </>

                                    }


                                    {
                                        !isMobile &&
                                            this.state.filterdata?.filter(val => val.title == "Ring Size" && val.data?.length > 0).length ?
                                            <Row className="d-block">

                                                <div className="gs_ringsizing">

                                                    {/* <span className="mr-3 mt-2 gs_q_size">Ring Size </span> */}
                                                    {/* <p>Need Help? </p> */}

                                                    {/* <div className={this.state.noRingsize ? "gs_rsize_btn w-100 gs_appointment_input gs_details_ring_size animate__animated animate__shakeX gs_sel_sizerequired" : "gs_rsize_btn gs_appointment_input gs_details_ring_size"}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel className="gs_sel_required" id="demo-simple-select-outlined-label">Select Size</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={this.state.RingSize}
                                                            label="Select Size"
                                                            onChange={(e) => this.handleSelectChange(e)}
                                                            size="small"
                                                        >
                                                            {this.state.filterdata?.filter(val => val.title == "Ring Size").map(item =>
                                                                item.data?.map(val =>
                                                                    <MenuItem value={val.Value} >{val.Value}</MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}

                                                    {
                                                        this.state.filterdata.length > 0 && <div className='ringSizeWrapper border-bottom border-top w-100 pt-2 pb-2 d-flex justify-content-between align-items-center'>
                                                            <div>
                                                                <span className="mr-3 mt-2 gs_q_size f_roboto">Size: </span>
                                                                <span onClick={() => this.setState({ ringSizeValid: false })}>


                                                                    {this.state.filterdata?.filter(val => val.title == "Ring Size").map(item =>
                                                                        item.data?.map(val =>
                                                                            <span className={`pointer fs12 px-2 py-1 f_roboto ${this.state.activeRing === val.Value ? 'activeRing' : ''}`} onClick={(e) => this.handleSelectChange(e)}>{val.Value}</span>
                                                                        ))}
                                                                </span>
                                                            </div>

                                                            <div className='fs11'>
                                                                <a onClick={() => this.setState({ ringSizeModal: true })} href="javascript:void()" className='text-dark f_roboto' rel="noreferrer">Ring Size Guide</a>
                                                            </div>

                                                        </div>
                                                    }


                                                    <div className={`tooltip tooltipRs ${this.state.ringSizeValid && 'showTooltip'}`}>
                                                        <span class="tooltiptext"> Please select ring size</span>
                                                    </div>
                                                </div>


                                            </Row> : ""}

                                    {!isMobile && this.state.addcartbutton ? <Button onClick={() => this.props.history.push("/cart")} variant="outlined" className="gs_save_btn d-block  mt-1 f_roboto" >VIEW BAG </Button> : !isMobile && <button onClick={() => this.addtocart(0)} className="gs_save_btn1 rounded-0 w-100  mt-1 btn btn-dark f_roboto" >ADD TO BAG</button>}

                                    <div id="amzn-buy-now" data-site-id="pcs9i7u1nf" data-widget-id="w-a2cGwNAmdZ55BJINXgivla" data-sku={this.state.productdata.variant} ></div>

                                    {/* <Button variant="outlined" onClick={() => this.addtocart(1)} className="gs_addcart_btn mt-1" >BUY NOW </Button> */}


                                    {!isMobile && this.state.productdata?.is_engraving == 1 ?
                                        <Button className="gs_save_btn1 rounded-0 w-100 mt-3  mt-1 btn btn-dark f_roboto" onClick={() => this.setState({ engravingshow: !this.state.engravingshow })}>
                                            {this.state.engravingshow ? <> Free Engraving  </> : <> Free Engraving </>}
                                        </Button> : ""}
                                    {this.state.engravingshow ?
                                        <>
                                            {this.state.eng_popup ?
                                                <Engraving image={this.state.images[0].original} handlesavebtn={this.handlesavebtn} textstyle={this.state.textstyle} engtext={this.state.engraving} handleClose={() => this.setState({ eng_popup: false })} eng_data={this.state.eng_data} /> : ""}
                                            <div className="gs_engraving">

                                                <Row className="w-100 m-auto gs_engraving_input">
                                                    {this.state.eng_data?.map(val =>
                                                        <Col lg={12} className='gs_engraving_input_close_main p-0'>
                                                            <div>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label={"Engraving" + val.id}
                                                                    type="text"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    autoComplete='off'
                                                                    value={val.engraving}
                                                                />
                                                            </div>
                                                            <div className='gs_engraving_input_close_icn'>
                                                                <MdDeleteForever onClick={() => this.deleteengraving(val.id)} />
                                                            </div>
                                                        </Col>)}

                                                </Row>

                                                {this.state.productdata?.engraving_data?.length > this.state.eng_data.length ?
                                                    <Row className="w-100 m-auto">

                                                        {
                                                            this.state.productdata?.engraving_data.map((e, i) => (
                                                                <Col lg={12} className='mb-3 p-0' key={i}>
                                                                    <TextField
                                                                        className='w-100'
                                                                        id="outlined-basic"
                                                                        label={"Enter engraving text"}
                                                                        type="text"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        autoComplete='off'
                                                                        name={`engraving${i}`}
                                                                        onBlurCapture={() => this.setState({ eng_color: true })}
                                                                        // value={this.state.engraving}
                                                                        onChange={(e) => this.handleEngravingChange(i, e, this.state.textstyle)}
                                                                        inputProps={{
                                                                            maxLength: 8,
                                                                        }} />
                                                                </Col>
                                                            ))
                                                        }

                                                        <Col className="p-0">
                                                            <div className="gs_engraving_text2 gs_engraving_text_new">
                                                                <div className='gs_engraving_d1'>
                                                                    <ul>
                                                                        <li onClick={() => this.setState({ textstyle: 'great_vibes' })} className={this.state.textstyle == "great_vibes" ? "active" : ""}><span className="gs_great_vibes">Aa</span></li>
                                                                        <li onClick={() => this.setState({ textstyle: 'montserrat' })} className={this.state.textstyle == 'montserrat' ? "active" : ""}><span className="gs_montserrat">Aa</span></li>
                                                                    </ul>
                                                                </div>

                                                                <div className='gs_engraving_d2'>
                                                                    <Button
                                                                        className='gs_save_btn'
                                                                        variant="outlined"
                                                                        disabled={this.state.engraving == ""}
                                                                    //onClick={() => this.setState({ eng_popup: true })}
                                                                    >
                                                                        Set Engraving </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    : ""}
                                            </div> </> : ""}



                                    {/* <Button className="gs_save_btn gs_engraving_btn mt-3" onClick={() => this.setState({ giftbox: !this.state.giftbox })}>
                                        Gift Box
                                    </Button> */}

                                    {/* <li>  <NavLink to="#"> | </NavLink> </li>
                                            <li>  <NavLink to="#" onClick={() => this.setState({ appointment: true })}> <EventAvailableOutlinedIcon /> Appointment  </NavLink> </li> */}



                                    {
                                        !isMobile && <div className="gs_social_media">
                                            <ul>
                                                <li>
                                                    {
                                                        (this.state.productdata.wishlist_id > 0) ?
                                                            <button onClick={() => this.deleteitem(this.state.productdata, 100, 'single')} variant="outlined"

                                                                className='noBtn singleWishList'>{HeartFilled}</button>
                                                            :
                                                            <button onClick={() => this.addtowishlist(this.state.productdata, 100, 'single')} variant="outlined"

                                                                className='noBtn singleWishList'>{HeartEmpty}</button>
                                                    }
                                                    {/* <button  onClick={() => this.addtowishlist(this.state.productdata,100,'single')} variant="outlined" 
                                
                                         className='noBtn singleWishList'>{(this.state.productdata.wishlist_id > 0 || this.state.singleWishlist) ? HeartFilled  : HeartEmpty }</button> */}
                                                </li>
                                                <li>  <NavLink to="#" onClick={() => this.setState({ hint: true })}> <BsEnvelope size='15px' color='#3e3e3e' /> </NavLink> </li>
                                                <li>
                                                    <button className='noBtn' onClick={() => this.setState({ shareBtn: !this.state.shareBtn })}>{share}</button>

                                                </li>
                                                {
                                                    this.state.shareBtn &&
                                                    <>
                                                        <li>
                                                            <FacebookShareButton
                                                                url={String(window.location)}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                {fbIcon}
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li>
                                                            <PinterestShareButton
                                                                url={String(window.location)}
                                                                media={String(window.location)}
                                                                windowWidth={1000}
                                                                windowHeight={730}
                                                            >
                                                                {pinterestIcon}
                                                            </PinterestShareButton> </li>
                                                        {/* <li>
                                                <EmailShareButton subject={'Gemstoneking'} body={String(window.location)}>
                                                    <EmailIcon size={30} round />
                                                </EmailShareButton> </li> */}
                                                        <li>
                                                            <TwitterShareButton
                                                                title={"Gemstoneking"}
                                                                url={String(window.location)}
                                                            >
                                                                {twitterIcon}
                                                            </TwitterShareButton> </li>
                                                    </>

                                                }

                                            </ul>
                                        </div>
                                    }


                                    {
                                        !isMobile && this.state.productdata.product_type === 0 && <p className="gs_esti f_roboto fs13"><MdOutlineLocalShipping size='18px' /> Delivery by {this.state.productdata.estimate_delivery}

                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        Our daily cutoff times are Monday - Friday 5pm EST and Saturdays 3am EST. Any order placed within before these cutoff times we can ship the same day.
                                                    </Tooltip>
                                                )}
                                                placement="bottom"
                                            >
                                                <button className='noBtn ml-2'><BsQuestionCircle /> </button>
                                            </OverlayTrigger>
                                        </p>
                                    }

                                    {
                                        !isMobile && <div className='mt-3 mb-3 wrapperContactInfo'>
                                            <div className='border-bottom d-flex pb-2'>
                                                <div className='mr-3'>
                                                    <img className='img-fluid' src={exchangeIcon} alt="" /> <span className='fs12 f_roboto'>Free Returns & Exchanges</span>
                                                </div>
                                                <div>
                                                    <MdOutlineLocalShipping size='18px' />   <span className='fs12 f_roboto'>Free Expedited Shipping (2-3 days)</span>
                                                </div>
                                            </div>

                                            <div className='fs11 pt-2'>
                                                <span className='text-uppercase mr-2 f_roboto'>Need Help?</span><a className='text-dark mr-2' href="tel:+1 (877) 706-5333"><span className='blackIcon f_roboto'>{callIcon}</span> +1 (877) 706-5333</a>

                                                <a href="mailto:support@gemstoneking.com" className='text-dark f_roboto'>
                                                    <span className='mr-2'>{envelopIcon}</span> Email Us
                                                </a>
                                            </div>
                                        </div>
                                    }


                                    {this.state.product_type == "0" ? <>
                                        {
                                            this.state.productdata.product_description &&
                                            <>
                                                <h6 className='f_roboto'>Description & Details</h6>
                                                <p className='f_roboto'>{(this.state.productdata.product_description)?.replaceAll("<b>", " ").replaceAll("</b>", " ").replaceAll("<br>", " ")} </p>
                                            </>

                                        }

                                    </> : ""}
                                </div>
                                {
                                    !isMobile && <button className='noBtn mb-3 f_roboto fs13 d-flex align-items-center' onClick={() => this.setState({ moreInfo: !this.state.moreInfo })}>
                                        More <span className='angleHandle'>{this.state.moreInfo ? AngleTop : AngleBottom}</span>
                                    </button>

                                }


                                {
                                    !isMobile &&
                                    this.state.moreInfo &&

                                    (this.state.product_type == 1 ?
                                        <div className="mj_product_specification mt-5">
                                            <Row className="m-auto w-100">
                                                <Col className="p-0">
                                                    <div className='gs_content_stone'>
                                                        <h2>PRODUCT DETAILS</h2>
                                                    </div>
                                                    <Row>
                                                        <Col sm={1} md={1} xs={12}></Col>
                                                        {this.state.productdata?.attributes?.map((val, index) => <>
                                                            {isMobileOnly ? "" : index && index % 5 == 0 ? <><Col sm={1} md={1} xs={12}></Col><Col sm={1} md={1} xs={12}></Col></> : ""}
                                                            <Col sm={3} md={2} xs={6}>
                                                                <div className="gs_stonelist_content">
                                                                    <h5>{val.title}</h5>
                                                                    <p>{val.value}</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <Row className="w-100 m-0">
                                            <div className="w-100">
                                                <div className="gs_stone_title">
                                                    {/* <Col sm={12} md={12} lg={5}>
                                                <div className="gs_stone_content_left">
                                                    {isMobileOnly ?
                                                        <Skeleton style={{ display: this.state.loading3 ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={100} height={100} /> : isTablet ?
                                                            <Skeleton style={{ display: this.state.loading3 ? "none" : "block", margin: "0 auto" }} variant="rect" animation="wave" width={150} height={150} /> : <Skeleton style={{ display: this.state.loading3 ? "none" : "block" }} variant="rect" animation="wave" width={150} height={150} />
                                                    }
                                                    <Image style={{ display: this.state.loading3 ? "block" : "none" }} onLoad={() => this.imageLoaded3()} src={stoneimg} alt=""></Image>
                                                    <h2>{this.state.productdata?.name}</h2>
                                                    <p>{this.state.productdata?.descriptions}</p>
                                                </div>
                                            </Col> */}
                                                    <div className="gs_stone_content_right">
                                                        <div className="mb-0 f_roboto">
                                                            {/* <h4>About your</h4>
                                                        <h2>{this.state.productdata?.category_name}</h2> */}
                                                            <span className='fs13'>REFERENCE CODE: {this.state.productdata?.variant}</span>
                                                        </div>

                                                        <Row>

                                                            {this.state.productdata?.stone_data?.map((val, ind) =>

                                                                <React.Fragment key={ind}>

                                                                    <Col lg={12} className={`${val.title.includes('stone') && 'mt-3 mb-1'}`}>
                                                                        <h6 className='mb-0 pb-0  text-capitalize fs13'><strong>{val.title}</strong> </h6>

                                                                    </Col>

                                                                    <Col lg={4}>
                                                                        <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Type </h6>
                                                                    </Col>
                                                                    <Col lg={8}>
                                                                        <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{val.data.display_name}</p>
                                                                    </Col>


                                                                </React.Fragment>




                                                            )}


                                                            <Col lg={4}>
                                                                <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Metals </h6>
                                                            </Col>
                                                            <Col lg={8}>
                                                                <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{this.state.productdata?.metal_name}</p>
                                                            </Col>



                                                            <Col lg={4}>
                                                                <h6 className='mb-0 pb-0 fw400 text-capitalize f_roboto fs13'>Item Code </h6>
                                                            </Col>
                                                            <Col lg={8}>
                                                                <p className='mb-0 pb-0 f_roboto fs13 mx-3'>{this.state.productdata?.variant}</p>
                                                            </Col>




                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>)
                                }

                            </Col>

                            {/* <h5>Clarity</h5>
                            <p>{val.data?.clarity}</p>
                            <h5>Size</h5>
                            <p>{val.data?.measurements}</p>
                            <h5>Weight</h5>
                            <p>{val.data?.carat}</p>
                            <h5>Total</h5>
                            <p>{val.data?.nos}</p>  */}
                        </Row>

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} >
                                <div className="gs_similar_prod">
                                    <h2 className="text-center mb-lg-5">You Might Also Like</h2>

                                    <Slider {...settings}>
                                        {this.state.similarproductdata && this.state?.similarproductdata?.map((data, index) =>
                                            <div className='youMayLike' key={index}>

                                                <div className="gsk_product_box_view">
                                                    <div className='gsk_product_heart_btn'>
                                                        <div className="flex">
                                                            {data.product_type == 0 && <Button className="gsk_customize" onClick={() => this.openDetail(data)}> Customize </Button>}
                                                            {/* 
<Button className="gsk_heart" onClick={() => this.addtowishlist(data, index+1,'loop')}>
data?.wishlist_id > 0 || 
{
(this.state.wishlistIndex==index+1 || data?.wishlist_id > 0) ? <AiTwotoneHeart size='21px' color="#F765A3"/>
 :  <BsHeart color="#F765A3"/>
}

</Button> */}


                                                            {
                                                                (data?.wishlist_id > 0) ?

                                                                    <Button
                                                                        className="gsk_heart"
                                                                        onClick={() =>
                                                                            this.deleteitem(data, index, 'loop')
                                                                        }
                                                                    >
                                                                        <AiTwotoneHeart color="#F765A3" size='21px' />

                                                                    </Button> : <Button
                                                                        className="gsk_heart"
                                                                        onClick={() =>
                                                                            this.addtowishlist(data, index, 'loop')
                                                                        }
                                                                    >
                                                                        <BsHeart color="#F765A3" />
                                                                    </Button>
                                                            }

                                                        </div>
                                                    </div>

                                                    <div onMouseOver={() => this.setState({ hoverpb: index })} className="gsk_product_box_view_img" >
                                                        <Link
                                                            to="#" onClick={() => this.openDetail(data)} onMouseOver={() => this.setState({ proIndex: index + 1 })} onMouseLeave={() => this.setState({ proIndex: '' })} >
                                                            <div className="hm_best_seller"></div>
                                                            {data.product_image?.length > 1 ?
                                                                <>
                                                                    {this.state.proIndex == index + 1 ?
                                                                        <Image
                                                                            //style={{ display: this.state.loading ? "block" : "none" }}
                                                                            onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image> :
                                                                        <Image
                                                                            //style={{ display: this.state.loading ? "block" : "none" }}
                                                                            onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                                    }
                                                                </>
                                                                :
                                                                <Image
                                                                    //style={{ display: this.state.loading ? "block" : "none" }}
                                                                    onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                            }

                                                        </Link>
                                                    </div>

                                                    <div className='gsk_product_box_view_text d_font'  >
                                                        <h3>
                                                            {data.product_name?.substring(0, 50)}
                                                        </h3>
                                                        {
                                                            data.sale_price && <p>{data.currency_icon}{data.sale_price}</p>
                                                        }

                                                        <Button className="gsk_product_addToBag_btn" onClick={() => this.openDetail(data)}> Add to bag</Button>
                                                    </div>


                                                </div>
                                            </div>
                                        )}


                                    </Slider>

                                    {/* <Slider {...settings}>

                                        {this.state.similarproductdata && this.state.similarproductdata.results?.map((data,index) =>

                                            <div className='youMayLike'>
                                           
                                            <div  className="gsk_product_box_view">
                <div className='gsk_product_heart_btn'>
                  <div className="flex">
                    {data.product_type==0 && <Button className="gsk_customize" onClick={() => this.openDetail(data)}> Customize </Button>}
                    
                    <Button className="gsk_heart" onClick={() => this.addtowishlist(data, index,'loop')}>
                    {
                    this.state.wishlistIndex==index ? <AiTwotoneHeart size='21px' color="#F765A3"/> :  <BsHeart color="#F765A3"/>
                    }
                  
                    </Button>

                  
                  </div>
                </div>

                <div onMouseOver={()=>this.setState({hoverpb:index})} className="gsk_product_box_view_img" >
             

                  <Link   
                to="#"  onClick={() => this.openDetail(data)}                     onMouseOver={()=> this.setState({proIndex: index + 1})} onMouseLeave={()=> this.setState({proIndex: ''})} >
                  <div className="hm_best_seller"></div>
                                                                        {data.product_image?.length > 1 ?
                                                                            <>
                                                                                {this.state.proIndex == index + 1 ?
                                                                                    <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image> :
                                                                                    <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                                        }
                                                                        
                                                                    </Link>
                </div>

                <div className='gsk_product_box_view_text d_font'  >
                  <h3>
                  {data.product_name?.substring(0, 50)}
                  </h3>
                  {
                    data.sale_price && <p>{data.currency_icon}{data.sale_price}</p>
                  }
              
                  <Button className="gsk_product_addToBag_btn" onClick={() => this.openDetail(data)}> Add to bag</Button>
                </div>

              
              </div>
                                            </div>
                                          
                                        )}
                             
    </Slider> */}

                                    {/* <div className="gs_you_might_slider d-block d-md-block ">
                                        <Slider {...settings}>
                                        <div className=" d-lg-block">
                                        {this.state.similarproductdata && this.state.similarproductdata.results?.map((data,index) =>

                                            <div>
                                            <div onMouseLeave={()=>this.setState({hoverpb:index})} className="gsk_product_box_view">
                <div className={this.state.hoverpb == index ? "gsk_product_heart_btn d-block" : "gsk_product_heart_btn d-none"}>
                  <div className="flex">
                    <Button className="gsk_customize" onClick={() => this.openDetail(data)}> Customize </Button>
                    <Button className="gsk_heart" onClick={() => this.addtowishlist(data, index)}>
                       
                      <BsHeart /> 
                    </Button>
                  </div>
                </div>

                <div onMouseOver={()=>this.setState({hoverpb:index})} className="gsk_product_box_view_img" >
             

                  <div onMouseOver={()=> this.setState({proIndex: index + 1})} onMouseLeave={()=> this.setState({proIndex: ''})} >
                                                                        {data.product_image?.length > 1 ?
                                                                            <>
                                                                                {this.state.proIndex == index + 1 ?
                                                                                    <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image> :
                                                                                    <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={data.img_url} alt=""></Image>
                                                                        }
                                                                    </div>
                </div>

                <div className={this.state.hoverpb == index ? "gsk_product_box_view_text d_font d-block" :"gsk_product_box_view_text d_font d-none"}  >
                  <h3>
                  {data.product_name?.substring(0, 50)}
                  </h3>
                  <p>{data.currency_icon}{data.sale_price}</p>
                  <Button className="gsk_product_addToBag_btn" onClick={() => this.openDetail(data)}> Add to bag</Button>
                </div>
              </div>
                                            </div>
                                          
                                        )}
                                    </div>

                                     
                                        </Slider>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                        {/* <div className="gs_consult_banner text-center" style={{ backgroundImage: `url(${adviceImage})` }}>
                            <div className="gs_consult_content">
                                <p> EXPERT ADVICE FROM OUR </p>
                                <h3> Jewelry Consultants</h3>
                                <span> Let the experts help you make the best decisions! <br />
                                    Our Jewelry Consultants assist you throughout your buying journey by giving you honest and genuine insights.
                                </span>
                                <Row>
                                    <Col sm={6}>
                                        <NavLink to="#" className="gs_num" onClick={(e) => { window.location.href = `tel:${footerdata?.footerinfo[0].mobile_no}` }} > {footerdata?.footerinfo[0].mobile_no} </NavLink>
                                    </Col>
                                    <Col sm={6}>
                                        <NavLink to="/pages/contact-us" className="gs_msg" onClick={() => window.scrollTo(0, 0)}> SEND A MESSAGE  </NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NavLink to="/pages/faqs" className="gs_view_faq" onClick={() => window.scrollTo(0, 0)}> View FAQ <ChevronRightIcon />  </NavLink>
                                    </Col>
                                </Row>
                            </div>
                        </div> */}

                        <Row className="w-100 m-0 mt-5" id="gs_rating">
                            <div className="gs_review_section w-100" >
                                <div className="gs_review_title">

                                    <Row>
                                        <Col sm={6}>
                                            <div className="gs_review_content_left">
                                                <div className="gs_review">
                                                    <h4>REVIEWS ({this.state.reviewdata?.total_review ? this.state.reviewdata?.total_review : 0})</h4>
                                                    <Box component="fieldset" mb={3} borderColor="transparent">
                                                        <Rating name="read-only" precision={0.5} value={Number(this.state.reviewdata.avg_rating)} readOnly />
                                                    </Box>
                                                    <span>{(this.state.reviewdata.avg_rating)?.toFixed(1)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="gs_review_content_right">
                                                <p className='f_roboto'>We want to hear from you.</p>
                                                {/* {!isLogin ? <Button variant="contained" onClick={()=> button.click()}  className="gs_review_btn">Login</Button> : */}
                                                <Button variant="contained" onClick={() => isLogin ? this.setState({ show: true }) : button?.click()} className="gs_review_btn">Write A Review</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="gs_user_review">
                                {this.state.reviewdata?.review?.slice(0, 4).map(val =>
                                    <Row>
                                        <Col sm={4} md={3}>
                                            <div className="gs_user_review_left">
                                                <Box component="fieldset" mb={3} borderColor="transparent">
                                                    <Rating name="read-only" value={val.rating} readOnly />
                                                </Box>
                                                <h2>{val.user.first_name + " " + val.user.last_name}</h2>
                                                <p>{timestampToDate(val.created_date)}</p>
                                            </div>
                                        </Col>
                                        <Col sm={8} md={9}>
                                            <div className="gs_user_review_right">
                                                <h2>{val.title}</h2>
                                                <p>{val.message}</p>
                                                {val.image == null ? "" :
                                                    <div className="gs_user_review_img">
                                                        <Image src={base_url + val.image} alt="review image"></Image>
                                                    </div>}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </Row>

                        <div className='mt-5'>
                            <GskDifference />
                        </div>

                    </Container>
                </div>

                <Modal show={this.state.show} onHide={() => this.setState({ show: false })} animation={false} className="gs_login_modal" centered aria-labelledby="contained-modal-title-vcenter">
                    <form onSubmit={this.sendreview}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="gs_review_form gs_appointment_input gs_login">
                                <Modal.Title id="contained-modal-title-vcenter">Add Review </Modal.Title>
                                <TextField
                                    id="outlined-basic"
                                    label="Title of Review"
                                    type="text"
                                    value={this.state.title}
                                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                                    variant="outlined"
                                    required />
                                <TextField
                                    id="outlined-basic"
                                    label="How was your overall experience ?"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={this.state.exp_text}
                                    onChange={(e) => { this.setState({ exp_text: e.target.value }) }}
                                    variant="outlined"
                                    required
                                />
                                <Box component="fieldset" mb={3} borderColor="transparent">
                                    <Typography className={this.state.norating ? "gs_rsize_btn gs_appointment_input gs_details_ring_size gs_sel_sizerequired animate__animated animate__shakeX " : "gs_rsize_btn gs_appointment_input gs_details_ring_size"} component="legend">Rating</Typography>
                                    <Rating
                                        name="simple-controlled"
                                        value={this.state.rating}
                                        type="rating"
                                        onChange={(event, newValue) => {
                                            this.setState({ rating: newValue })
                                        }}
                                        required
                                    />
                                </Box>
                                <div className="gs_upload_review_img">
                                    <input
                                        accept="image/*"
                                        className="form-control"
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={this.handleChangeimg}
                                    />
                                    <label htmlFor="contained-button-file">
                                        Image Upload
                                        {/* <Button variant="contained" color="default"
                                            startIcon={<CloudUploadIcon />} component="span">
                                           
                                        </Button> */}
                                    </label>
                                    {imgdata}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="contained" className="gs_review_btn" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/* <Modal className="gs_login_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered className="gs_appointment_modal" show={this.state.appointment} onHide={() => this.setState({ appointment: false })}>
                    <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="gs_appointment_input">
                            <Modal.Title>SCHEDULE AN APPOINTMENT</Modal.Title>
                            <p>Please use the form below or call us at <NavLink to="#" onClick={(e) => { window.location.href = `tel:${footerdata?.footerinfo[0].mobile_no}` }} > {footerdata?.footerinfo[0].mobile_no} </NavLink> to schedule your personalized visit with a jewelry specialist.</p>
                            <Form>
                                <Row>
                                    <Col xs={12} sm={6} >
                                        <TextField
                                            id="outlined-basic"
                                            label="First Name"
                                            type="text"
                                            variant="outlined"
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Last Name"
                                            type="text"
                                            variant="outlined"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            type="Email"
                                            variant="outlined"
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Phone Number"
                                            type="number"
                                            variant="outlined"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="date"
                                            label="Date"
                                            type="date"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                            inputProps={{
                                                min: this.state.date
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="time"
                                            label="Time"
                                            type="time"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            id="outlined-basic"
                                            label="Comment"
                                            type="text"
                                            multiline
                                            rows={2}
                                            variant="outlined"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Modal.Footer>
                                    <Button className="gs_fill_button" type="submit">
                                        Submit
                                    </Button>
                                    <Button className="gs_outline_button1">
                                        Reset
                                    </Button>
                                    <Button className="gs_fill_light_button1" onClick={() => this.setState({ appointment: false })}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal> */}
                <Modal className="gs_login_modal gs_appointment_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.state.hint} onHide={() => this.setState({ hint: false })}>
                    <Modal.Header className="gs_modal_header" closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="gs_appointment_input">
                            <Modal.Title className="mb-5">DROP A HINT</Modal.Title>
                            <Form onSubmit={this.hintsubmit}>
                                {this.state.fields.map((val, index) =>
                                    <Row>
                                        <Col xs={12} sm={5} >
                                            <TextField
                                                id="outlined-basic"
                                                label="Friend's Name"
                                                type="text"
                                                variant="outlined"
                                                value={val.name}
                                                onChange={(e) => this.setState(prevState => ({
                                                    fields: prevState.fields.map(
                                                        (obj, i) => (i === index ? Object.assign(obj, { name: e.target.value }) : obj)
                                                    )
                                                }))}
                                                required={index == 0 ? true : false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Friend's Email"
                                                type="email"
                                                value={val.email}
                                                variant="outlined"
                                                onChange={(e) => this.setState(prevState => ({
                                                    fields: prevState.fields.map(
                                                        (obj, i) => (i === index ? Object.assign(obj, { email: e.target.value }) : obj)
                                                    )
                                                }))}
                                                required={index == 0 ? true : false}
                                            />
                                        </Col>
                                        {index == 0 ?
                                            <Col xs={12} sm={2}>
                                                <IconButton color="primary" aria-label="add more field" component="span" disabled={this.state.fields.length == 2 ? true : false} onClick={() => this.addmore()}>
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </Col> : ""}
                                        {index > 0 ?
                                            <Col xs={12} sm={2}>
                                                <IconButton color="primary" aria-label="remove field" component="span" onClick={() => this.removefield(index)}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </Col> : ""}
                                    </Row>
                                )}
                                <Row>
                                    <Col>
                                        <TextField
                                            id="outlined-basic"
                                            label="Comment"
                                            type="text"
                                            multiline
                                            rows={2}
                                            variant="outlined"
                                            onChange={(e) => this.setState({ hintcomment: e.target.value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Your Name"
                                            type="text"
                                            variant="outlined"
                                            onChange={(e) => this.setState({ hintname: e.target.value })}
                                            required
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Your Email"
                                            type="Email"
                                            variant="outlined"
                                            onChange={(e) => this.setState({ hintemail: e.target.value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                {/* <Row className="gs_check_condition m-0">
                                    <Col>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.offers}
                                                    onChange={() => this.setState({ offers: true })}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                        />
                                        <span className="gs_condition">Sign up for special offers</span>
                                    </Col>
                                </Row> */}
                                <Modal.Footer>
                                    <Button type="submit" className="gs_fill_button1">
                                        Send
                                    </Button>
                                    <Button className="gs_fill_light_button1" onClick={() => this.setState({ hint: false, hintname: "", hintemail: "", hintcomment: "", fields: [{ name: "", email: "" }] })}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal.Body>

                </Modal>
                {/* Gift box  */}
                <Modal className="gs_login_modal gs_quick_view_popup gs_appointment_modal gs_quick_view_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.state.giftbox} onHide={() => { this.setState({ giftbox: false }); }}>
                    <Modal.Header className="gs_modal_header gs_mod_pd0" closeButton> <h3> Select Gift Box</h3>   </Modal.Header>
                    <Modal.Body>

                        <div className="gs_gift_bx">
                            <div className="gs_ring_box">
                                <Row>



                                    <Col sm={6}>
                                        <div className="gs_ringbox text-center" onClick={() => this.setState({ giftboxid: 0 })}>
                                            {this.state.giftboxid ? "" : <CheckCircleIcon />}
                                            <img src="https://assets.jewlr.com/images/product/photo_box/ring_box_classic.jpg" />
                                            <h6> PREMIUM RING BOX -{this.state.productdata?.currency_icon}0 </h6>
                                            <p> An elegant jewelry box with a
                                                luxury matte black finish. </p>
                                        </div>


                                    </Col>

                                    <Col sm={6}>

                                        <div className="gs_ringbox text-center" onClick={() => this.setState({ giftboxid: 1 })}>
                                            {this.state.giftboxid ? <CheckCircleIcon /> : ""}
                                            <img src="https://assets.jewlr.com/images/product/photo_box/pendantbox_mockup.jpg" alt='' />
                                            <h6> PREMIUM RING BOX - {this.state.productdata?.currency_icon}{this.state.productdata?.ring_box_price} </h6>
                                            <p> An elegant jewelry box with a
                                                luxury matte black finish. </p>
                                        </div>

                                    </Col>



                                </Row>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className='ringSizeModal' show={this.state.ringSizeModal} onHide={() => this.setState({ ringSizeModal: false })} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body><img className='img-fluid' src={require("../../assets/images/ring-size-guide.webp").default} /></Modal.Body>

                </Modal>


            </>
        );
    }
}

export default withStyles(style)(Productdetails);
