import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../assets/css/home.css";
import Ring from "../../assets/images/Home/rings.jpg";
import Neclaces from "../../assets/images/Home/Pendant.jpg";
import Bracelets from "../../assets/images/Home/Bracelet.jpg";
import Earrings from "../../assets/images/Home/Earrings.jpg";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";

const ShopByCategory = () => {
  const history = useHistory();
  return (
    <>
      <section className="gsk_shop_category_section">
        <Container className="gs_custome_fluid_container" fluid>
          <Row>
            <Col>
              <div className="gsK_home_title d_font">
                <h2> Shop By Category </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} xs={12}>
              <div className="gsk_shop_category_box">
                <Link to="/category/shop-rings/category=ring">
                  <div className="gsk_shop_category_img">
                    <img src={Ring} alt="Rings" />
                  </div>
                </Link>
                <div className="gsk_shop_category_box_text d_font">
                <Link to="/category/shop-rings/category=ring"> <h3 className="text-dark"> Rings </h3> </Link>
                  <Button
                    onClick={() =>
                      history.push("/category/shop-rings/category=ring")
                    }
                  >
                    Shop Now
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={6} xs={12}>
              <div className="gsk_shop_category_box">
              <Link to="/category/shop-pendants/category=pendant">
                <div className="gsk_shop_category_img">
                  <img src={Neclaces} alt="Neclaces" />
                </div>
                </Link>
                <div className="gsk_shop_category_box_text d_font">
                <Link to="/category/shop-pendants/category=pendant">  <h3 className="text-dark" > Necklaces </h3> </Link>
                  <Button
                    onClick={() =>
                      history.push("/category/shop-pendants/category=pendant")
                    }
                  >
                     Shop Now
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="gsk_shop_category_box">
              <Link to="/category/shop-bracelets/category=bracelet">
                <div className="gsk_shop_category_img">
                  <img src={Bracelets} alt="Bracelets" />
                </div>
                </Link>
                <div className="gsk_shop_category_box_text d_font">
                <Link to="/category/shop-bracelets/category=bracelet">  <h3 className="text-dark"> Bracelets </h3> </Link>
                  <Button  onClick={() =>
                      history.push("/category/shop-bracelets/category=bracelet")
                    }>
                    Shop Now 
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={6} xs={12}>
              <div className="gsk_shop_category_box">
              <Link to="category/shop-earrings/category=earrings"> 
                <div className="gsk_shop_category_img">
                  <img src={Earrings} alt="Earrings" />
                </div>
                </Link>
                <div className="gsk_shop_category_box_text d_font">
                <Link to="category/shop-earrings/category=earrings">  <h3 className="text-dark"> Earrings </h3> </Link>
                  <Button  onClick={() =>
                      history.push("category/shop-earrings/category=earrings")
                    }>
                     Shop Now 
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ShopByCategory;
