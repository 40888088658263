import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const FourZeroFour = () => {
  return (
    <section>
        <Container>
            <div className='text-center mb-5'>
            <Link to="/">
            <img src={require("./assets/images/404.svg").default} className='img-fluid'/>
                </Link>
           
            </div>
            <div className="text-center mb-5">
                <Link className='gsk_our_brand_btn' to="/">
                    Go to Home
                </Link>
            </div>
               
        </Container>
    </section>
  )
}

export default FourZeroFour