import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, InputGroup, Col, Image, Modal } from 'react-bootstrap';
import { Button, IconButton, InputLabel, MenuItem, Select, FormControl, CircularProgress, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import '../../assets/css/wishlist.css'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import payment_icon from "../../assets/images/footer/payment_icon.svg";
import { NavLink, useHistory } from 'react-router-dom';
import { getData, postData, deleteData, putAuthData, putData, isLogin } from '../../Helpers/request';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { toast } from 'react-toastify';
import emptyCart from "../../assets/images/icon/mycart.svg";
import { Helmet } from 'react-helmet';
import { Skeleton } from '@material-ui/lab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import gs_ringsicon from '../../assets/images/Home/rings.jpg';
import gs_pendanticon from '../../assets/images/Home/Pendant.jpg';
import gs_earringicon from '../../assets/images/Home/Earrings.jpg';
import gs_braceletsicon from '../../assets/images/Home/Bracelet.jpg';
import { CartData } from '../../store/slices/Global';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 110,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Cart = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const [coupon, setCoupon] = React.useState('');
    const [cartid, setCartid] = React.useState('');
    const [curr_icon, setCurr_icon] = React.useState('');
    const [curr_ring_size, setCurr_ring_size] = React.useState([]);
    const [productsdata, setProductsdata] = React.useState([]);
    const [mycart, setMycart] = React.useState(localStorage.getItem("cartlength"));
    const [quantities, setQuantities] = React.useState([]);
    const [ivalue, setIvalue] = React.useState(null);
    const [total_cart_price, setTotal_cart_price] = React.useState(null);
    const [saleTax, setSaleTax] = React.useState(null);
    const [discount, setDiscount] = React.useState(0);
    const [amount, setAmount] = React.useState(null);
    const [couponcode, setCouponcode] = React.useState(null);
    const [checkoutdisabled, setCheckoutdisabled] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [noRingsize, setNoRingsize] = useState(false);
    const [overnightshipping, setOvernightshipping] = useState(false);
    const [productType, setproductType] = useState(0);
    const [shippingPriceByUser, setshippingPriceByUser] = useState(1);
    const [shipDataText, setshipDataText] = useState("");

   const kleviyoCheckoutStart = (val) => {

        var item = {
            "url": window.location.href,
            "cartlist": val?.cartlist,
            "sale_tax_price": val?.sale_tax_price,
            "total_cart_price": val?.total_cart_price,
            "cart_item_count": val?.cart_item_count
        };
        if (window.klaviyo) {
            window.klaviyo.push(["track", "Checkout Started", item]);
        }
    }

    const handleChange = (e, i) => {
        setCurr_ring_size(curr_ring_size.map((x, index) => {
            if (i == index) {
                postsize(i, e.target.value);
                return x = e.target.value;
            }
            return x;
        }))
    }

    const incNum = (i) => {
        // quantities[i] = Number(quantities[i]) + 1;
        setIvalue(i);
        setQuantities(quantities.map((x, index) => {
            if (i === index) {
                // postquantity(i, x + 1);
                return x + 1;
            }
            return x;
        }))
    };
    const decNum = (i) => {
        setIvalue(i);
        if (quantities[i] > 1) {
            // quantities[i] = Number(quantities[i]) - 1;
            setQuantities(quantities.map((x, index) => {
                if (i === index) {
                    // postquantity(i, x - 1);
                    return x - 1;
                }
                return x;
            }))
        } else {
            setQuantities(quantities.map((x, index) => {
                if (i === index)
                    return x = 1;
                return x;
            }))
        }
    };
    const postquantity = (i) => {
        setCount(0);
        var quantity = 0;
        quantities.map((x, index) => {
            if (i === index) {
                quantity = x;
            }
        })
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            product_id: productsdata?.cartlist[i]?.product[0]?.id,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            stone_data: productsdata?.cartlist[i]?.product[0]?.stone_data?.length ? Object.values(productsdata?.cartlist[i]?.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "",
            ring_size: curr_ring_size[i],
            quantity: quantity,
            engraving: productsdata?.cartlist[i]?.engraving,
            font_style: productsdata?.cartlist[i]?.font_style,
            jewelry_box: productsdata?.cartlist[i]?.jewelry_box,
        }
        postData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const OvernightHandle = (type) => {
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            is_overnight_shipping: type,

            product_type: productType
        }
        postData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // setOvernightshipping(check)
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const postsize = (i, x) => {
        const data = {
            user_id: JSON.parse(localStorage.getItem('user'))?.user_id ? JSON.parse(localStorage.getItem('user')).user_id : 0,
            product_id: productsdata.cartlist[i].product[0].id,
            cart_id: productsdata.cartlist[i].id,
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            stone_data: productsdata?.cartlist[i]?.product[0]?.stone_data?.length ? Object.values(productsdata?.cartlist[i]?.product[0]?.stone_data)?.toString()?.replaceAll(",", "_") : "",
            ring_size: x,
            quantity: quantities[i],
            engraving: productsdata?.cartlist[i]?.engraving,
            font_style: productsdata?.cartlist[i]?.font_style,
            jewelry_box: productsdata?.cartlist[i]?.jewelry_box,
        }
        putData(`/order/cartItems`, data)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getcoupon = () => {
        setCouponcode("");
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/coupon?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}&coupon_code=${coupon ? coupon : sessionStorage.getItem("coupon")}`)
            .then((response) => {
                if (response.status == 1) {
                    setTotal_cart_price(response.data.final_amount)
                    setDiscount(response.data.discount_amount)
                    setAmount(response.data.total_amount)
                    setCouponcode(response.data.coupon_code)
                    sessionStorage.setItem("coupon", response.data.coupon_code);
                    setCoupon("")
                    setMessage("")
                    // toast.success(response.message, { autoClose: 3000 });
                } else if (response.status == 0) {
                    // toast.error(response.message, { autoClose: 3000 });
                    setMessage(response.message)
                    sessionStorage.removeItem("coupon")
                    setCoupon(null);
                    setDiscount(0)
                    setAmount(null)
                    setTotal_cart_price(null)
                    setCouponcode(null)
                    getcartdata();
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const Deleteitem = () => {
        deleteData(`/order/cartItems?cart_id=${cartid}`)
            .then((response) => {
                if (response.status == 1) {
                    // toast.success(response.message, { autoClose: 3000 });
                    getcartdata();
                }
                else {
                    toast.error(response.message, { autoClose: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const getcartdata = () => {
        setCheckoutdisabled(false)
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/cartItems?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then(res => {
                if (res.status == 1) {
                    sessionStorage.setItem("saleTax", res?.data?.shipping_price)
                    setQuantities([])
                    setCurr_ring_size([])
                    setProductsdata(res.data)
                    setTotal_cart_price(res.data.total_cart_price)
                    setAmount(res.data.cart_price)
                    setOvernightshipping(res.data.overnight_shipping_flag)
                    localStorage.setItem("cartlength", res.data.cart_item_count)
                    setMycart(res.data.cart_item_count);
                    setCurr_icon(res.data.cartlist[0]?.product[0]?.currency_icon)
                    setSaleTax(res?.data?.sale_tax_price);
                    setshipDataText(res?.data?.ship_data_text)
                    let proType=[];
                    res.data.cartlist?.map((val, index) => {

                        val?.product.length > 0 && val?.product?.map((e, i) => (
                          
                            proType.push(e?.product_type),
                            e?.product_type === 0 ? (setproductType(0), dispatch(CartData(0), localStorage.setItem("productType", 0))) : (setproductType(1), dispatch(CartData(1), localStorage.setItem("productType", 1)))
                        ))

                        //quantities.push(val.quantity)
                        setQuantities(quantities => [...quantities, val.quantity])
                        setCurr_ring_size(curr_ring_size => [...curr_ring_size, val.ring_size])
                        if (val.product[0].ring_size?.filter(val => val.value?.length > 0).length) {
                            if (val.ring_size == 0) {
                                setCheckoutdisabled(true)
                            }
                        }
                        // if (val.product[0].engraving?.filter(val => val.value?.length > 0).length) {
                        //     if (val.engraving == 0) {
                        //         setCheckoutdisabled(false)
                        //     }
                        // }
                    })
                    proType.includes(0) ? localStorage.setItem("productType1", 0) : localStorage.setItem("productType1", 1)

                    if (sessionStorage.getItem("coupon")?.length) {
                        getcoupon();
                    }

                }
                else {
                    if (isLogin) {
                        history.push("/myaccount")
                        toast.error(res.message, { autoClose: 3000 });
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const deletecoupon = () => {
        sessionStorage.removeItem("coupon")
        setCoupon(null);
        setDiscount(0)
        setAmount(null)
        setTotal_cart_price(null)
        setCouponcode(null)
        getcartdata();
    }
    useEffect(() => {
        getcartdata();
        addedToCart()

    }, [])

    useMemo(() => {
        OvernightHandle(shippingPriceByUser)

    }, [shippingPriceByUser])




    const redirect = (slug, sku, stone_data) => {
        history.push('/productdetail/' + slug + "/" + sku + (stone_data.length ? "/" + Object.values(stone_data)?.toString()?.replaceAll(",", "_") : ""));
    }
    const begin_checkout_event = () => {
        let newArray = [];
        const res = productsdata?.cartlist.length > 0 && productsdata.cartlist?.map(product => ({
            item_id: product.cart_id,
            item_name: product.name,
            price: product.price
        }));
        newArray.push(res)
        if (window.gtag) {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('event', 'begin_checkout', {
                event: 'begin_checkout',
                currency: "USD",
                value: productsdata?.cart_price,
                ecommerce: {
                    items: newArray
                }
            });
        }
    }

    const checkoutclick = () => {

        kleviyoCheckoutStart(productsdata)
        if (checkoutdisabled) {
            setNoRingsize(true)
            // setTimeout(() => { setNoRingsize(false) }, 2000);
        } else {
            history.push("/checkout")
            window.scrollTo(0, 0)
            begin_checkout_event()
        }
    }
    const [remove, setRemove] = useState(false);
    const handleClose = () => setRemove(false);
    const handleShow = () => setRemove(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [count, setCount] = useState(0);
    const imageLoaded = () => {
        setLoading(true);
    }

 

    const addedToCart = () => {


    }
    return (
        <>
            <Helmet>
                <title>Cart | Gem Stone King ™</title>
                <meta name="description" content="Cart" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/cart" />
                <meta property="og:title" content="Cart" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Cart" />
            </Helmet>

            <div className="gs_wishlist_content">
                <Container className="gs_customer_container">

                    <Row className="m-0 w-100">
                        <div className="gs_wish_heading">
                            <h1>My Cart <span>{mycart == 1 ? `( ${mycart} Item )` : mycart > 1 ? `( ${mycart} Items )` : ""}</span></h1>
                        </div>
                    </Row>
                    {mycart > 0 ?
                        <Row className="pb-3">
                            <Col sm={12} md={12} lg={8} xl={9} className="">
                                <Row className="m-0 w-100">
                                    <Modal
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered className="gs_appointment_modal gs_cart" show={remove} onHide={() => setRemove(false)}>
                                        <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="gs_appointment_input gs_noradius">
                                                <DeleteOutlineIcon className="gs_remove_cart" />
                                                <Modal.Title>Are you sure you want to remove this item ?</Modal.Title>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" className="gs_fill_button" onClick={() => { Deleteitem(); handleClose() }}>
                                                Yes
                                            </Button>
                                            <Button variant="primary" className="gs_outline_button" onClick={() => setRemove(false)}>
                                                No
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {productsdata?.cartlist?.map((val, index) =>
                                        <Col sm={12} md={12} lg={12} xl={12} className="mb-3 p-0">
                                            <div className="gs_my_wishlist gs_my_cart">
                                                <IconButton className="gs_remove_icon" aria-label="edit" component="span">
                                                    <DeleteIcon onClick={() => { setCartid(val.id); handleShow() }} />
                                                </IconButton>

                                                <Row>
                                                    <Col sm={12} md={4} lg={6} xl={3}>
                                                        {isMobileOnly ?
                                                            <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={130} height={130} /> : isTablet ? <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={350} height={350} /> : <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={220} height={220} />
                                                        }
                                                        <Image style={{ display: loading ? "block" : "none" }} onLoad={() => imageLoaded(val.product[0]?.img_url)} src={val.product[0]?.img_url}></Image>
                                                    </Col>
                                                    <Col sm={12} md={8} lg={6} xl={8}>
                                                        <h2 className="gs_wish_title" onClick={() => redirect(val.product[0]?.product_slug, val.product[0]?.Product_sku, val.product[0]?.stone_data)}>{val.product[0]?.product_name} </h2>
                                                        <p className="gs_sku">{val.product[0]?.Product_sku}</p>
                                                        <h2 className="gs_wish_title">{val.product[0]?.currency_icon}{val.product[0]?.product_price}{val.jewelry_box ? ` + ${val.product[0]?.currency_icon}${val.jewelry_box_price} (Customize Gift Box)  ` : ""} </h2>
                                                        <div className="gs_cart_quantitys mb-3">
                                                            <span className="mr-3 mt-2 gs_q_size">Quantity </span>
                                                            <InputGroup className="mb-3 mt-2 input-group">
                                                                <Button variant="outline-secondary" className="gs_increment_btn" id="button-addon1" onMouseLeave={(e) => count > 0 ? postquantity(ivalue) : ""} onClick={() => { setCount(count + 1); decNum(index) }}>
                                                                    <RemoveIcon />
                                                                </Button>
                                                                <input type="text" value={quantities[index]} disabled name="name" />
                                                                <Button variant="outline-secondary" className="gs_increment_btn" id="button-addon1" onMouseLeave={(e) => count > 0 ? postquantity(ivalue) : ""} onClick={() => { setCount(count + 1); incNum(index) }}>
                                                                    <AddIcon />
                                                                </Button>
                                                            </InputGroup>
                                                        </div>
                                                        {val.product[0]?.ring_size?.filter(val => val.value?.length > 0).length ?
                                                            <Row className="gsk_cart_ringsize mt-4">
                                                                <div className={noRingsize && (curr_ring_size[index] == 0) ? "gs_appointment_input gs_noradius ml-3 mt-3 d-block gs_details_ring_size animate__animated animate__shakeX gs_sel_sizerequired" : "gs_appointment_input gs_noradius ml-3 mt-3 d-block gs_details_ring_size "}>
                                                                    <FormControl variant="outlined" className={classes.formControl} style={{ margin: "0px !important" }}>
                                                                        <InputLabel id="demo-simple-select-outlined-label">Ring Size</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-outlined-label"
                                                                            id="demo-simple-select-outlined"
                                                                            label="Ring Size"
                                                                            value={String(curr_ring_size[index])}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                            size="small"
                                                                        >
                                                                            {val.product[0]?.ring_size.map(item =>
                                                                                <MenuItem value={item.value} >{item.value}</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </Row> : ""}
                                                        <Row className="w-100 m-auto">
                                                            {/* {val.product[0].engraving?.filter(val => val.value?.length > 0).length ? */}
                                                            <Col xs={12} sm={6} className="p-0">
                                                                {val.product[0]?.ring_size?.filter(val => val.value?.length > 0).length ?
                                                                    <>
                                                                        {val.engraving_data?.map(newval =>
                                                                            <>
                                                                                <span className="mr-3 mt-2 gs_eng_txt">{newval.title}</span>
                                                                                <p className={val.font_style == 'great_vibes' ? "gs_great_vibes" : "gs_montserrat"}>{newval.value}</p>
                                                                            </>)}

                                                                    </>
                                                                    : ""}
                                                                {/* <p className={this.state.textstyle == 'great_vibes gs_cart_price1' ? "gs_great_vibes gs_cart_price1" : "gs_montserrat gs_cart_price1"}>Abhishek</p> */}
                                                            </Col>
                                                            <Col xs={12} sm={6} className="p-0">
                                                                <p className="gs_cart_price">{val.product[0]?.currency_icon}{val.product[0]?.sale_price}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col sm={12} md={12} lg={4} xl={3} className="">
                                <div className="gs_cart_summary">
                                    <h4>SUMMARY </h4>
                                    <div className="gs_cart_box">
                                        <div className="gs_cart_row">
                                            <p className="gs_summary_label"> Total</p>
                                            <p>{curr_icon}{amount}</p>
                                        </div>
                                        {discount > 0 ?
                                            <div className="gs_cart_row">
                                                <p className="gs_summary_label"> Discount</p>
                                                <p>{curr_icon}{discount}</p>
                                            </div> : ""}
                                        {overnightshipping > 0 ?
                                            <div className="gs_cart_row">
                                                <p className="gs_summary_label">Shipping Charges</p>
                                                <p>{curr_icon}{productsdata?.shipping_price}</p>
                                            </div> : ""}

                                        {
                                            isLogin && <div className="gs_cart_row">
                                                <p className="gs_summary_label"> Sale Tax</p>
                                                <p>{curr_icon}{saleTax}</p>
                                            </div>
                                        }

                                        <div className="gs_cart_row">
                                            <p className="gs_summary_label"> Total Payable</p>
                                            <p>{curr_icon}{total_cart_price}</p>
                                        </div>

                                        {sessionStorage.getItem("coupon")?.length ?
                                            <div className="gs_promoapply_field">
                                                <span>
                                                    <LocalOfferIcon /> {sessionStorage.getItem("coupon")}
                                                </span>
                                                <IconButton className="gs_remove_icon" aria-label="edit" component="span">
                                                    <DeleteIcon onClick={() => deletecoupon()} />
                                                </IconButton>
                                            </div> : <div className="gs_promo_field">
                                                <strong>Promo Code </strong>
                                                <InputGroup className="mb-3 mt-3">
                                                    <input value={(coupon)?.toLocaleUpperCase()} onChange={(e) => setCoupon((e.target.value).toLocaleUpperCase())} placeholder="Have a Promo Code" type="text" name="name" />
                                                    <Button disabled={coupon?.length ? false : true} onClick={() => getcoupon()} variant="outline-secondary" id="button-addon10">
                                                        Apply
                                                    </Button>
                                                </InputGroup>
                                                {message.length ? <p style={{ fontSize: "12px", margin: "5px 0", color: "red" }}>{message}</p> : ""}
                                            </div>}
                                        <div className="gs_cart_row">
                                            <p className="gs_total_label"> Total Payable</p>
                                            <p className="gs_total_price">{curr_icon}{total_cart_price}</p>
                                        </div>

                                        <div className="gs_ship_row">
                                            {/* <p className="gs_summary_label">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={overnightshipping}
                                                            onChange={(e) => OvernightHandle(e.target.checked)}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Overnight Shipping"
                                                />
                                            </p> */}
                                            <p className="gs_summary_label">  Delivery by:   <span><strong>   {productsdata.estimate_delivery} </strong></span></p>
                                        </div>

                                        {
                                            productType === 0 ? <div className='shippingOptWrapper'>
                                                {/* <h5 className='blueColor'>
                                                    All customized items
                                                </h5> */}

                                                <div className='checkItems'>
                                                    <label htmlFor="cust3to7One" onClick={() => setshippingPriceByUser(1)}>  <input id='cust3to7One' name='all_customized' className='' type="radio" value={1} checked={shippingPriceByUser === 1 ? true : false} /> <span>Free Standard Shipping (3-7 Days Delivery)</span></label>
                                                </div>
                                                <div className='checkItems'>
                                                    <label htmlFor="cust2day" onClick={() => setshippingPriceByUser(2)}>  <input id='cust2day' name='all_customized' className='' type="radio" value={2} checked={shippingPriceByUser === 2 ? true : false} /> <span>Free (2  Day Delivery)</span></label>
                                                </div>
                                                <div className='checkItems'>
                                                    <label htmlFor="cust1day" onClick={() => setshippingPriceByUser(3)}>  <input id='cust1day' name='all_customized' className='' type="radio" value={3} checked={shippingPriceByUser === 3 ? true : false} /> <span>Free (1 Day Delivery)</span></label>
                                                </div>
                                            </div> :
                                                shipDataText === "less_than_$40" ? <div className='shippingOptWrapper'>
                                                    {/* <h5 className='blueColor'>
                                                All regular items (not customized) less than $40
                                                </h5> */}
                                                    <div className='checkItems'>
                                                        <label htmlFor="forty3to7One" onClick={() => setshippingPriceByUser(1)}>  <input id='forty3to7One' name='fortyLess' className='' type="radio" value={1} checked={shippingPriceByUser === 1 ? true : false} /> <span>Free Standard Shipping (3-7 Days Delivery)</span></label>
                                                    </div>
                                                    <div className='checkItems'>
                                                        <label htmlFor="fortycust2day" onClick={() => setshippingPriceByUser(2)}>  <input id='fortycust2day' name='fortyLess' className='' type="radio" value={2} checked={shippingPriceByUser === 2 ? true : false} /> <span>$6 (2 Day Delivery)</span></label>
                                                    </div>
                                                    <div className='checkItems'>
                                                        <label htmlFor="fortycust1day" onClick={() => setshippingPriceByUser(3)}>  <input id='fortycust1day' name='fortyLess' className='' type="radio" value={3} checked={shippingPriceByUser === 3 ? true : false} /> <span>$15 (1 Day Delivery)</span></label>
                                                    </div>
                                                </div>
                                                    : shipDataText === "$40_to_$99.99" ? <div className='shippingOptWrapper'>
                                                        {/* <h5 className='blueColor'>
                                            All regular items (not customized) $40 to $99.99
                                            </h5> */}
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNinty3to7" onClick={() => setshippingPriceByUser(1)}>  <input id='fortytoNinty3to7' name='fortytonintynine' className='' type="radio" value={1} checked={shippingPriceByUser === 1 ? true : false} /> <span>Free Standard Shipping (3-7 Days Delivery)</span></label>
                                                        </div>
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNintycust2day" onClick={() => setshippingPriceByUser(2)}>  <input id='fortytoNintycust2day' name='fortytonintynine' className='' type="radio" value={2} checked={shippingPriceByUser === 2 ? true : false} /> <span>Free (2 Day Delivery)</span></label>
                                                        </div>
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNintycust15day" onClick={() => setshippingPriceByUser(3)}>  <input id='fortytoNintycust15day' name='fortytonintynine' className='' type="radio" value={3} checked={shippingPriceByUser === 3 ? true : false} /> <span>$15 (1 Day Delivery)</span></label>
                                                        </div>
                                                    </div> : <div className='shippingOptWrapper'>
                                                        {/* <h5 className='blueColor'>
                                            All regular items (not customized) $100 and up
                                            </h5> */}
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNinty3to7" onClick={() => setshippingPriceByUser(1)}>  <input id='fortytoNinty3to7' name='hundUp' className='' type="radio" value={1} checked={shippingPriceByUser === 1 ? true : false} /> <span>Free Standard Shipping (3-7 Days Delivery)</span></label>
                                                        </div>
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNintycust2day" onClick={() => setshippingPriceByUser(2)}>  <input id='fortytoNintycust2day' name='hundUp' className='' type="radio" value={2} checked={shippingPriceByUser === 2 ? true : false} /> <span>Free (2 Day Delivery)</span></label>
                                                        </div>
                                                        <div className='checkItems'>
                                                            <label htmlFor="fortytoNintycust15day" onClick={() => setshippingPriceByUser(3)}>  <input id='fortytoNintycust15day' name='hundUp' className='' type="radio" value={3} checked={shippingPriceByUser === 3 ? true : false} /> <span>Free (1 Day Delivery)</span></label>
                                                        </div>
                                                    </div>

                                        }
                                        <div className="text-center">
                                            <Button onClick={checkoutclick} className="gs_check_btn mt-2" disabled={buttonLoading}>
                                                Checkout  {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                                            </Button>
                                        </div>
                                        <div className="gs_accept_card">
                                            <p>We Accept </p>
                                            <img src={payment_icon} alt="Payment"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row> : <Row className='w-100 d-block m-auto'><NavLink to="/"> <Image className="gs_empty_cart" src={emptyCart} alt="cart empty image"></Image> </NavLink>
                            <Divider />
                            <ul className="gs_no_prod_item mt-5">
                                <li onClick={() => history.push('/category/shop-rings/category=ring')}>
                                    <img className="gs_noprod_img" src={gs_ringsicon} />
                                    Shop Rings
                                </li>
                                <li onClick={() => history.push('/category/shop-pendants/category=pendant')}>
                                    <img className="gs_noprod_img" src={gs_pendanticon} />
                                    Shop Pendants</li>
                                <li onClick={() => history.push('/category/shop-earrings/category=earrings')}>
                                    <img className="gs_noprod_img" src={gs_earringicon} />
                                    Shop Earrings</li>
                                <li onClick={() => history.push('/category/shop-bracelets/category=bracelet')}>
                                    <img className="gs_noprod_img" src={gs_braceletsicon} />
                                    Shop Bracelets</li>
                            </ul>
                        </Row>
                    }
                </Container>
            </div>

        </>
    )
}

export default Cart
