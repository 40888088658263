import React from 'react'
import { Redirect, Switch,Route } from 'react-router-dom'

const Redirection = () => {
  return (
    <Switch>
    <Route path="/collections/rings" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/earrings" render={() =>(<Redirect to="/category/earrings/category=earrings" />)} />
    <Route path="/collections/necklaces" render={() =>(<Redirect to="/category/pendants/category=pendant" />)} />
    <Route path="/blogs/news/how-to-customize-your-gemstone-jewelry-like-a-color-expert" render={() =>(<Redirect to="/blog-details/12" />)} />
    <Route path="/collections/bracelets" render={() =>(<Redirect to="/category/bracelets/category=bracelet" />)} />
    <Route path="/collections/london-blue-rings" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/rings/stone_onyx" render={() =>(<Redirect to="/productlist/rings/gemstone/onyx/category=ring/gemstone=onyx" />)} />
    <Route path="/blogs/news/jewelry-inspired-by-the-year-of-the-ox-and-ancient-chinese-tradition" render={() =>(<Redirect to="/blog-details/8" />)} />
    <Route path="/collections/heart-necklaces-1" render={() =>(<Redirect to="/productlist/pendants/style/hearts/category=pendant/style=hearts" />)} />
    <Route path="/blogs/news/the-significance-of-family-jewelry-heirlooms" render={() =>(<Redirect to="/blog-details/9" />)} />
    <Route path="/collections/gem-stone-rings" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/engagement-rings-1" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/blogs/news/4-uses-for-amethyst-a-spiritual-stone-to-warm-winter" render={() =>(<Redirect to="/blog-details/10" />)} />
    <Route path="/collections/infinity-necklaces-1" render={() =>(<Redirect to="/productlist/pendants/style/infinity/category=pendant/style=infinity" />)} />
    <Route path="/collections/moissanite-collection/stone_created-ruby" render={() =>(<Redirect to="/productlist/pendants/gemstone/moissanite/category=pendant/gemstone=moissanite" />)} />
    <Route path="/collections/moissanite-collection" render={() =>(<Redirect to="/productlist/rings/gemstone/moissanite/category=ring/gemstone=moissanite" />)} />
    <Route path="/collections/gem-stone-rings/stone_created-ruby" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/infinity-rings" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/pages/reviews" render={() =>(<Redirect to="/pages/our-story" />)} />
    <Route path="/key-necklaces-1" render={() =>(<Redirect to="/category/pendants/category=pendant" />)} />
    <Route path="/collections/dangle-earrings" render={() =>(<Redirect to="/category/earrings/category=earrings" />)} />
    <Route path="/products/timeless-brilliant%C2%AE-8mm-3-80ct-dew-white-created-moissanite-14k-white-gold-round-stud-earrings" render={() =>(<Redirect to="/category/earrings/category=earrings" />)} />
    <Route path="/collections/rings/stone_opal" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/gem-stone-rings/stone_nano-morganite" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/tanzanite" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/rings/stone_citrine" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/rings/stone_simulated-aquamarine" render={() =>(<Redirect to="/category/rings/category=ring" />)} />
    <Route path="/collections/dangle-earrings/stone_mystic-topaz" render={() =>(<Redirect to="/category/earrings/category=earrings" />)} />
    <Route path="/collections/gem-stone-rings/stone_aquamarine" render={() =>(<Redirect to="/category/rings/category=ring" />)} />

    <Route path="/collections/rings/stone_sapphire" render={() =>(<Redirect to="/productlist/rings/gemstone/sapphire/category=ring/gemstone=sapphire" />)} />
    <Route path="/collections/peridot-earrings" render={() =>(<Redirect to="/productlist/rings/gemstone/peridot/category=ring/gemstone=peridot" />)} />
    <Route path="/collections/rings/stone_amethyst" render={() =>(<Redirect to="/productlist/rings/gemstone/amethyst/category=ring/gemstone=amethyst" />)} />
    <Route path="/collections/rings/stone_created-moissanite" render={() =>(<Redirect to="/productlist/rings/gemstone/moissanite/category=ring/gemstone=moissanite" />)} />
    <Route path="/collections/peridot" render={() =>(<Redirect to="/productlist/rings/gemstone/peridot/category=ring/gemstone=peridot" />)} />
    <Route path="/collections/sets" render={() =>(<Redirect to="/searchlist/set" />)} />
    <Route path="/collections/hoops-earrings-silver-gold" render={() =>(<Redirect to="/productlist/earrings/style/hoop/category=earrings/style=hoop" />)} />
    <Route path="/blogs/news/birthstone-jewelry-gemstones-that-add-a-personal-touch" render={() =>(<Redirect to="/blog-details/13" />)} />
</Switch>
  )
}

export default Redirection