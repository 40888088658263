import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import Global from "./slices/Global";
import OnlyRedux from './slices/OnlyRedux'
// const Store=configureStore({
//     reducer: Global
// });

// export default Store;


const persistConfig = {
    key:'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, combineReducers({ user: Global}))

const store = configureStore({
  reducer: {
    persistedReducer,
    OnlyRedux
  },
})
const persistor = persistStore(store)
export {store,persistor}
