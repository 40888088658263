import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../assets/css/home.css";
import BrandImg from "../../assets/images/Home/our-brand.png";
import ModalVideo from "react-modal-video";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";

const OurBrand = () => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <section className="gsk_our_brand_section">
        <Container className="gs_customer_container">
          <Row>
            <Col>
              <div className="gsk_our_brand_title">
                <p> From the heart of </p>
                <h2> New York City </h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg={5} xs={12}>
              <div className="gsk_our_brand_text_content">
                <h2 className="text-uppercase"> Our Brand </h2>
                <p>
                  For Four generations, since 1903 our family has taken pride on
                  delivering thehighest quality of Fine Jewelry.
                </p>
                <p>
                  Each Gem Stone King piece is a work of art. We ensure a unique
                  optimal shoppingexperience for our customers.All of our pieces
                  go through intense and rigorous quality control process in our
                  home office in New York to ensure that our jewelry is up to
                  standard and built to last.
                </p>
                <div className="mt-5">
                  <Link to="/pages/our-story" className="gsk_our_brand_btn">
                    Discover More
                  </Link>
                </div>

              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="gsk_our_brand_img">
                {/* <img src={BrandImg} alt=''/>
                <button
                  className="gsk_our_brand_video_btn"
                  onClick={() => setOpen(true)}
                >
                  <BsPlayCircle />
                </button> */}


                <video className="w-100" poster={require("../../assets/images/brand-thumb.jpg").default} controls>
                  <source src="https://gsk-videos.s3.amazonaws.com/website-videos/gsk-our-brand-video.mp4" type="video/mp4" />
                </video>
                {/* <div className="videoContainer"> 
                <iframe className="responsiveIframe" src="https://www.youtube-nocookie.com/embed/J4cz8-V3Ya4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div> */}

              </div>

              {/* <ModalVideo
                youtube={{
                  autoplay: 1,
                  mute: 1,
                  rel:0,
                  show_related: 0,
                  playlist: null
                }}
                isOpen={isOpen}
                videoId="J4cz8-V3Ya4?rel=0"
                onClose={() => setOpen(false)}
               
              /> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default OurBrand;
