// export const base_url = "http://192.168.1.39:8080"
// export const base_url = "https://api.tasksource.in"
export const base_url = "https://api.gemstoneking.com"
export const web_base_url = "https://api.gemstoneking.com"
export const isLogin = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).isLogin : false ;

function sessionValidation(response) {
  if (response.status === 401 || response.status === 2) {
    localStorage.removeItem('user');
    localStorage.setItem('warn', 'Session expired');
    window.location.reload();
    return false;
  };
  return true;
}
export const postHeader = {
  'Authorization': `Token e222085edc871220cae528152b73c1317ba2addf`,
  'Content-type':'application/json'
}
export async function putAuthData(url, data) {
  const user = JSON.parse(localStorage.getItem('user'));
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (data === null || data === undefined)
    data = new Object();
    data.language = language;
    data.currency = currency;
    const response = await fetch(base_url + url, {
      method: 'PUT',
      headers: {
        'Authorization': `Token ${user.token}`,
        'Content-Type': 'application/json',
      },
    body: JSON.stringify(data)
  });

  if (!sessionValidation(response)) {
    return;
  };
  return response.json();
}
export async function putData(url, data) {
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (data === null || data === undefined)
    data = new Object();
    data.language = language;
    data.currency = currency;
    const response = await fetch(base_url + url, {
      method: 'PUT',
      headers: {
        'Authorization': `Token e222085edc871220cae528152b73c1317ba2addf`,
        'Content-Type': 'application/json',
      },
    body: JSON.stringify(data)
  });

  if (!sessionValidation(response)) {
    return;
  };
  return response.json();
}
export async function postAuthData(url, data) {
  const user = JSON.parse(localStorage.getItem('user'));
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (data === null || data === undefined)
    data = new Object();
    data.language = language;
    data.currency = currency;
    const response = await fetch(base_url + url, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${user.token}`,
        'Content-Type': 'application/json',
      },
    body: JSON.stringify(data)
  });

  if (!sessionValidation(response)) {
    return;
  };
  return response.json();
}

export async function postData(url, data) {
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (data === null || data === undefined)
    data = new Object();
    data.language = language;
    data.currency = currency;
    const response = await fetch(base_url + url, {
      method: 'POST',
      headers: {
        'Authorization': `Token e222085edc871220cae528152b73c1317ba2addf`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
  });
  return response.json();
}

export async function deleteData(url, data) {
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (data === null || data === undefined)
    data = new Object();
    data.language = language;
    data.currency = currency;
    const response = await fetch(base_url + url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Token e222085edc871220cae528152b73c1317ba2addf`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
  });
  return response.json();
}

export async function getAuthData(url) {
  const user = JSON.parse(localStorage.getItem('user'));
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (url.indexOf('?') === -1)
    url = `${url}?language=${language}&currency=${currency}`;
  else
    url = `${url}&language=${language}&currency=${currency}`;
    const response = await fetch(base_url + url, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${user.token}`,
        'Content-Type': 'application/json'
      }
  });

  if (!sessionValidation(response)) {
    return;
  };
  return response.json();
}

export async function getData(url) {
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
  const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : 'USD';

  if (url.indexOf('?') === -1)
    url = `${url}?language=${language}&currency=${currency}`;
  else
    url = `${url}&language=${language}&currency=${currency}`;

    const response = await fetch(base_url + url, {
      method: 'GET',
      headers: {
        'Authorization': `Token e222085edc871220cae528152b73c1317ba2addf`,
        'Content-Type': 'application/json'
      }
  });
  return response.json();
}

