import React from "react";
import {
  Container,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PaymentDeclined = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

  return (
    <>
      <section className="thanku-page">
        <Container className="mt-lg-5 pt-lg-5 pb-5">
          <Col lg={12} md={12} xs={12} xl={12} className="pe-lg-5 text-center pb-5">
            <h1 className="title">SOMETHING WENT WRONG!</h1>
            <p className="subtitle">
            Your card was declined. Please try again or use a different card.<br/> Sorry for the inconvenience!
            </p>
            <p>
            Go to <Link to="/cart">cart</Link> page
            </p>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default PaymentDeclined;
