import React, { Component, useRef, useState } from "react";
import {
  Container,
  Col,
  Row,
  Image,
  Modal,
  NavLink,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductImageRing from "../../assets/images/gsk-ring.jpg";
import ProductImageEarring from "../../assets/images/gsk-earrings.jpg";
import ProductImageBracelets from "../../assets/images/gsk-bracelet.jpg";
import ProductImage3 from "../../assets/images/gsk-Pendant.jpg";
import ProductImage4 from "../../assets/images/best_seller_plp.jpg";
import ProductImage5 from "../../assets/images/gsk-men.jpg";
import NoProduct from "../../assets/images/no_product.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import "../../assets/css/product_list.css";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/styles";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import {
  getData,
  base_url,
  postData,
  deleteData,
  web_base_url,
} from "../../Helpers/request";
import { toast, ToastContainer } from "react-toastify";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Box,
  Divider,
} from "@material-ui/core";
import { connect } from 'react-redux';
import { Range } from "rc-slider";
import "../../../node_modules/rc-slider/assets/index.css";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { Helmet } from "react-helmet";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  FacebookShareButton,
  EmailShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import FacebookIcon from "@material-ui/icons/Facebook";
import PinterestIcon from "@material-ui/icons/Pinterest";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import gs_ringsicon from "../../assets/images/Home/rings.jpg";
import gs_pendanticon from "../../assets/images/Home/Pendant.jpg";
import gs_earringicon from "../../assets/images/Home/Earrings.jpg";
import gs_braceletsicon from "../../assets/images/Home/Bracelet.jpg";
import { AngleBottom, AngleTop } from "../../assets/svg";
import { BsHeart } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiTwotoneHeart } from "react-icons/ai";
import { MenuFilter } from "../../store/slices/Global";

const style = (theme) => ({
  root: {
    width: "100%",
  },
});

class Productlist extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      showFilter: isMobile ? false : true,
      productdata: [],
      filterdata: [],
      productCount: 0,
      page: 1,
      titleheading: this.props.match.params.item,
      Category: this.props.match.params.value
        ? this.props.match.params.value
        : "",
      Gemstone: JSON.parse(sessionStorage.getItem("list_filter"))?.Gemstone
        ? JSON.parse(sessionStorage.getItem("list_filter")).Gemstone
        : [],
      gemstoneDisplayName: [],
      Shape: JSON.parse(sessionStorage.getItem("list_filter"))?.Shape
        ? JSON.parse(sessionStorage.getItem("list_filter")).Shape
        : [],
      shapeDisplayName: [],
      categoryDisplayName: [],
      MetalColor: JSON.parse(sessionStorage.getItem("list_filter"))?.MetalColor
        ? JSON.parse(sessionStorage.getItem("list_filter")).MetalColor
        : [],
      metalDisplayName: [],
      Gender: JSON.parse(sessionStorage.getItem("list_filter"))?.Gender
        ? JSON.parse(sessionStorage.getItem("list_filter")).Gender
        : [],
      genderDisplayName: [],
      Style: JSON.parse(sessionStorage.getItem("list_filter"))?.Style
        ? JSON.parse(sessionStorage.getItem("list_filter")).Style
        : [],
      styleDisplayName: [],
      priceFrom: JSON.parse(sessionStorage.getItem("list_filter"))?.PriceFrom
        ? JSON.parse(sessionStorage.getItem("list_filter")).PriceFrom
        : 0,
      priceTo: JSON.parse(sessionStorage.getItem("list_filter"))?.PriceTo
        ? JSON.parse(sessionStorage.getItem("list_filter")).PriceTo
        : 50000,
      minprice: 0,
      maxprice: 50000,
      allPrice: [],
      priceChecked: null,
      subCat: this.props.match.params.subvalue
        ? this.props.match.params.subvalue
        : "",
      loading: false,
      totalProductCount: null,
      loadingApi: false,
      productempty: false,
      isClick: false,
      isExpanded: false,
      firstrender: false,
      quickveiw: false,
      quickveiwdata: [],
      quickveiwstone_data: "",
      quickveiwindex: 0,
      showDropDown: "",
      proIndex: "",
      activeClass: "",
      hoverpb: 0,
      wishlistIndex: '',
      radioCheck: false,
      sortby: 0,
      selFilter: false,
      categoryFilter: ""

    };
    this.counter = React.createRef(0);
  }

  klaviyoTrack = () => {
    var item = {
      "category": this.props.match.params.searchvalue && this.props.match.params.searchvalue,
      "pageName": document.title,
      "URL": window.location.href
    };
    if (window?.klaviyo) {
      window?.klaviyo.track("Viewed Product", item);
    }

  }



  productApi = () => {
    this.setState({
      loading: false,
      productdata: [],
      productCount: 0,
      firstrender: false,

    });
    var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
      ? JSON.parse(localStorage.getItem("user"))?.user_id
      : "";
    var session_id = localStorage.getItem("session_id")
      ? localStorage.getItem("session_id")
      : "";
    getData(
      `/api/productList?${this.props.match.params.value ? this.props.match.params.value : ""
      }${this.props.match.params.subvalue
        ? "&" + this.props.match.params.subvalue
        : ""
      }${this.state.categoryFilter ? "&category=" + this.state.categoryFilter : ""}${this.state.Gemstone?.length ? "&gemstone=" + this.state.Gemstone : ""
      }${this.state.Shape?.length ? "&shape=" + this.state.Shape : ""}${this.state.MetalColor?.length
        ? "&metalcolor=" + this.state.MetalColor
        : ""
      }${this.state.Gender?.length ? "&gender=" + this.state.Gender : ""}${this.state.Style?.length ? "&style=" + this.state.Style : ""
      }${this.state.priceFrom ? "&pricefrom=" + this.state.priceFrom : ""
      }${this.state.priceTo ? "&priceto=" + this.state.priceTo : ""}${this.props.match.params.searchvalue
        ? "&search_text=" + this.props.match.params.searchvalue
        : ""
      }&format=json&limit=16&offset=${(this.state.page - 1) * 16}&${user_id
        ? "user_id=" + user_id
        : session_id
          ? "session_id=" + session_id
          : ""
      }&sortby=${this.state.sortby}`
    )
      .then(async (response) => {
        this.setState({ firstrender: true });
        if (response.status == 1) {
          var count = (response.data.count / 16).toFixed();
          if (count * 16 >= response.data.count) {
            this.setState({ productCount: count });
          } else {
            this.setState({ productCount: Number(count) + 1 });
          }
          this.setState({
            totalProductCount: response.data.count,
            productdata: response.data.results,
            productempty: false,
          });
        } else {
          this.setState({
            totalProductCount: 0,
            loadingApi: true,
            productdata: [],
            productCount: 0,
            productempty: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  filterApi = () => {
    getData(
      `/api/productFilter?${this.props.match.params.value ? this.props.match.params.value : ""
      }`
    )
      .then((response) => {
        if (response.status == 1) {
          var arr = [];
          if (this.props.match.params.value == "gender=male") {
            arr = response.data.filter((val) => val.title != "Gender");
          }
          var price = response.data.filter((val) => val.title == "Price");
          this.setState({
            filterdata: arr?.length ? arr : response.data,
            minprice: price[0].data[0],
            maxprice: price[0].data[1],
            allPrice: price[0].data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount = () => {
    this.klaviyoTrack()
    document.addEventListener("mousedown", this.handleClickOutside);
    this.filterApi();
    this.productApi();
  };
  openDetail = (event, item) => {

    window.scrollTo(0, 0);
    var stone_data = item?.stone_data?.length
      ? Object.values(item?.stone_data)?.toString()?.replaceAll(",", "_")
      : "";


    if (event.ctrlKey) {
      var dynamicUrl = "/productdetail/" +
        item.product_slug +
        "/" +
        item.variant +
        "/" +
        stone_data;
      window.open(dynamicUrl, "_blank");
    } else {
      this.props.history.push(
        "/productdetail/" +
        item.product_slug +
        "/" +
        item.variant +
        "/" +
        stone_data
      );
    }
  };
  handlePageChange = (e, value) => {
    this.setState({ page: value });
    window.scrollTo(0, 0);
  };
  handlecheckChange = (e, title, item, display_name) => {

    const { dispatch } = this.props;
    dispatch(MenuFilter(true));

    if (e.target.defaultChecked) {
      this.setState({
        radioCheck: true
      })
    } else {
      this.setState({
        radioCheck: false
      })
    }

    window.scrollTo(0, 0);
    if (title == "Gemstone") {
      if (this.state.Gemstone?.includes(item)) {
        var data = this.state.Gemstone.filter((val) => val !== item);
        this.setState({ Gemstone: data });
      } else {
        //this.setState({ Gemstone: [...this.state.Gemstone, item] });
        this.setState({ Gemstone: [item] });
        this.setState({
          gemstoneDisplayName: [display_name]
        })

      }
    } else if (title == "Shape") {
      if (this.state.Shape?.includes(item)) {
        var data = this.state.Shape.filter((val) => val !== item);
        this.setState({ Shape: data });
      } else {
        //this.setState({ Shape: [...this.state.Shape, item] });
        this.setState({ Shape: [item] });
        this.setState({
          shapeDisplayName: [display_name]
        })
      }
    } else if (title == "Category") {
      if (this.state.categoryFilter?.includes(item)) {
        var data = this.state.categoryFilter.filter((val) => val !== item);
        this.setState({ categoryFilter: data });
      } else {
        //this.setState({ Shape: [...this.state.Shape, item] });
        this.setState({ categoryFilter: [item] });
        this.setState({
          categoryDisplayName: [display_name]
        })
      }
    } else if (title.replace(" ", "") == "MetalColor") {
      if (this.state.MetalColor?.includes(item)) {
        var data = this.state.MetalColor.filter((val) => val !== item);
        this.setState({ MetalColor: data });
      } else {
        //this.setState({ MetalColor: [...this.state.MetalColor, item] });
        this.setState({ MetalColor: [item] });
        this.setState({
          metalDisplayName: [display_name]
        })
      }
    } else if (title == "Gender") {
      if (this.state.Gender?.includes(item)) {
        var data = this.state.Gender.filter((val) => val !== item);
        this.setState({ Gender: data });
      } else {
        //this.setState({ Gender: [...this.state.Gender, item] });
        this.setState({ Gender: [item] });
      }
    } else if (title == "Style") {
      if (this.state.Style?.includes(item)) {
        var data = this.state.Style.filter((val) => val !== item);
        this.setState({ Style: data });
      } else {
        // this.setState({ Style: [...this.state.Style, item] });
        this.setState({ Style: [item] });
        this.setState({
          styleDisplayName: [display_name]
        })
      }
    } else if ([e.target.name] == "priceTo") {
      if (Number(e.target.value) > Number(this.state.priceFrom)) {
        this.setState({
          priceTo: e.target.value,
        });
      } else {
        this.setState({
          priceTo: this.state.priceFrom,
          priceFrom: e.target.value,
        });
      }
    } else if ([e.target.name] == "priceFrom") {
      if (Number(e.target.value) < Number(this.state.priceTo)) {
        this.setState({
          priceFrom: e.target.value,
        });
      } else {
        this.setState({
          priceFrom: this.state.priceTo,
          priceTo: e.target.value,
        });
      }
    }

    this.setState({
      showDropDown: ''
    })

  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.Gemstone != this.state.Gemstone ||
      prevState.Shape != this.state.Shape ||
      prevState.MetalColor != this.state.MetalColor ||
      prevState.Style != this.state.Style ||
      prevState.Gender != this.state.Gender ||
      prevState.priceTo != this.state.priceTo ||
      prevState.priceFrom != this.state.priceFrom ||
      prevState.sortby != this.state.sortby ||
      prevState.categoryFilter != this.state.categoryFilter
    ) {
      this.setState({ page: 1 });
      this.setState({ loading: false });
      if (this.state.page == 1) {
        this.productApi();
      }
      var filter = {
        Gemstone: this.state.Gemstone,
        Shape: this.state.Shape,
        MetalColor: this.state.MetalColor,
        Gender: this.state.Gender,
        Style: this.state.Style,
        priceTo: this.state.priceTo,
        priceFrom: this.state.priceFrom,
      };
      sessionStorage.setItem("list_filter", JSON.stringify(filter));
    }

    if (
      prevState.page != this.state.page &&
      (prevState.page != 1 || this.state.page != 1)
    ) {
      this.setState({ loading: false });
      this.productApi();
    }
    if (
      prevProps.match.params.item != this.props.match.params.item ||
      prevProps.match.params.menu != this.props.match.params.menu ||
      prevProps.match.params.value != this.props.match.params.value ||
      prevProps.match.params.subvalue != this.props.match.params.subvalue
    ) {
      this.setState({
        Category: this.props.match.params.value
          ? this.props.match.params.value
          : "",
        subCat: this.props.match.params.subvalue
          ? this.props.match.params.subvalue
          : "",
        loading: false,
        productempty: false,
        page: 1,
        Gemstone: JSON.parse(sessionStorage.getItem("list_filter"))?.Gemstone
          ? JSON.parse(sessionStorage.getItem("list_filter")).Gemstone
          : [],
        Shape: JSON.parse(sessionStorage.getItem("list_filter"))?.Shape
          ? JSON.parse(sessionStorage.getItem("list_filter")).Shape
          : [],
        MetalColor: JSON.parse(sessionStorage.getItem("list_filter"))
          ?.MetalColor
          ? JSON.parse(sessionStorage.getItem("list_filter")).MetalColor
          : [],
        Gender: JSON.parse(sessionStorage.getItem("list_filter"))?.Gender
          ? JSON.parse(sessionStorage.getItem("list_filter")).Gender
          : [],
        Style: JSON.parse(sessionStorage.getItem("list_filter"))?.Style
          ? JSON.parse(sessionStorage.getItem("list_filter")).Style
          : [],
        priceFrom: JSON.parse(sessionStorage.getItem("list_filter"))?.PriceFrom
          ? JSON.parse(sessionStorage.getItem("list_filter")).PriceFrom
          : 0,
        priceTo: JSON.parse(sessionStorage.getItem("list_filter"))?.PriceTo
          ? JSON.parse(sessionStorage.getItem("list_filter")).PriceTo
          : 50000,
      });
      // this.productApi();
      this.filterApi();
    }
    if (
      prevProps.match.params.searchvalue != this.props.match.params.searchvalue
    ) {
      this.setState({
        Category: this.props.match.params.value
          ? this.props.match.params.value
          : "",
        subCat: this.props.match.params.subvalue
          ? this.props.match.params.subvalue
          : "",
        loading: false,
        productempty: false,
        page: 1,
        Gemstone: [],
        Shape: [],
        MetalColor: [],
        Gender: [],
        Style: [],
        priceFrom: 0,
        priceTo: 50000,
      });
      // this.productApi();
      this.filterApi();
    }
    // if (this.state.Gemstone?.length || this.state.Shape?.length || this.state.MetalColor?.length || this.state.Gender?.length || this.state.Style?.length) {
    //     this.props.history.push('/category/' + this.props.match.params.menu + "/" + this.props.match.params.value);
    // }
  }
  clearfilter = () => {
    sessionStorage.removeItem("list_filter");
    this.setState({
      Gemstone: [],
      Shape: [],
      MetalColor: [],
      Style: [],
      Gender: [],
      priceTo: 50000,
      priceFrom: 0,
    });
    if (this.props.match.params.searchvalue?.length) {
      this.props.history.push(
        "/searchlist/" + this.props.match.params.searchvalue
      );
    } else {
      this.props.history.push(
        "/category/" +
        this.props.match.params.menu +
        "/" +
        this.props.match.params.value
      );
    }
  };

  checkboxcheck = (title, item) => {

    if (title == "Gemstone") {
      if (this.state.Gemstone?.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (title == "Category") {
      if (this.state.categoryFilter?.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (title == "Shape") {
      if (this.state.Shape?.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (title.replace(" ", "") == "MetalColor") {

      if (this.state.MetalColor?.includes(item)) {

        return true;
      } else {
        return false;
      }
    } else if (title == "Style") {
      if (this.state.Style?.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (title == "Gender") {
      if (this.state.Gender?.includes(item)) {
        return true;
      } else {
        return false;
      }
    }

  };

  onSliderChange = (value) => {
    this.setState({ priceFrom: value[0], priceTo: value[1] });
  };
  handlepricecheck = () => {
    if (Number(this.state.priceFrom) > Number(this.state.priceTo)) {
      this.setState({
        priceFrom: this.state.priceTo,
        priceTo: this.state.priceFrom,
      });
    }
  };
  onMinMaxChange = (e) => {
    if (e.target.name == "min") {
      this.setState({
        priceFrom: e.target.value,
      });
    } else {
      this.setState({
        priceTo: e.target.value,
      });
    }
  };

  priceFilter = (event,v,index) => {
    if(event.target.checked){
      this.setState({
        priceChecked: index
      })
    }else{
      this.setState({
        priceChecked: null
      })
    }
  
    if (this.state.priceFrom == Number(v[0]) && this.state.priceTo == Number(v[1])) {
      this.setState({
        priceFrom: "",
        priceTo: ""
      })
    } else {  
      this.setState({
        priceFrom: Number(v[0]),
        priceTo: Number(v[1])
      });
    }
    
  }
  kleviyoTracking=(val)=> {
     
    var item = {
      "user_id": val?.user_id,
      "product_id": val?.product_id,
      "session_id": val?.session_id,
      "stone_data": val?.stone_data,
      "product_type": val?.product_type,
      "ring_size": val?.ring_size,
      "quantity": val?.quantity,
      "engraving": val?.engraving,
      "jewelry_box": val?.jewelry_box,
      "engraving_image": val?.engraving_image
    };
    if(window.klaviyo){
      window.klaviyo.push(["track", "Added to Cart", item]);
    }
  }

  kleviyoTrackingWishlist=(val)=> {
     
    var item = {
      "user_id": val?.user_id,
      "product_id": val?.product_id,
      "session_id": val?.session_id,
      "stone_data": val?.stone_data
    };
    if(window.klaviyo){
      window.klaviyo.push(["track", "Added to wishlist", item]);
    }
  }

  addtowishlist = (item, wishindex) => {

    const data = {
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id
        ? JSON.parse(localStorage.getItem("user")).user_id
        : 0,
      product_id: item.id,
      session_id: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : 0,
      stone_data: item?.stone_data?.length
        ? Object.values(item?.stone_data)?.toString()?.replaceAll(",", "_")
        : "",
    };
    postData(`/order/wishlist`, data)
      .then((response) => {
        if (response.status == 1) {
          this.kleviyoTrackingWishlist(data)
          this.setState({
            wishlistIndex: wishindex
          })
          localStorage.setItem(
            "wishlistlength",
            response.data.wishlist_item_count
          );
          // this.productApi();
          const productlist = this.state.productdata.map((obj, index) =>
            index == wishindex
              ? Object.assign(obj, { wishlist_id: response.data.wishlist_id })
              : obj
          );
          this.setState({ productdata: productlist });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteitem = (id, wishindex) => {

    var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
      ? JSON.parse(localStorage.getItem("user"))?.user_id
      : "";
    var session_id = localStorage.getItem("session_id")
      ? localStorage.getItem("session_id")
      : "";
    deleteData(
      `/order/wishlist?wishlist_id=${id?.wishlist_id}&${user_id
        ? "user_id=" + user_id
        : session_id
          ? "session_id=" + session_id
          : ""
      }`
    )
      .then((response) => {
        if (response.status == 1) {
          localStorage.setItem(
            "wishlistlength",
            response.data.wishlist_item_count
          );
          const productlist = this.state.productdata.map((obj, index) =>
            index == wishindex ? Object.assign(obj, { wishlist_id: 0 }) : obj
          );
          this.setState({ productdata: productlist });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addtocart = (i) => {
    //if (!this.state.filterdata?.filter(val => val.title == "Ring Size" && val.data?.length > 0).length && this.state.RingSize != null || this.state.RingSize != "") {
    // const session_id = localStorage.getItem('session_id') ? localStorage.getItem('session_id') : "",
    const data = {
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id
        ? JSON.parse(localStorage.getItem("user")).user_id
        : 0,
      product_id: i.id,
      // cart_id: this.state.productsdata[i].id,
      session_id: localStorage.getItem("session_id")
        ? localStorage.getItem("session_id")
        : 0,
      stone_data: i.product_type === 0 ? i.stone_data.join('_') : [],
      product_type: i.product_type,
      ring_size: Number(this.state.RingSize),
      quantity: 1,
      engraving: this.state.eng_data,
      jewelry_box: this.state.giftboxid,
      engraving_image: this.state.eng_img,
    };

    postData(`/order/cartItems`, data)
      .then((response) => {
        if (response.status == 1) {
          this.kleviyoTracking(data) 
          toast.success(response.message, { autoClose: 3000 });
          this.setState({ buttonLoading: false, addcartbutton: true });
          localStorage.setItem("cartlength", response.data.cart_item_count);
          if (i == 1) {
            this.props.history.push("/cart");
          }
          document.getElementById('cartTrigger').click();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // } else {
    // this.setState({ noRingsize: true })
    //setTimeout(() => { this.setState({ noRingsize: false }) }, 2000);
    //}
  };


  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ isExpanded: isExpanded ? panel : false });
  };

  DropDown = (channel) => {
    if (this.state.showDropDown == channel) {
      this.setState({ showDropDown: "" });
    } else {
      this.setState({ showDropDown: channel });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  myRef = React.createRef();

  handleClickOutside = (e) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ showDropDown: "" });
    }
  };
  render() {
    const { classes, someData } = this.props;
    return (
      <>
        <Helmet>
          <title>
            {this.props.match.params.menu
              ? this.props.match.params.menu?.replace("shop-", "")
              : this.props.match.params.searchvalue}{" "}
            | Gem Stone King ™
          </title>
          <meta
            name="description"
            content="We are proud to offer our clientele a wide range of products with gemstones: beautiful Necklaces, Rings in a range of exciting gemstones, Bracelets and fine designer Earrings. Our quality is unsurpassed and all of our collections cater to the latest fashion trends."
          />
          <meta property="og:site_name" content="Gem Stone King ™" />
          <meta
            property="og:url"
            content="http://gsk.tasksource.in:8080/accessibility-statement"
          />
          <meta
            property="og:title"
            content={
              this.props.match.params.menu
                ? this.props.match.params.menu?.replace("shop-", "")
                : this.props.match.params.searchvalue
            }
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="We are proud to offer our clientele a wide range of products with gemstones: beautiful Necklaces, Rings in a range of exciting gemstones, Bracelets and fine designer Earrings. Our quality is unsurpassed and all of our collections cater to the latest fashion trends."
          />
        </Helmet>
        <ToastContainer limit={1} />
        <div className="gs_product_list_main">

          <div className="breadCrumb">
            <Container className="gs_customer_container_fluid" fluid>
              <nav aria-label="breadcrumb" className="breadCrumbWrapper">
                <ol className="breadcrumb bg-transparent">
                  <li className="breadcrumb-item">
                    <Link className="text-dark" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active "
                    aria-current="page"
                  >
                    {this.props?.match?.params?.menu
                      ? this.props?.match?.params?.menu.replace("-", " ")
                      : "Search Lists"}
                  </li>
                </ol>
              </nav>
              <div className="gs_product_top_heading">
                <h1>
                  {this.props.match.params.menu
                    ? this.props.match.params.menu
                      ?.replace("shop-", "")
                      .replace("-", " ")
                    : <span className="text-lowercase1">{this.props.match.params.searchvalue}</span>}
                </h1>

                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                  rerum officiis nesciunt molestiae inventore perferendis
                </p> */}
              </div>
            </Container>
          </div>
          <Container fluid>
            <Row>
              <Col className="borderTop w-100">

                <Container className="gs_customer_container_fluid" fluid>
                  <div className="gs_filter_list gs_filter_list_desc d-flex justify-content-between">
                    <ul className="gs_filter_list_content" ref={this.myRef}>

                      {this.state.Category !== "category=bestseller" && this.state.filterdata && this.state.filterdata?.map((item, index) =>
                        item.title == "Price" ? (
                          <li
                            onClick={() =>
                              this.setState({ headerClass: item.title })
                            }
                            className={`${this.state.headerClass === item.title &&
                              "activeClass"
                              } ${item.title}`}
                          >
                            <strong
                              className="text-uppercase"
                              onClick={() => this.DropDown("Price")}
                            >
                              Price{" "}
                              {this.state.showDropDown == "Price"
                                ? AngleTop
                                : AngleBottom}
                            </strong>
                            {this.state.showDropDown == "Price" ? (
                              <Container>
                                <div className="gs_filter_list_content_inner gs_price">
                                  <Row className="justify-content-center">
                                    <Col lg={6}>

                                      <Row>
                                    
                                        {
                                          this.state.allPrice.length > 0 && this.state.allPrice.map((e, i) => (
                                            <Col lg={3} key={i}>
                                              <div className="form-check pl-0 mb-2 d-flex"><input checked={this.state.priceChecked===i}  onChange={(event) => this.priceFilter(event,e?.name,i)} name="pricefilter" id={`price${i}`} type="checkbox" className="form-check-input" />
                                                <label className="checkboxStyle" htmlFor={`price${i}`}></label>
                                                <label htmlFor={`price${i}`} className="form-check-label pointer">{e?.display_name} </label>
                                              </div>

                                            </Col>

                                          ))
                                        }

                                      </Row>



                                      {/* <Nouislider
                                        start={[
                                          this.state.priceFrom,
                                          this.state.priceTo,
                                        ]}
                                        range={{
                                          min: item.data[0],
                                          max: item.data[1],
                                        }}
                                        connect={true}
                                        step={5}
                                        tooltips={false}
                                        onChange={(value) =>
                                          this.onSliderChange(value)
                                        }
                                      /> */}
                                      {/* <Row className="w-100 m-0 mb-4 mt-3 gs_resulslider">
                                        <Col className="mt-2" xs={6}>
                                          <InputGroup>
                                            <InputGroup.Text>
                                              {localStorage.getItem(
                                                "currency_text"
                                              )}
                                            </InputGroup.Text>
                                            <FormControl
                                              aria-label="Amount (to the nearest dollar)"
                                              type="int"
                                              name="min"
                                              value={this.state.priceFrom}
                                              onChange={(e) =>
                                                this.onMinMaxChange(e)
                                              }
                                              onBlurCapture={() =>
                                                this.handlepricecheck()
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                        <Col className="mt-2" xs={6}>
                                          <InputGroup>
                                            <InputGroup.Text>
                                              {localStorage.getItem(
                                                "currency_text"
                                              )}
                                            </InputGroup.Text>
                                            <FormControl
                                              aria-label="Amount (to the nearest dollar)"
                                              type="int"
                                              name="max"
                                              value={this.state.priceTo}
                                              onChange={(e) =>
                                                this.onMinMaxChange(e)
                                              }
                                              onBlurCapture={() =>
                                                this.handlepricecheck()
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                      </Row> */}
                                    </Col>
                                  </Row>
                                </div>{" "}
                              </Container>
                            ) : (
                              ""
                            )}
                          </li>
                        ) : (
                          <li
                            onClick={() =>
                              this.setState({ headerClass: item.title })
                            }
                            className={`prodFilter ${this.state.headerClass === item.title &&
                              "activeClass"
                              } ${item.title}`}
                          >
                            <strong
                              className="text-uppercase"
                              onClick={() => this.DropDown(item.title)}
                            >
                              {item.title}{" "}
                              {this.state.showDropDown == item.title
                                ? AngleTop
                                : AngleBottom}
                            </strong>
                            {this.state.showDropDown == item.title ? (
                              <div
                                className={
                                  item.title == "Gender"
                                    ? "gs_filter_list_content_inner gs_gender"
                                    : "gs_filter_list_content_inner"
                                }
                              >
                                <Container
                                  className="gs_customer_container_fluid"
                                  fluid
                                >

                                  <ul className="row gs_list_filter pt-4 pb-4">
                                    {item.data.length > 0 && item.data?.map((subitem, index) => (


                                      <li
                                        className={` ${item.title === "Shape"
                                          ? "shapeType mb-2 "
                                          : item.title === "Gender"
                                            ? "col-md-1" : item.title === "Gemstone" ? "col-lg-1-5"
                                              : "col-md-2"
                                          } ${item.title.replace(" ", "_")}`}
                                      >
                                        <div
                                          className={`form-check pl-0 mb-2 ${item.title === "Shape"
                                            ? "text-center"
                                            : ""
                                            }`}
                                        >
                                          {item.title === "Shape" && (
                                            <label
                                              htmlFor={`filter${subitem?.id}`}
                                              className="mb-2 d-block pointer"
                                            >
                                              {item.title == "Gemstone" ? (
                                                <Image
                                                  src={base_url + subitem.image}
                                                  alt=""
                                                ></Image>
                                              ) : item.title == "Shape" ? (
                                                <Image
                                                  src={base_url + subitem.image}
                                                  alt=""
                                                ></Image>
                                              ) : item.title ==
                                                "Metal Color" ? (
                                                <Image
                                                  src={base_url + subitem.image}
                                                  alt=""
                                                ></Image>

                                              ) : (
                                                ""
                                              )}
                                            </label>
                                          )}
                                          <input
                                            defaultChecked={this.checkboxcheck(
                                              item.title,
                                              subitem.name,
                                              subitem.display_name
                                            )}

                                            onClick={(e) =>
                                              this.handlecheckChange(
                                                e,
                                                item.title,
                                                subitem.name,
                                                subitem.display_name
                                              )
                                            }


                                            name="dt2"
                                            id={`filter${subitem?.id}`}
                                            type="radio"
                                            className={`form-check-input ${this.state.radioCheck ? 'removed' : 'added'}`}
                                          />
                                          <label htmlFor={`filter${subitem?.id}`} className="checkboxStyle">

                                          </label>
                                          <label
                                            htmlFor={`filter${subitem?.id}`}
                                            className="form-check-label pointer"
                                          >
                                            {item.title !== "Shape" && (
                                              <>
                                                {item.title == "Gemstone" ? (
                                                  <span className="mr-1">
                                                    <Image
                                                      src={
                                                        base_url + subitem.image
                                                      }
                                                      alt=""
                                                    ></Image>
                                                  </span>
                                                ) : item.title == "Shape" ? (
                                                  <span className="mr-1">
                                                    <Image
                                                      src={
                                                        base_url + subitem.image
                                                      }
                                                      alt=""
                                                    ></Image>
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )}
                                            <span className="filterSubItem">
                                              {subitem.display_name}
                                            </span>{" "}
                                          </label>
                                        </div>
                                        {/* <FormGroup className="gs_acc_checkbox">
                                                                        <FormControlLabel className="gs_gender_filter" control={<Checkbox checked={this.checkboxcheck(item.title, subitem.name)} onChange={(e) => this.handlecheckChange(e, item.title, subitem.name)} />}
                                                                            label={subitem.display_name} >
                                                                            </FormControlLabel>
                                                                    </FormGroup> */}
                                      </li>
                                    ))}
                                  </ul>
                                </Container>
                              </div>
                            ) : (
                              ""
                            )}
                          </li>
                        )
                      )}

                      <li className="">
                        {this.state.Gemstone?.length ||
                          this.state.Shape?.length ||
                          this.state.MetalColor?.length ||
                          this.state.Gender?.length ||
                          this.state.Style?.length ||
                          this.state.priceFrom > this.state.minprice ||
                          this.state.priceTo < this.state.maxprice ? (
                          <Button
                            className="text-uppercase fs13 fw400 text-dark fw600 noBtn"
                            onClick={this.clearfilter}
                          >
                            Clear All
                          </Button>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>

                    <div>
                      <select className="form-control sortBy" onChange={(e) => this.setState({ 'sortby': e.target.value })}>
                        <option value={0}>Recommended</option>
                        <option value={1}>Price : Low to High</option>
                        <option value={2}>Price : High to Low</option>
                        <option value={3}>New Arrivals</option>
                        <option value={4}>Most Sold</option>
                      </select>
                    </div>
                  </div>
                </Container>

                <div
                  className="gs_accordion_sec gs_filter_list_mob"
                  sticky="top"
                >
                  <div className={classes.root}>
                    <div className="position-relative d-block d-lg-none">
                      {/* <p
                        className="gs_show_result"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "-19px",
                          fontSize: "14px",
                        }}
                      >
                        Results : {this.state.totalProductCount}
                      </p> */}
                    </div>
                    {this.state.showFilter ? (
                      <>
                        {isMobile ? (
                          <h4
                            onClick={() => this.setState({ showFilter: false })}
                          >
                            Hide Filter <ArrowDropUpIcon />
                          </h4>
                        ) : (
                          <h4> Filter</h4>
                        )}
                        {this.state.filterdata?.map((item, index) => (
                          <Accordion
                            expanded={this.state.isExpanded === "panel" + index}
                            onChange={this.handleChange("panel" + index)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <h3 className="gs_accord_title">{item.title}</h3>
                            </AccordionSummary>
                            {item.title == "Price" ? (
                              <>
                                <AccordionDetails className="gs_price_slider gs_list_priceslider">
                                  {/* <Nouislider
                                    start={[
                                      this.state.priceFrom,
                                      this.state.priceTo,
                                    ]}
                                    range={{
                                      min: item.data[0],
                                      max: item.data[1],
                                    }}
                                    connect={true}
                                    step={5}
                                    tooltips={false}
                                    onChange={(value) =>
                                      this.onSliderChange(value)
                                    }
                                  /> */}

                                  <Row>
                                    {
                                      this.state.allPrice.length > 0 && this.state.allPrice.map((e, i) => (
                                        <Col xs={6} lg={3} key={i}>
                                          <div className="form-check pl-0 mb-2 fsMobCheck14"><input checked={this.state.priceChecked===i} onChange={(event) => this.priceFilter(event,e?.name,i)} name="pricefilter" id={`price${i}`} type="checkbox" className="form-check-input" />
                                            <label className="checkboxStyle" htmlFor={`price${i}`}></label>
                                            <label htmlFor={`price${i}`} className="form-check-label pointer">{e?.display_name} </label>
                                          </div>


                                       
                                        </Col>

                                      ))
                                    }

                                  </Row>
                                  {/* 
                                  <Row className="w-100 m-0 mb-4 mt-3 gs_resulslider">
                                    <Col className="mt-2" xs={6}>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          {localStorage.getItem(
                                            "currency_text"
                                          )}
                                        </InputGroup.Text>
                                        <FormControl
                                          aria-label="Amount (to the nearest dollar)"
                                          type="int"
                                          name="min"
                                          value={this.state.priceFrom}
                                          onChange={(e) =>
                                            this.onMinMaxChange(e)
                                          }
                                          onBlurCapture={() =>
                                            this.handlepricecheck()
                                          }
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col className="mt-2" xs={6}>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          {localStorage.getItem(
                                            "currency_text"
                                          )}
                                        </InputGroup.Text>
                                        <FormControl
                                          aria-label="Amount (to the nearest dollar)"
                                          type="int"
                                          name="max"
                                          value={this.state.priceTo}
                                          onChange={(e) =>
                                            this.onMinMaxChange(e)
                                          }
                                          onBlurCapture={() =>
                                            this.handlepricecheck()
                                          }
                                        />
                                      </InputGroup>
                                    </Col>
                                  </Row> */}
                                </AccordionDetails>
                              </>
                            ) : (
                              <>
                                {item.data?.map((subitem) => (
                                  <AccordionDetails>
                                    <Typography>
                                      <FormGroup className="gs_acc_checkbox">
                                        <FormControlLabel
                                          className={
                                            item.title == "Gender" ||
                                              item.title == "Style"
                                              ? "gs_gender_filter"
                                              : ""
                                          }
                                          control={
                                            <Checkbox
                                              checked={this.checkboxcheck(
                                                item.title,
                                                subitem.name
                                              )}
                                              onChange={(e) =>
                                                this.handlecheckChange(
                                                  e,
                                                  item.title,
                                                  subitem.name
                                                )
                                              }
                                            />
                                          }
                                          label={subitem.display_name}
                                        />
                                        {item.title == "Gemstone" ? (
                                          <Image
                                            className="gs_filter_plcholder"
                                            src={base_url + subitem.image}
                                            alt=""
                                          ></Image>
                                        ) : item.title == "Shape" ? (
                                          <Image
                                            className="gs_filter_plcholder1"
                                            src={base_url + subitem.image}
                                            alt=""
                                          ></Image>
                                        ) : item.title == "Metal Color" ? (
                                          <Image
                                            className="gs_filter_plcholder2"
                                            src={base_url + subitem.image}
                                            alt=""
                                          ></Image>
                                        ) : (
                                          ""
                                        )}
                                      </FormGroup>
                                    </Typography>
                                  </AccordionDetails>
                                ))}
                              </>
                            )}
                          </Accordion>
                        ))}
                      </>
                    ) : (isMobile && this.state.Category !== "category=bestseller") ? (
                      <h4
                        className="gs_show_filter"
                        onClick={() => this.setState({ showFilter: true })}
                      >
                        Show Filter <ArrowDropDownIcon />
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="gs_breadcrumb">
                  {
                    someData &&
                    <Row className="w-100 m-0 justify-content-between">
                      <Col sm={10} className={`mt-3`}>

                        {this.state.Gemstone?.map((val) =>
                          val != "" ? (
                            <Button className="gs_filter_btn">
                              {
                                this.state.gemstoneDisplayName[0] === undefined ? val : this.state.gemstoneDisplayName[0]
                              }
                              <CloseIcon
                                onClick={(e) =>
                                  this.handlecheckChange(e, "Gemstone", val)
                                }
                              />
                            </Button>
                          ) : (
                            this.setState({ Gemstone: [] })
                          )
                        )}
                        {this.state.Shape?.map((val) =>
                          val != "" ? (
                            <Button className="gs_filter_btn">
                              {
                                this.state.shapeDisplayName[0] === undefined ? val : this.state.shapeDisplayName[0]

                              }
                              <CloseIcon
                                onClick={(e) =>
                                  this.handlecheckChange(e, "Shape", val)
                                }
                              />
                            </Button>
                          ) : (
                            this.setState({ Shape: [] })
                          )
                        )}
                        {this.state.MetalColor?.map((val) =>
                          val != "" ? (
                            <Button className="gs_filter_btn">
                              {this.state.metalDisplayName[0] === undefined ? val : this.state.metalDisplayName[0]}
                              <CloseIcon
                                onClick={(e) =>
                                  this.handlecheckChange(e, "MetalColor", val)
                                }
                              />
                            </Button>
                          ) : (
                            this.setState({ MetalColor: [] })
                          )
                        )}
                        {this.state.Gender?.map((val) =>
                          val != "" ? (
                            <Button className="gs_filter_btn">
                              {val}

                              <CloseIcon
                                onClick={(e) =>
                                  this.handlecheckChange(e, "Gender", val)
                                }
                              />
                            </Button>
                          ) : (
                            this.setState({ Gender: [] })
                          )
                        )}
                        {this.state.Style?.map((val) =>
                          val != "" ? (
                            <Button className="gs_filter_btn">

                              {this.state.styleDisplayName[0] === undefined ? val : this.state.styleDisplayName[0]
                              }
                              <CloseIcon
                                onClick={(e) =>
                                  this.handlecheckChange(e, "Style", val)
                                }
                              />
                            </Button>
                          ) : (
                            this.setState({ Style: [] })
                          )
                        )}

                        {this.state.priceFrom > this.state.minprice ||
                          this.state.priceTo < this.state.maxprice ? (
                          <Button
                            className="gs_filter_btn"
                            onClick={(e) =>
                              this.setState({
                                priceFrom: this.state.minprice,
                                priceTo: this.state.maxprice,
                              })
                            }
                          >
                            {localStorage.getItem("currency_text")}
                            {this.state.priceFrom} -{" "}
                            {localStorage.getItem("currency_text")}
                            {this.state.priceTo}
                            <CloseIcon />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.Gemstone?.length ||
                          this.state.Shape?.length ||
                          this.state.MetalColor?.length ||
                          this.state.Gender?.length ||
                          this.state.Style?.length ||
                          this.state.priceFrom > this.state.minprice ||
                          this.state.priceTo < this.state.maxprice ? (
                          <Button
                            className="gs_filter_btn1"
                            onClick={this.clearfilter}
                          >
                            Clear All
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col sm={2} className="d-none d-lg-block mt-3">
                        {/* <p className="gs_show_result">
                        {" "}
                        Results : {this.state.totalProductCount}
                      </p> */}
                      </Col>
                    </Row>
                  }

                </div>

                {this.state.productempty ? (
                  <Row className="w-100 m-auto d-block">
                    <div className="gs_no_prod">
                      <Image
                        className="css-9whsf3"
                        src={NoProduct}
                        alt=""
                      ></Image>
                    </div>
                    <Divider />
                    <ul className="gs_no_prod_item mt-5 mb-5">
                      <li
                        onClick={() => {
                          this.clearfilter();
                          this.props.history.push(
                            "/category/shop-rings/category=ring"
                          );
                        }}
                      >
                        <img className="gs_noprod_img" src={gs_ringsicon} />
                        Shop Rings
                      </li>
                      <li
                        onClick={() => {
                          this.clearfilter();
                          this.props.history.push(
                            "/category/shop-pendants/category=pendant"
                          );
                        }}
                      >
                        <img className="gs_noprod_img" src={gs_pendanticon} alt="" />
                        Shop Pendants
                      </li>
                      <li
                        onClick={() => {
                          this.clearfilter();
                          this.props.history.push(
                            "/category/shop-earrings/category=earrings"
                          );
                        }}
                      >
                        <img className="gs_noprod_img" src={gs_earringicon} alt="" />
                        Shop Earrings
                      </li>
                      <li
                        onClick={() => {
                          this.clearfilter();
                          this.props.history.push(
                            "/category/shop-bracelets/category=bracelet"
                          );
                        }}
                      >
                        <img className="gs_noprod_img" src={gs_braceletsicon} />
                        Shop Bracelets
                      </li>
                    </ul>
                  </Row>
                ) : (
                  <>

                    <div className="gs_product_list_section">

                      <div className="parent">
                        {this.state.firstrender
                          ? this.state.productdata?.map((item, index) => (
                            item?.status_id == 1 && <div
                              className={
                                this.props.match.params.menu?.replace(
                                  "shop-",
                                  ""
                                ) == "PENDANTS"
                                  ? "child list_pendants"
                                  : "child"
                              }
                            >
                              <div className="gs_product_list1">
                                <div className="gsk_product_box_view">
                                  <div className="gsk_product_heart_btn">
                                    <div className="flex">
                                      {
                                        item.product_type === 0 && <Button
                                          className="gsk_customize"
                                          onClick={(e) => this.openDetail(e, item)}
                                        >
                                          Customize
                                        </Button>
                                      }

                                      {
                                        (item?.wishlist_id > 0) ?

                                          <Button
                                            className="gsk_heart"
                                            onClick={() =>
                                              this.deleteitem(item, index)
                                            }
                                          >
                                            <AiTwotoneHeart color="#F765A3" size='21px' />
                                          </Button> : <Button
                                            className="gsk_heart"
                                            onClick={() =>
                                              this.addtowishlist(item, index)
                                            }
                                          >
                                            <BsHeart color="#F765A3" />
                                          </Button>
                                      }


                                      {/* <Button
                                      className="gsk_heart"
                                      onClick={() =>
                                        this.addtowishlist(item, index+1)
                                      }
                                    >
                                    {
                                      (this.state.wishlistIndex==index+1 || item?.wishlist_id > 0)  ? <AiTwotoneHeart color="#F765A3" size='21px'/> :   <BsHeart color="#F765A3" />
                                    }
                                     
                                    </Button> */}
                                    </div>
                                  </div>

                                  <div
                                    className="gsk_product_box_view_img pointer"
                                    onClick={(e) => this.openDetail(e, item)}
                                  >
                                    <div
                                      onMouseOver={() =>
                                        this.setState({ proIndex: index + 1 })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({ proIndex: "" })
                                      }
                                    >
                                      {item.product_image?.length > 1 ? (
                                        <>
                                          {this.state.proIndex ==
                                            index + 1 ? (
                                            <Image
                                              style={{
                                                display: this.state.loading
                                                  ? "block"
                                                  : "none",
                                              }}
                                              onLoad={() =>
                                                this.setState({
                                                  loading: true,
                                                })
                                              }
                                              src={item.product_image?.[1]}
                                              alt=""
                                            ></Image>
                                          ) : (
                                            <Image
                                              onClick={(e) =>
                                                this.openDetail(e, item)
                                              }
                                              style={{
                                                display: this.state.loading
                                                  ? "block"
                                                  : "none",
                                              }}
                                              onLoad={() =>
                                                this.setState({
                                                  loading: true,
                                                })
                                              }
                                              src={item.product_image?.[0]}
                                              alt=""
                                            ></Image>
                                          )}
                                        </>
                                      ) : (
                                        <Image
                                          onClick={(e) =>
                                            this.openDetail(e, item)
                                          }
                                          style={{
                                            display: this.state.loading
                                              ? "block"
                                              : "none",
                                          }}
                                          onLoad={() =>
                                            this.setState({ loading: true })
                                          }
                                          src={item.product_image?.[0]}
                                          alt=""
                                        ></Image>
                                      )}
                                    </div>
                                  </div>

                                  <div className="gsk_product_box_view_text d_font">
                                    <h3
                                      onClick={(e) => this.openDetail(e, item)}
                                      className="pointer"
                                    >
                                      {item.product_name?.substring(0, 50)}
                                    </h3>
                                    <p>
                                      {item.currency_icon}
                                      {item.sale_price}
                                    </p>
                                    <Button
                                      className="gsk_product_addToBag_btn"
                                      onClick={() => this.addtocart(item)}
                                    >
                                      {" "}
                                      Add to bag
                                    </Button>
                                  </div>
                                </div>

                                {/* <div className=" gs_product_list">
                                                            <div className="gs_wishlist_icon">
                                                                <IconButton style={{ display: this.state.loading ? "block" : "none" }} color="primary" aria-label="upload picture" component="span" className="animate__animated">
                                                                    {item.wishlist_id == 0 ? <FavoriteBorderIcon style={{ display: this.state.loading ? "block" : "none" }} onClick={() => this.addtowishlist(item, index)} /> :
                                                                        ''}
                                                                </IconButton>
                                                            </div>
                                                            <div className="gs_quick_wrapper">
                                                                <Button onClick={() => { this.setState({ quickveiw: true, quickveiwdata: item, quickveiwstone_data: Object.values(item.stone_data)?.toString()?.replaceAll(",", "_"), quickveiwindex: index }); }}>
                                                                    <PageviewOutlinedIcon className="gs_quick_icons" />
                                                                </Button>
                                                            </div>
                                                            {item?.product_type == "0" ? <div className='gs_customized_btn'>
                                                                <span>customize me</span>
                                                            </div> : ""}
                                                            {item.wishlist_id == 0 ? "" : <div className="gs_wishlist_icon1"><FavoriteIcon className="gs_wish_active animate__heartBeat" onClick={() => this.deleteitem(item.wishlist_id, index)} /></div>}
                                                            <div className="gs_productlist_content" onClick={() => this.openDetail(item)}>
                                                             
                                                                <div onMouseOver={()=> this.setState({proIndex: index + 1})} onMouseLeave={()=> this.setState({proIndex: ''})} >
                                                                    {item.product_image?.length > 1 ?
                                                                        <>
                                                                            {this.state.proIndex == index + 1 ?
                                                                                <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={item.product_image[1]} alt=""></Image> :
                                                                                <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={item.product_image[0]} alt=""></Image>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Image style={{ display: this.state.loading ? "block" : "none" }} onLoad={() => this.setState({ loading: true })} src={item.product_image[0]} alt=""></Image>
                                                                    }
                                                                </div>
                                                                <h2 style={{ display: this.state.loading ? "block" : "none" }}>{item.product_name?.substring(0, 50)}</h2>
                                                                <span style={{ display: this.state.loading ? "block" : "none" }}>{item.currency_icon}{item.sale_price}</span>
                                                            </div>
                                                        </div> */}
                              </div>
                            </div>

                          ))
                          : [...Array(16)].map((item, index) => (
                            <div className="child" key={'sk' + index}>
                              <div className="gs_product_list1">
                                <div className=" gs_product_list">
                                  <div className="gs_productlist_content">

                                    {isMobileOnly ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="rect"
                                        animation="wave"
                                        width={130}
                                        height={130}
                                      />
                                    ) : isTablet ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="rect"
                                        animation="wave"
                                        width={350}
                                        height={350}
                                      />
                                    ) : (
                                      <Skeleton
                                        className="gs_product_list_skelton"
                                        style={{ display: "block" }}
                                        variant="rect"
                                        animation="wave"
                                        width="100%"
                                        height={350}
                                      />
                                    )}
                                    {isMobileOnly ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="text"
                                        animation="wave"
                                        width={130}
                                        height={20}
                                      />
                                    ) : isTablet ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="text"
                                        animation="wave"
                                        width={350}
                                        height={20}
                                      />
                                    ) : (
                                      <Skeleton
                                        className="gs_product_list_skelton1"
                                        style={{ display: "block" }}
                                        variant="text"
                                        animation="wave"
                                        width={280}
                                        height={20}
                                      />
                                    )}
                                    {isMobileOnly ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="text"
                                        animation="wave"
                                        width={80}
                                        height={20}
                                      />
                                    ) : isTablet ? (
                                      <Skeleton
                                        style={{ display: "block" }}
                                        variant="text"
                                        animation="wave"
                                        width={150}
                                        height={20}
                                      />
                                    ) : (
                                      <Skeleton
                                        style={{
                                          display: this.state.loading
                                            ? "none"
                                            : "block",
                                        }}
                                        variant="text"
                                        animation="wave"
                                        width={200}
                                        height={20}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className="creative0">
                          <div
                            style={{
                              transition:
                                "opacity 600ms ease 0s, -webkit-transform 600ms ease 0s",
                              opacity: 1,
                              display: "block",
                              transform: "translateY(0px)",
                            }}
                          >
                            <div className="css-1s1yre3">
                              <div className="css-1dawyn9">
                                <picture className="css-1gli8yj">
                                  {this.state.productdata.length > 6 ||
                                    this.state.firstrender == false ? (
                                    this.props.match.params.menu ==
                                      "rings" ? (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImageRing}
                                        alt=""
                                      ></Image>
                                    ) : this.props.match.params.menu ==
                                      "earrings" ? (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImageEarring}
                                        alt=""
                                      ></Image>
                                    ) : this.props.match.params.menu ==
                                      "bracelets" ? (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImageBracelets}
                                        alt=""
                                      ></Image>
                                    ) : this.props.match.params.menu ==
                                      "pendants" ? (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImage3}
                                        alt=""
                                      ></Image>
                                    ) : this.props.match.params.menu ==
                                      "men's" ? (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImage5}
                                        alt=""
                                      ></Image>
                                    ) : (
                                      <Image
                                        className="css-9whsf3"
                                        src={ProductImage4}
                                        alt=""
                                      ></Image>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </picture>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gs_pagination">
                      {this.state.productCount > 1 ? (
                        <Pagination
                          count={this.state.productCount}
                          page={this.state.page}
                          onChange={this.handlePageChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {/* Quick view modal popup */}
        <Modal
          className="gs_login_modal gs_quick_view_popup gs_appointment_modal gs_quick_view_modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.quickveiw}
          onHide={() => {
            this.setState({ quickveiw: false });
          }}
        >
          <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <div className="gs_quick_image">
                  <Image src={this.state.quickveiwdata?.img_url}></Image>
                </div>{" "}
              </Col>

              <Col sm={6}>
                <div className="gs_quick_content">
                  <div className="gs_product_details_info">
                    <h1>{this.state.quickveiwdata?.product_name}</h1>
                    <span className="gs_product_price">
                      {this.state.quickveiwdata?.currency_icon}
                      {this.state.quickveiwdata?.sale_price}
                    </span>
                    <div className="gs_social_media text-left">
                      <ul>
                        <li>
                          <FacebookShareButton
                            url={
                              web_base_url +
                              "/productdetail/" +
                              this.state.quickveiwdata?.product_slug +
                              "/" +
                              this.state.quickveiwdata?.variant +
                              "/" +
                              this.state.quickveiwstone_data
                            }
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={30} round />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <PinterestShareButton
                            url={
                              web_base_url +
                              "/productdetail/" +
                              this.state.quickveiwdata?.product_slug +
                              "/" +
                              this.state.quickveiwdata?.variant +
                              "/" +
                              this.state.quickveiwstone_data
                            }
                            media={
                              web_base_url +
                              "/productdetail/" +
                              this.state.quickveiwdata?.product_slug +
                              "/" +
                              this.state.quickveiwdata?.variant +
                              "/" +
                              this.state.quickveiwstone_data
                            }
                            windowWidth={1000}
                            windowHeight={730}
                          >
                            <PinterestIcon size={30} round />
                          </PinterestShareButton>
                        </li>
                        <li>
                          <EmailShareButton
                            subject={"Gemstoneking"}
                            body={
                              web_base_url +
                              "/productdetail/" +
                              this.state.quickveiwdata?.product_slug +
                              "/" +
                              this.state.quickveiwdata?.variant +
                              "/" +
                              this.state.quickveiwstone_data
                            }
                          >
                            <EmailIcon size={30} round />
                          </EmailShareButton>{" "}
                        </li>
                        <li>
                          <TwitterShareButton
                            title={"Gemstoneking"}
                            url={
                              web_base_url +
                              "/productdetail/" +
                              this.state.quickveiwdata?.product_slug +
                              "/" +
                              this.state.quickveiwdata?.variant +
                              "/" +
                              this.state.quickveiwstone_data
                            }
                          >
                            <TwitterIcon size={30} round />
                          </TwitterShareButton>
                        </li>
                      </ul>
                    </div>
                    <Button
                      onClick={() =>
                        this.addtowishlist(
                          this.state.quickveiwdata,
                          this.state.quickveiwindex
                        )
                      }
                      variant="outlined"
                      className="gs_save_btn"
                    >
                      ADD TO WISH LIST
                    </Button>
                    <Button
                      className="gs_save_btn gs_engraving_btn mt-3"
                      onClick={() => {
                        this.openDetail(this.state.quickveiwdata);
                      }}
                    >
                      View Product
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    someData: state?.menuAction,
  };
};
//export default withStyles(style)(Productlist);
export default connect(mapStateToProps)(withStyles(style)(Productlist));