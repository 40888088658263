
export const AngleBottom=<svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 23.616 13.503">
<path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M18,20.679l8.93-8.937a1.681,1.681,0,0,1,2.384,0,1.7,1.7,0,0,1,0,2.391L19.2,24.258a1.685,1.685,0,0,1-2.327.049L6.68,14.14a1.688,1.688,0,0,1,2.384-2.391Z" transform="translate(-6.188 -11.246)"/>
</svg>

export const AngleTop=<svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 23.619 13.503">
<path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M18,15.321l8.93,8.937a1.688,1.688,0,1,0,2.384-2.391L19.2,11.742a1.685,1.685,0,0,0-2.327-.049L6.68,21.86a1.688,1.688,0,0,0,2.384,2.391Z" transform="translate(-6.188 -11.251)"/>
</svg>

export const ShippingIcon= <svg xmlns="http://www.w3.org/2000/svg"  fill='#3e3e3e' width="18" height="18" viewBox="0 0 33 24">
<path id="Icon_material-local-shipping" data-name="Icon material-local-shipping" d="M30,12H25.5V6H4.5a3.009,3.009,0,0,0-3,3V25.5h3a4.5,4.5,0,0,0,9,0h9a4.5,4.5,0,0,0,9,0h3V18ZM9,27.75a2.25,2.25,0,1,1,2.25-2.25A2.247,2.247,0,0,1,9,27.75Zm20.25-13.5L32.19,18H25.5V14.25ZM27,27.75a2.25,2.25,0,1,1,2.25-2.25A2.247,2.247,0,0,1,27,27.75Z" transform="translate(-1.5 -6)"/>
</svg>

export const QuestionIcon= <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 30.849 30.849">
<path id="Icon_metro-question" data-name="Icon metro-question" d="M16.067,23.136h3.856v3.856H16.067Zm7.712-13.5a1.928,1.928,0,0,1,1.928,1.928v5.784l-5.784,3.856H16.067V19.28l5.784-3.856V13.5h-9.64V9.64H23.779ZM17.995,4.82a12.532,12.532,0,1,0,8.862,3.671A12.45,12.45,0,0,0,17.995,4.82Zm0-2.892A15.424,15.424,0,1,1,2.571,17.352,15.424,15.424,0,0,1,17.995,1.928Z" transform="translate(-2.571 -1.928)"/>
</svg>

export const HeartEmpty=<svg xmlns="http://www.w3.org/2000/svg" fill='#3e3e3e' width="14" height="14" viewBox="0 0 29.25 28.125">
<path id="Icon_ionic-ios-heart-empty" data-name="Icon ionic-ios-heart-empty" d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Zm2.925,16.116A53.929,53.929,0,0,1,18,29.587a54.01,54.01,0,0,1-9.675-9.541,15.169,15.169,0,0,1-2.981-8.163,5.966,5.966,0,0,1,5.92-5.97h.063a5.9,5.9,0,0,1,2.89.759,6.144,6.144,0,0,1,2.137,2,1.975,1.975,0,0,0,3.3,0,6.205,6.205,0,0,1,2.138-2,5.9,5.9,0,0,1,2.89-.759h.063a5.966,5.966,0,0,1,5.92,5.97A15.361,15.361,0,0,1,27.675,20.053Z" transform="translate(-3.375 -3.938)"/>
</svg>

export const HeartFilled=<svg xmlns="http://www.w3.org/2000/svg" fill='#3e3e3e' width="14" height="14" viewBox="0 0 29.25 28.125">
<path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Z" transform="translate(-3.375 -3.938)"/>
</svg>

export const share= <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 30 33">
<g id="Icon_feather-share-2" data-name="Icon feather-share-2" transform="translate(-3 -1.5)">
  <path id="Path_1" data-name="Path 1" d="M31.5,7.5A4.5,4.5,0,1,1,27,3,4.5,4.5,0,0,1,31.5,7.5Z" fill="none" stroke="#3e3e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  <path id="Path_2" data-name="Path 2" d="M13.5,18A4.5,4.5,0,1,1,9,13.5,4.5,4.5,0,0,1,13.5,18Z" fill="none" stroke="#3e3e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  <path id="Path_3" data-name="Path 3" d="M31.5,28.5A4.5,4.5,0,1,1,27,24,4.5,4.5,0,0,1,31.5,28.5Z" fill="none" stroke="#3e3e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  <path id="Path_4" data-name="Path 4" d="M12.885,20.265l10.245,5.97" fill="none" stroke="#3e3e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  <path id="Path_5" data-name="Path 5" d="M23.115,9.765l-10.23,5.97" fill="none" stroke="#3e3e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</g>
</svg>

export const callIcon=<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 29.797 29.833">
<path id="Icon_zocial-call" data-name="Icon zocial-call" d="M3.328,8.6a2.121,2.121,0,0,1,.54-1.152l4.32-4.32q.5-.432.756.144l3.492,6.552a.943.943,0,0,1-.18,1.116l-1.584,1.584a1.791,1.791,0,0,0-.5,1.116,5.5,5.5,0,0,0,1.116,2.772,23.672,23.672,0,0,0,2.2,2.881L14.6,20.448c.336.336.767.75,1.3,1.242A21.734,21.734,0,0,0,18.5,23.634a5.839,5.839,0,0,0,2.862,1.206,1.567,1.567,0,0,0,1.152-.468L24.389,22.5a.791.791,0,0,1,1.079-.144l6.3,3.708a.526.526,0,0,1,.288.378.46.46,0,0,1-.144.414l-4.32,4.32a2.116,2.116,0,0,1-1.151.539,8.709,8.709,0,0,1-3.979-.486,18,18,0,0,1-4.1-1.962q-1.89-1.223-3.51-2.483t-2.592-2.16l-.936-.9q-.359-.359-.954-.99T8.3,20.25A36.155,36.155,0,0,1,5.74,16.632a21.106,21.106,0,0,1-1.872-4.014A8.539,8.539,0,0,1,3.328,8.6Z" transform="translate(-2.767 -2.446)" fill="#3e3e3e" stroke="#3e3e3e" strokeWidth="1"/>
</svg>

export const envelopIcon=<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 37 28">
<path id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M35.318,13.416a.423.423,0,0,1,.682.33V28.125A3.376,3.376,0,0,1,32.625,31.5H3.375A3.376,3.376,0,0,1,0,28.125V13.753a.421.421,0,0,1,.682-.33C2.257,14.646,4.345,16.2,11.517,21.41,13,22.493,15.5,24.771,18,24.757c2.51.021,5.063-2.306,6.49-3.347C31.662,16.2,33.743,14.639,35.318,13.416ZM18,22.5c1.631.028,3.98-2.053,5.161-2.911,9.33-6.771,10.041-7.362,12.192-9.049A1.683,1.683,0,0,0,36,9.211V7.875A3.376,3.376,0,0,0,32.625,4.5H3.375A3.376,3.376,0,0,0,0,7.875V9.211A1.693,1.693,0,0,0,.647,10.54c2.152,1.68,2.862,2.278,12.192,9.049C14.02,20.447,16.369,22.528,18,22.5Z" transform="translate(0.5 -4)" fill="#3e3e3e" stroke="#3e3e3e" strokeWidth="1"/>
</svg>

export const crossIcon=<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 14.527 14.523">
<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-10.785 -10.789)" fill="#ababab" stroke="#ababab" strokeWidth="1"/>
</svg>


export const fbIcon=<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20.281 37">
<path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M19.627,20.25l1-6.515H14.375V9.507c0-1.782.873-3.52,3.673-3.52h2.842V.44A34.658,34.658,0,0,0,15.846,0C10.7,0,7.332,3.12,7.332,8.769v4.965H1.609V20.25H7.332V36h7.043V20.25Z" transform="translate(-1.109 0.5)" fill="#3e3e3e" stroke="#3e3e3e" strokeWidth="1"/>
</svg>

export const pinterestIcon=<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 36.083">
  <path id="Icon_awesome-pinterest-p" data-name="Icon awesome-pinterest-p" d="M14.344.457C7.13.457,0,5.266,0,13.05,0,18,2.784,20.813,4.472,20.813c.7,0,1.1-1.941,1.1-2.489,0-.654-1.666-2.046-1.666-4.767A9.5,9.5,0,0,1,13.774,3.9c4.788,0,8.332,2.721,8.332,7.72,0,3.734-1.5,10.737-6.349,10.737a3.122,3.122,0,0,1-3.248-3.08c0-2.658,1.856-5.231,1.856-7.973,0-4.655-6.6-3.811-6.6,1.814a8.26,8.26,0,0,0,.675,3.565c-.97,4.177-2.953,10.4-2.953,14.7,0,1.329.19,2.637.316,3.966.239.267.12.239.485.105C9.83,30.6,9.7,29.651,11.306,23.3c.865,1.645,3.1,2.531,4.873,2.531C23.646,25.833,27,18.555,27,12,27,5.013,20.967.457,14.344.457Z" transform="translate(0.5 0.043)" fill="#3e3e3e" stroke="#3e3e3e" strokeWidth="1"/>
</svg>


export const twitterIcon= <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11.371" viewBox="0 0 14 11.371">
<path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M12.561,6.215c.009.124.009.249.009.373a8.108,8.108,0,0,1-8.164,8.164A8.108,8.108,0,0,1,0,13.463a5.936,5.936,0,0,0,.693.036,5.746,5.746,0,0,0,3.562-1.226,2.874,2.874,0,0,1-2.683-1.99,3.619,3.619,0,0,0,.542.044,3.035,3.035,0,0,0,.755-.1,2.87,2.87,0,0,1-2.3-2.816V7.378a2.89,2.89,0,0,0,1.3.364A2.874,2.874,0,0,1,.977,3.9a8.156,8.156,0,0,0,5.916,3,3.239,3.239,0,0,1-.071-.657,2.872,2.872,0,0,1,4.966-1.963,5.649,5.649,0,0,0,1.821-.693,2.862,2.862,0,0,1-1.261,1.581A5.752,5.752,0,0,0,14,4.731a6.168,6.168,0,0,1-1.439,1.483Z" transform="translate(0 -3.381)" fill="#3e3e3e"/>
</svg>

