import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import about_line from "../../assets/images/icon/line_black.png";
import { base_url, postHeader } from '../../Helpers/request';

const Faq = () => {
  useEffect(()=>{
    getBestSeller();
  },[])

  const [data,setData] = useState([]);

  const getBestSeller = async () => {
    const options = {
      method: "GET",
      headers: postHeader
    };

   await axios.get(base_url + "/user/faq", options).then((res) => {
      if (res && res?.status === 200) {
        setData(res?.data?.data);
      }
    });
  };

    return (
        <>
     

      
            <Helmet>
                <title>FAQS | Gem Stone King ™</title>
                <meta name="description" content="PAYMENTS Q: I am skeptical of buying jewelry on the Internet. What assurance can you give me? The best advice we can give you is to check our background record on selling over the internet in different web platforms such as Amazon, Ebay, Rakuten, Walmart, Sears, Newegg. You can check our great feedback score record by" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/faq" />
                <meta property="og:title" content="FAQS" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="PAYMENTS Q: I am skeptical of buying jewelry on the Internet. What assurance can you give me? The best advice we can give you is to check our background record on selling over the internet in different web platforms such as Amazon, Ebay, Rakuten, Walmart, Sears, Newegg. You can check our great feedback score record by" />
            </Helmet>



        <div className="breadCrumb cart_breadcrumb">
        <Container className="gs_customer_container_fluid" fluid>
          <nav aria-label="breadcrumb" className="breadCrumbWrapper">
            <ol className="breadcrumb bg-transparent">
              <li className="breadcrumb-item">
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
               FAQ
              </li>
            </ol>
          </nav>
          <div className="gs_product_top_heading">
            <h1>FAQ</h1>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad rerum
              officiis nesciunt molestiae inventore perferendis
            </p>
          </div>
      
        </Container>
      </div>

            <div className="gs_static_pages_content gs_faq_section">
                <Container className="gs_customer_container_fluid" fluid>
                    <Row className="m-0 w-100">
                     
                       <Col>
                       <div className="gs_static_section">
                       <h5>PAYMENTS</h5>

                            {
                              data.length > 0 && data.map((e,i)=>(
                                <div key={i}>
                                <p className='f_roboto'><strong>{e?.title}</strong></p>
                                <div className='f_roboto' dangerouslySetInnerHTML={{ __html: e?.description }} />
                                </div>
                              ))
                            }

                            {/* <h2>PAYMENTS</h2>
                            <p><strong>Q: I am skeptical of buying jewelry on the Internet. What assurance can you give me?</strong></p>
                            <p>The best advice we can give you is to check our background record on selling over the internet in different web platforms such as Amazon, Ebay, Rakuten, Walmart, Sears, Newegg. You can check our great feedback score record by clicking here.</p>
                            <p><strong>Q: Can I pay by check or money order?</strong></p>
                            <p>We do not accept either check or money order. You can pay with PayPal or MasterCard, Visa, American Express and Discover cards and all payments are processed securely by PayPal.</p> */}
                        </div>
                        {/* <div className="gs_static_section">
                            <h2>SHIPPING</h2>
                            <p><strong>Q: Do you ship Internationally?</strong></p>
                            <p>We do ship to most countries with the exception of some, please visit this page for most accurate list of excluded countries. We will ship by the best possible method either United Parcel Service (UPS) or the United States Postal Service (USPS).</p>
                            <p>Customs and Import Duties: Your country of residence may impose duties or levy some charge that you must pay because your order originated outside of your home country. These fees are your responsibility and are separate from the cost paid to joggles for your merchandise and shipping.</p>
                            <p>Please do not ask that we mark the customs tag as a "gift" or to value the order at less than what you paid for the merchandise. Our doing any of those things is fraud. Thanks for understanding.</p>
                            <p><strong>Q: Does Shipping & Handling fees include insurance?</strong></p>
                            <p>Shipping & Handling and Insurance fees are all included in the shipping rates.</p>
                            <p><strong>Q: Can I combine multiple items into one shipment?</strong></p>
                            <p>If you purchase your items at different times and we end up with multiple purchase orders, then you have to contact us immediately and request so we can try to combine the shipment and you save money.</p>
                        </div>
                        <div className="gs_static_section">
                            <h2>RETURNS</h2>
                            <p><strong>Q: If I am not satisfied can I get my money back?</strong></p>
                            <p>You may return most new, unopened items within 30 days of delivery for a full refund. We'll also pay the return shipping costs if the return is a result of our error (you received an incorrect or defective item, etc.)</p>
                            <p>You should expect to receive your refund within four weeks of giving your package to the return shipper, however, in many cases you will receive a refund more quickly. This time period includes the transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to process our refund request (5 to 10 business days.)</p>
                            <p>Please visit Shiping & Returns page for more information</p>
                        </div>
                        <div className="gs_static_section">
                            <h2>PRODUCTS</h2>
                            <p><strong>Q: Do you resize rings?</strong></p>
                            <p>You can easily select the available ring size on the product page then add it to your shopping cart. If you cannot find desired ring size that means most likely we do not have it in stock. If you purchase a wrong size and wish to exchange with one of the available sizes please contact as immediately so we can exchange or resize for free.</p>
                            <p><strong>Q: Do you offer a appraisals or certificates?</strong></p>
                            <p>Not every product comes with appraisal or certificate but you may request one by contacting us. Depend on the products we may provide you either appraisal or certificate card containing a detailed information about the 4 important characteristics of a diamond or gemstone (the 4 C's): color, cut, clarity. and carat weight; a picture of the item, and the appraisal value.</p>
                            <p><strong>Q: What is the source of all your diamonds?</strong></p>
                            <p>All our rough diamonds have been purchased from legitimate sources not involved in funding conflicts and in compliance with United Nations resolutions. We hereby guarantee that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
                            <p><strong>Q: Are the items you are selling brand new?</strong></p>
                            <p>We only sell brand new items. We do not sell used jewelry.</p>
                            <p><strong>Q: Do you sell real jewelry?</strong></p>
                            <p>All our jewelry is real and genuine.</p>
                            <p><strong>Q: Where should I keep my diamond jewelry?</strong></p>
                            <p>We recommend that all diamond, gemstone, gold or silver jewelry be stored in soft cloth pouches when not being worn.</p>
                            <p><strong>Q: How should I take care of my diamond jewelry?</strong></p>
                            <p>Exposure during ordinary wear to perspiration and household chemicals, like chlorine and hairspray, can cause buildup that dulls the surface of the diamond. We recommend periodic cleaning to keep the diamond sparkling and refractive.</p>
                        </div> */}
                       </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Faq
