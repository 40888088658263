import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Contact from "../../assets/images/Home/new-home/contact-img.png";
import { NavLink } from "react-router-dom";
import { CircularProgress, TextField } from "@material-ui/core";
import { postData } from "../../Helpers/request";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [capchaloading, setCapchaloading] = useState(true);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);
  const detail = JSON.parse(localStorage.getItem("footerdetails"));

  async function kleviyoTracking(val) {

    var item = {
      "url": window.location.href,
      "full_name": val?.first_name+ " " + val?.last_name,
      "email": val?.email,
      "order_no": val?.first_name,
      "phone": val?.phone_no,
      "message": val?.message,
    };
    if(window.klaviyo){
     await window.klaviyo.push(["track", "Contact Form", item]);
    }
  }
  const handleContact = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    var data = {
      first_name: fname,
      last_name: lname,
      email: email,
      phone_no: phone,
      message: message,
    };
    postData(`/user/contactUs`, data)
      .then((response) => {
        if (response.status == 1) {
          toast.success(response.message, { autoClose: 3000 });
          setFname("");
          setLname("");
          setEmail("");
          setPhone("");
          setMessage("");
          setButtonLoading(false);
          kleviyoTracking(data)
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const capchastatus = (res) => {
    if (res) {
      setCapchaloading(false);
    }
  };
  const expireCaptcha = () => {
    setCapchaloading(true);
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <>
      <Helmet>
        <title>Contact Us | Gem Stone King ™</title>
        <meta
          name="description"
          content="We&#39;re happy to answer questions or help you with returns.Have a question or comment? Use the form below to send us a message or give us a call to 212-869-6776 555 Eighth Avenue Room 300 New York, NY 10018 United States"
        />
        <meta property="og:site_name" content="Gem Stone King ™" />
        <meta
          property="og:url"
          content="http://gsk.tasksource.in:8080/contact-us"
        />
        <meta property="og:title" content="Contact Us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="We&#39;re happy to answer questions or help you with returns.Have a question or comment? Use the form below to send us a message or give us a call to 212-869-6776 555 Eighth Avenue Room 300 New York, NY 10018 United States"
        />
      </Helmet>
      <ToastContainer limit={1} />
      <div className="gs_static_pages_content">
        <Container>
          <Row className="m-0 w-100">
            <Col md={6}>
              <div className="gs_contact_forms gs_appointment_input">
                <div className="gs_contact_add_info">
                  <h1 className="d_font">Contact Us</h1>
                  <p>
                    We're happy to answer questions or help you with returns.
                    Have a question or comment? <br /> Use the form below to
                    send us a message or give us a call to 212-869-6776
                  </p>
                  <p>
                    555 Eighth Avenue Room 300 New York, NY 10018 United States 
                  </p>
                </div>
                <div className="Container">
                  <Form onSubmit={(e) => handleContact(e)}>
                    <Row>
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="First Name"
                          type="text"
                          variant="standard" 
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Last Name"
                          type="text"
                          variant="standard" 
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Email Address"
                          type="Email"
                          variant="standard" 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Col>
                      
                    </Row>
                    <Row>
                    <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Phone Number"
                          type="number"
                          variant="standard" 
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>
                    <Col className="p-0">
                      <TextField
                        id="outlined-basic"
                        label="Message"
                        type="text"
                        multiline
                        rows={4}
                        variant="outlined"
             
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                    </Col>
                    <Row>
                      <Col>
                        <ReCAPTCHA
                          sitekey="6LdLTkQdAAAAAAJ2NzZVwtBEmpVLR5srrf0sPOO3"
                          onChange={capchastatus}
                          onExpired={expireCaptcha}
                        />
                      </Col>
                    </Row>
                    <Col className="p-0 mb-3">
                      <Button
                        className="gs_cont_btn"
                        type="submit"
                        disabled={capchaloading || buttonLoading ? true : false}
                      >
                         
                        Send
                        {buttonLoading ? (
                          <CircularProgress className="gs_loader" />
                        ) : (
                          ""
                        )} 
                      </Button>
                    </Col>
                  </Form>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="gs_contact_info">
                <img src={Contact} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
