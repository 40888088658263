import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import Home from './Containers/Landing/home';
import './assets/css/custome.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Containers/Header/navbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from './Containers/Footer/footer';
import productlist from './Containers/Pages/productlist';
import productdetails from './Containers/Pages/productdetails';
import wishlist from './Containers/Pages/wishlist';
import cart from './Containers/Pages/cart';
import Faq from './Containers/Pages/faq';
import Shipping from './Containers/Pages/shipping';
import Refund from './Containers/Pages/refund';
import "../src/assets/css/static.css";
import { getData, postData } from './Helpers/request';
import Privacy from './Containers/Pages/privacy';
import Terms from './Containers/Pages/terms';
import Accessibility from './Containers/Pages/accessibility';
import OurStory from './Containers/Pages/ourStory';
import GivingBackToCommunity from './Containers/Pages/givingBackToCommunity';
import ContactUs from './Containers/Pages/contact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollButton from './Containers/Pages/ScrollButton';
import MyAccount from './Containers/Pages/myAccount';
import Checkout from './Containers/Pages/checkout';
import Invoice from './Containers/Pages/invoice';
import OrderSuccess from './Containers/Pages/OrderSuccess';
import Blog from './Containers/Pages/blog';
import BlogDetails from './Containers/Pages/blogdetails';
import GotoTop from './Containers/Pages/GotoTop';
import ShopingBag from './Containers/Pages/ShopingBag';
import SignIn from './Containers/Pages/Login/SignIn';
import LoginPage from './Containers/Pages/Login';
import Payment from './Containers/Pages/Payment';
import Redirection from './Containers/Redirection';
import FourZeroFour from './404';
import PaymentDeclined from './Containers/Pages/PaymentDeclined';
import Checkout2 from './Containers/Pages/Checkout2';

const App = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingbanner, setIsloadingbanner] = useState(true);
  const [isLoadingbs, setIsloadingbs] = useState(true);
  const [menudata, setMenudata] = useState([]);
  useLayoutEffect(() => {
    if (localStorage.getItem("session_id")?.length || JSON.parse(localStorage.getItem("user"))?.length) {
      console.log("session exist!")
    } else {
      getData(`/api/getsessionid/`)
        .then(res => {
          if (res.status == 1) {
            localStorage.setItem("session_id", res.data.session_id)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    //Banner
    getData(`/user/banner`)
      .then(res => {
        if (res.status == 1) {
          localStorage.setItem("bannerdata", JSON.stringify(res.data[0]));
          setIsloadingbanner(false);
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch(err => {
        console.log(err)
      })
    //Birthstone
    getData(`/user/birthStone`)
      .then(res => {
        if (res.status == 1) {
          localStorage.setItem("birthstone", JSON.stringify(res.data[0]));
          setIsloadingbs(false);
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch(err => {
        console.log(err)
      })
    //footer
    getData(`/user/footerInfo`)
      .then((response) => {
        if (response.status == 1) {
          localStorage.setItem("footerdetails", JSON.stringify(response.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      })
    //Product-Menu
    getData(`/api/menu/?format=json`)
      .then((response) => {
        if (response.status == 1) {
          setMenudata(response.data);
          setLoading(false);
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, []);

  // const ScrollToTop = (props) => {
  //   const location = useLocation();
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [location]);
  
  //   return <>{props.children}</>
  // };

  if (isLoading || isLoadingbanner || isLoadingbs) {
    return <div className="gs_loader1">
      <CircularProgress />
    </div>
  }
  return (
    <>
      <ToastContainer limit={1} />
      <BrowserRouter>
        <Navigation menudata={menudata} />
        <Switch>
        {/* <ScrollToTop> */}
          <Route exact path="/" component={Home} />
          {/* <Route component={pageRouter} /> */}
          <Route path="/category/:menu/:value" component={productlist} />
          <Route path="/searchlist/:searchvalue" component={productlist} />
          <Route path="/productlist/:menu/:submenu/:item/:value/:subvalue" component={productlist} />
          <Route path="/pages/faqs" component={Faq} />
          <Route path="/sign-in" component={LoginPage} />
          <Route path="/pages/shipping-returns" component={Shipping} />
          <Route path="/pages/refund-policy" component={Refund} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/terms-of-service" component={Terms} />
          <Route path="/accessibility-statement" component={Accessibility} />
          <Route path="/pages/our-story" component={OurStory} />
          <Route path="/giving-back-to-the-community" component={GivingBackToCommunity} />
          <Route path="/pages/contact-us" component={ContactUs} />
          <Route path="/productdetail/:slug/:sku/:stone_data" component={productdetails} />
          <Route path="/productdetail/:slug/:sku" component={productdetails} />
          <Route path="/wishlist" component={wishlist} />
          <Route path="/cart" component={cart} />
          <Route path="/checkout" component={Checkout2} />
          <Route path="/myaccount" component={MyAccount} />
          <Route path="/invoice/:uid/:order_id" component={Invoice} />
          <Route path="/blog" component={Blog} />
          <Route path="/order-success/:uid/:id/:no/:tid" component={OrderSuccess} />
          <Route path="/payment-declined" component={PaymentDeclined} />
          <Route path="/blogs/news/:id" component={BlogDetails} />
          <Route path="/shoping-bag" component={ShopingBag} />
          <Route path="/payment" component={Payment} />
          <Route path="*" component={FourZeroFour} />
          <Route path="*" component={Redirection} />

        

          {/* </ScrollToTop> */}
        </Switch>
        <Footer />
        <GotoTop />
        <ScrollButton />
      </BrowserRouter>
    </>
  );
}

export default App;