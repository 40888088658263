import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Login from '../../../Components/Login'
import SignIn from './SignIn'
import SignUp from './SignUp'

const LoginPage = () => {
  return (
    <section className='pt-4 mb-5'>
      <Container>
        <Row className='gx-5'>
            <Col lg={6} md={6} className='lineAfter position-relative'>
            <div className='pr-lg-5'>
            <SignIn/>
            </div>
               
            </Col>

            <Col lg={6} md={6}>
            <div className='pl-lg-5'>
            <SignUp/>
            </div>
           
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default LoginPage
