import React from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import about_line from "../../assets/images/icon/line_black.png";

const Accessibility = () => {
    return (
        <>
            <Helmet>
                <title>Accessibility Statement | Gem Stone King ™</title>
                <meta name="description" content="Accessibility Statement for Gem Stone King We are committed to making our website as accessible as possible to people with special needs, including those with visual, hearing, cognitive and motor impairments. We&#39;re constantly working towards improving the accessibility of our website to ensure we provide equal access t" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/accessibility-statement" />
                <meta property="og:title" content="Accessibility Statement" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Accessibility Statement for Gem Stone King We are committed to making our website as accessible as possible to people with special needs, including those with visual, hearing, cognitive and motor impairments. We&#39;re constantly working towards improving the accessibility of our website to ensure we provide equal access t" />
            </Helmet>
            <div className="gs_static_pages_content">
                <Container>
                    <Row className="m-0 w-100">
                        <div className="gs_static_heading">
                            <h1>ACCESSIBILITY STATEMENT</h1>
                            <Image src={about_line} alt="line"></Image>
                        </div>
                        <div className="gs_static_section">
                            <h2>Accessibility Statement for Gem Stone King</h2>
                            <p>We are committed to making our website as accessible as possible to people with special needs, including those with visual, hearing, cognitive and motor impairments. We're constantly working towards improving the accessibility of our website to ensure we provide equal access to all of our users</p>
                            <p>We always make sure that our website follows accessibility best practices by following the principles of universal design. This ensures the site is flexible and adaptable to different users’ needs or preferences and is accessible through a variety of different technologies, including mobile devices or assistive technologies.</p>
                        </div>
                        <div className="gs_static_section">
                            <h2>Web Content Accessibility Guidelines (WCAG) 2.1</h2>
                            <p>Wherever possible, our site will adhere to level AA of the Web Content Accessibility Guidelines (WCAG 2.1). These guidelines outline four main principles that state that sites should be:</p>
                        </div>
                        <div className="gs_static_section">
                            <ul>
                                <li><strong>Perceivable -</strong> Information and user interface components must be presentable to users in ways they can perceive</li>
                                <li><strong>Operable -</strong> User interface components and navigation must be operable</li>
                                <li><strong>Understandable -</strong> Information and the operation of user interface must be understandable </li>
                                <li><strong>Robust -</strong> Content must be robust enough that it can be interpreted reliably by a wide variety of user agents, including assistive technologies</li>
                            </ul>
                        </div>
                        <div className="gs_static_section">
                            <h2>Accessibility Toolbar</h2>
                            <p>This website uses Accessibility Enabler toolbar to allow users to customise the accessibility settings based on their needs. This tool helps user with access needs to adjust any web page so that it can be consumed irrespective of disability of the user</p>
                            <p>You can refer to Accessibility Enabler user guide here to know more.</p>
                        </div>
                        <div className="gs_static_section">
                            <h2>Give Us Your Feedback</h2>
                            <p>We are committed to providing an accessible service.If you experience problems or have any suggestions for improvement please get in touch with us.We do continuous improvement based on your feedback.</p>
                        </div>
                        <div className="gs_static_section p-0">
                            <p><strong>Phone :</strong> +1 (877) 706-5333 </p>
                        </div>
                        <div className="gs_static_section p-0">
                            <p><strong>Email :</strong> support@gemstoneking.com </p>
                        </div>
                        <div className="gs_static_section p-0">
                            <span>We try to respond to feedback within 3 business days.</span>
                        </div>
                        <div className="p-3"></div>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Accessibility
