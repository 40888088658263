import React from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import about_line from "../../assets/images/icon/line_black.png";

const Shipping = () => {
    return (
        <>
            <Helmet>
                <title>SHIPPING &amp; RETURNS | Gem Stone King ™</title>
                <meta name="description" content="ShippingWe can ship to virtually any address in the world. Note that there are restrictions on some products, and some products cannot be shipped to international destinations.When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items and the shipping options y" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/pages/pages/shipping-returns" />
                <meta property="og:title" content="SHIPPING &amp; RETURNS" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="ShippingWe can ship to virtually any address in the world. Note that there are restrictions on some products, and some products cannot be shipped to international destinations.When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items and the shipping options y" />
            </Helmet>
            <div className="gs_static_pages_content">
                <Container>
                    <Row className="m-0 w-100">
                        <div className="gs_static_heading">
                            <h1>SHIPPING & RETURNS</h1>
                            <Image src={about_line} alt="line"></Image>
                        </div>
                        <div className="gs_static_section">
                            <h2>Shipping</h2>
                            <p>We can ship to virtually any address in the world. Note that there are restrictions on some products, and some products cannot be shipped to international destinations.</p>
                            <p>When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items and the shipping options you choose. Depending on the shipping provider you choose, shipping date estimates may appear on the shipping quotes page.</p>
                            <p>Please also note that the shipping rates calculated based on total order amount.</p>
                        </div>
                        <div className="gs_static_section">
                            <h2>Returns Policy</h2>
                            <p>You may return most new, unopened items within 30 days of delivery for a full refund. We'll also pay the return shipping costs if the return is a result of our error (you received an incorrect or defective item, etc.).</p>
                            <p>You should expect to receive your refund within four weeks of giving your package to the return shipper, however, in many cases you will receive a refund more quickly. This time period includes the transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to process our refund request (5 to 10 business days).</p>
                           
                        </div>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default Shipping
