import React, { Component } from 'react';

class Testing extends Component {
  constructor(props) {
    super(props);

    // Initialize state with an array of values
    this.state = {
      inputValues: [''], // Initial value for the first input
    };
  }

  // Function to handle input change for a specific index
  handleInputChange = (index, event) => {
    const { inputValues } = this.state;

    const updatedValues = [...inputValues];
    updatedValues[index] = event.target.value;

    this.setState({
      inputValues: updatedValues,
    });
  };

  // Function to add a new input field
  addInputField = () => {
    const { inputValues } = this.state;
    const updatedValues = [...inputValues, ''];
    this.setState({
      inputValues: updatedValues,
    });
  };

  render() {
    const { inputValues } = this.state;

    return (
      <div>
        <h1>My Component</h1>

        {/* Render input fields based on the array of values */}
        {inputValues.map((value, index) => (
          <input
            key={index}
            type="text"
            value={value}
            onChange={(event) => this.handleInputChange(index, event)}
          />
        ))}

        {/* Button to add a new input field */}
        <button onClick={this.addInputField}>Add Input Field</button>

        {/* Display the current array of values in state */}
        <p>Current Values: {JSON.stringify(inputValues)}</p>
      </div>
    );
  }
}

export default Testing;
