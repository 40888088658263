import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './store';

ReactDOM.render(
  <StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</StrictMode>,
  document.getElementById('root')
);


// root.render(
//   <StrictMode>
//     <Provider store={Store}>
//       <App />
//     </Provider>
//   </StrictMode>
//  );