import { CircularProgress, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { postData } from '../../../Helpers/request'

const Forget = () => {

    const [buttonLoading, setButtonLoading] = useState(false);
    const [f_email, setF_email] = useState('');
    const handleforgotpassword = (e) => {
        setButtonLoading(true);
        e.preventDefault();
        var data = {
            email: f_email
        }
        postData(`/user/forgetPassword`, data)
            .then(function (response) {
                if (response.status == 1) {
                    setButtonLoading(false);
                    toast.success(response.message, { autoClose: 3000 });
                    // history.push("/home");
                } else {
                    setButtonLoading(false);
                    toast.error(response.message, { autoClose: 3000 });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        setF_email("");
    }
  return (
    <div className="gs_appointment_input gs_login gs_forgot">
    <Modal.Title id="contained-modal-title-vcenter">Forgot Password</Modal.Title>
    <div className="gs_forgot_msg"><span>To reset your password, please enter your email address</span></div>
    <Form onSubmit={handleforgotpassword}>
        <Row>
            <Col>
                <TextField
                    id="outlined-basic"
                    label="Email Address"
                    type="Email"
                    variant="standard"
                    value={f_email}
                    onChange={(e) => setF_email(e.target.value)}
                    required />
            </Col>
        </Row>
        <Row>
            <Col>
                <Button className="gs_fill_button" type="submit" disabled={buttonLoading}>
                    Continue {buttonLoading ? <CircularProgress className="gs_loader" /> : ""}
                </Button>
            </Col>
        </Row>
     
    </Form>
</div>
  )
}

export default Forget
