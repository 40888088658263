import { CircularProgress, Modal } from "@material-ui/core";
import React, { useRef, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { postAuthData, postData } from "../Helpers/request";

export default function Paypal(props) {
  const [loader, setLoader] = useState(false);
  const paypal = useRef();
  const history = useHistory();
 async function kleviyoTracking() {

    var item = {
      "billing_address": props?.data?.billing_address,
      "order_id": props?.data?.order_id,
      "tax_amount": props?.data?.tax_amount,
      "order_no": props?.data?.order_no,
      "productlist": props?.data?.productlist,
      "user_id": props?.data?.user_id,
      "currency_code": props?.data?.currency_code,
      "total_amount": props?.data?.final_amount,
    };
    if(window?.klaviyo){
     await window.klaviyo.push(["track", "Order confirmation", item]);
    }
  }

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          layout: 'horizontal' 
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              reference_id: props.data.order_no,
              description: "GSK",
              custom_id: props.data.order_id,
              amount: {
                currency_code: props.data.currency_code,
                value: props.data.final_amount,
                breakdown: {
                  item_total: {
                    currency_code: props.data.currency_code,
                    value: props.data.total_amount,
                  },
                  shipping: {
                    currency_code: props.data.currency_code,
                    value: props.data.shipping_amount,
                  },
                  tax_total: {
                    currency_code: props.data.currency_code,
                    value: props.data.tax_amount,
                  },
                  discount: {
                    currency_code: props.data.currency_code,
                    value: props.data.discount_amount,
                  }
                }
              },
              items: props.data.productlist
            }],
            application_context: {
              shipping_preference: "NO_SHIPPING"
            }
          });
        },
        onApprove: async (data, actions) => {
          return await actions.order.capture()
            .then((res) => {
              setLoader(true)
              res.payment_method="Paypal";
              postData(`/order/orderResponse`, res)
                .then(response => {
                  if (response.status == 1) {
                    kleviyoTracking()
                    localStorage.removeItem("cartlength")
                    var transaction = res.purchase_units[0].payments.captures[0];
                    setLoader(false)
                    history.push(`/order-success/${props.data.user_id}/${response.data.order_id}/${response.data.order_no}/${response.data.txn_id}?total=${props.data.final_amount}`)
                  }
                })
                .catch(err => {
                  console.log(err)
                  setLoader(false)
                })
            })

        },
        onError: (err) => {
          alert(err);
          setLoader(false)
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <>
      <div>
        <div ref={paypal}></div>
      </div>
      <Modal className="rcs_modal_124" open={loader} >
      <div className="gs_loader1">
      <CircularProgress />
    </div>
      </Modal>
    </>
  );
}