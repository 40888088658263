import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../assets/css/home.css";
import Video1 from "../../assets/images/Home/video-img1.png";
import Video2 from "../../assets/images/Home/video-img2.png";
import ModalVideo from "react-modal-video";
import { BsPlayCircle } from "react-icons/bs";

const VideoBanner = () => {

  // const videoRef = useRef(null);
  // const videoRef1 = useRef(null);
  // const [playStatus,setplayStatus]=useState(false);
  // const [playStatus1,setplayStatus1]=useState(false);

  // const playPauseToggle=(status)=>{
  //   if(playStatus===false){
  //       videoRef.current.play();
  //       setplayStatus(true);

  //   }else{
  //       videoRef.current.pause()
  //       setplayStatus(false);
  //   }
  // }

  // const playPauseToggle1=(status)=>{
  //   if(playStatus1===false){
  //       videoRef.current.play();
  //       setplayStatus1(true);

  //   }else{
  //       videoRef.current.pause()
  //       setplayStatus1(false);
  //   }
  // }

  return (
    <>
      <section className="gsk_video_banner_section">
        <Container className="gs_custome_fluid_container" fluid>

          <Row className="align-items-center gy-5">
            <Col lg={6} xs={12}>

              <video className="w-100" poster={require("../../assets/images/Home/new-home/left_vidd.jpg").default} controls >
                <source src="https://gsk-videos.s3.amazonaws.com/website-videos/charm_collection_14_small.mp4" type="video/mp4" />
              </video>
              {/* 
            {
              !isOpen &&  <div className="gsk_video_banner_img" onClick={() => setOpen(true)}>
                <img src={Video1} alt='' />
            
              </div>
            }
       
            {
              isOpen &&  <div className="videoContainer"> 
                <iframe className="responsiveIframe" src="https://www.youtube-nocookie.com/embed/2r2oOG-LhtE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            } */}



              {/* <ModalVideo
                youtube={{
                  autoplay: 1,
                  mute: 1,
                  rel:0,
                }}
                isOpen={isOpen}
                videoId="2r2oOG-LhtE"
                onClose={() => setOpen(false)}
              /> */}
            </Col>
            <Col lg={6} xs={12}>

              <video className="w-100" poster={require("../../assets/images/Home/new-home/right_video.jpg").default} controls>
                <source src="https://gsk-videos.s3.amazonaws.com/website-videos/personalize.mp4" type="video/mp4" />
              </video>
              {/* {
              !isOpen2 && 
              <div className="gsk_video_banner_img"   onClick={() => setOpen2(true)}>
                <img src={Video2} alt='' />
              </div>
            }
             

            {
              isOpen2 &&  <div className="videoContainer"> 
                <iframe className="responsiveIframe" src="https://www.youtube-nocookie.com/embed/K7GEmqMBe4o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            } */}

              {/* <ModalVideo
                youtube={{
                  autoplay: 1,
                  mute: 1,
                  rel:0,
                }}
                isOpen={isOpen2}
                videoId="K7GEmqMBe4o"
                onClose={() => setOpen2(false)}
              /> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default VideoBanner;
